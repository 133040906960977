import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import OtpInput from "react-otp-input";

import OTPImage from "../../assets/images/otp-image.png";
import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL, SEND_OTP, VERIFY_OTP } from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";

const EmailVerificationModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [otp, setOtp] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const jwt = GetCookies(CookieItems.JWT);

  const handleError = useErrorHandler();

  useEffect(() => {
    if (otp.length === 6) {
      axios
        .post(
          BASE_URL + VERIFY_OTP,
          { otp: otp },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((res) => {
          setOtp("");
          setError("");
          onClose();
        })
        .catch((err) => {
          console.error("Error while fetching verification level", err);
          if (err.response.status === 500) {
            setError("OTP didn't match. Please try again.");
            toast.error("OTP didn't match. Please try again.")
          } else if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          }
          // else {
          //   handleError(new Error("Error failed to verify OTP"));
          // }
          setOtp("");
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  function handleRetry() {
    axios
      .post(
        BASE_URL + SEND_OTP,
        { use_ID: -1 },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((res) => {})
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while sending OTP", err);
          handleError(new Error("Error while sending OTP"));
        }
      });
  }

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal centered isOpen={open} toggle={onClose} backdrop="static">
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        OTP VERIFICATION
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142", textAlign: "center" }}>
        <img className="" alt="explore-profile" src={OTPImage} />

        <h4>Enter OTP sent to your email.</h4>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span></span>}
          containerStyle={{
            display: "flex",
            justifyContent: "center",
          }}
          inputStyle={{
            width: "2rem",
            height: "2.6rem",
            margin: "0.5rem",
            borderRadius: "8px",
            border: "none",
          }}
          renderInput={(props) => <input {...props} />}
        />
        <div
          className="d-flex justify-content-center mt-3"
          style={{ fontSize: 14, gap: 10 }}
        >
          <span>Dont receive the OTP ? </span>
          <div className="modal-popup-retry" onClick={handleRetry}>
            RESEND OTP
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EmailVerificationModal;
