import React, { useCallback, useEffect, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import WalletCreditIcon from "../../assets/svg/Wallet-credit-icon.svg";
import useBreakPoints from "../../hooks/useBreakPoints";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import {
  MyProductType,
  SingleProjectDetails,
  // projectSubDetailsType,
} from "../../helpers/types/TraidPareType";
import axios from "axios";
import {
  ADD_TOKEN_ID_FAVOURITE,
  BASE_URL,
  GET_ALL_PRODUCT_LIST_BY_ASSET_ID,
  GET_TOKEN_ID_FAVOURITE,
  REMOVE_TOKEN_ID_FAVOURITE,
  SEARCHPROJECTLIST,
} from "../../helpers/api_helper";
import { debounce } from "lodash";
import CategoryTable from "./CategoryTable";
import { TablePagination } from "@mui/material";

import FilterIcon from "../../assets/svg/Filter-Icon.svg";
import DownloadIcon from "../../assets/svg/Download-Icon.svg";
import CustomButton from "../../Component/CustomButton";
// import Drawer from "@mui/material/Drawer";

const TradeDetailModal = ({
  open,
  data,
  onClose,
}: {
  open: boolean;
  data: any;
  onClose: () => void;
}) => {
  const { isTablet, isMobile, isMediumMobile } = useBreakPoints();
  const navigate = useNavigate();
  const jwt = GetCookies(CookieItems.JWT);
  // const role = GetCookies(CookieItems.ROLE);

  const [defaultProjectList, setDefaultProjectList] = useState<MyProductType[]>(
    []
  );
  const [page, setPage] = useState(0);
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  const [sortedFavourite, setSortedFavourite] = useState(true);
  const [favoriteTokenIDs, setFavoriteTokenIDs] = useState<number[]>([]);
  const [projectList, setProjectList] = useState<MyProductType[]>([]);
  const [step, setStep] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [singleProjectDetails, setSingleProjectDetails] =
    useState<SingleProjectDetails | null>(null);
  console.log(projectList, "projectList");
  console.log(data, "data");

  useEffect(() => {
    getFavoriteTokenIDs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getAllProjectList = async (favouriteArr: number[]) => {
    axios
      .post(
        BASE_URL + GET_ALL_PRODUCT_LIST_BY_ASSET_ID,
        {
          page: 0,
          asset_id:
            !data?.asset1_iscurrency && !data?.asset2_iscurrency
              ? data?.asset1_id
              : !data?.asset1_iscurrency
              ? data?.asset1_id
              : !data?.asset2_iscurrency
              ? data?.asset1_id
              : "",
        },
        {}
      )
      .then((response) => {
        console.log(response, "response");
        if (sortedFavourite) {
          let arr: any = [];
          response.data.forEach((token: MyProductType) => {
            if (favouriteArr.includes(token.token_id)) {
              arr.unshift({ ...token, favourite: true });
            } else {
              arr.push({ ...token, favourite: false });
            }
          });
          console.log(arr, "arr");

          setDefaultProjectList(arr);
          setProjectList(arr);
        } else {
          let arr: any = [];
          response.data.forEach((token: MyProductType) => {
            if (favouriteArr.includes(token.token_id)) {
              arr.push({ ...token, favourite: true });
            } else {
              arr.push({ ...token, favourite: false });
            }
          });
          console.log(arr, "arr");

          setDefaultProjectList(arr);
          setProjectList(arr);
        }
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while getting all trade pairs", error);
          toast.error("Error while getting all trade pair");
        }
      });
  };

  const getFavoriteTokenIDs = async () => {
    try {
      const response = await axios.post(
        BASE_URL + GET_TOKEN_ID_FAVOURITE,
        {
          trade_pair_id: data?.id,
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      );
      setFavoriteTokenIDs(response.data);
      getAllProjectList(response.data);
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Failed to fetch favorite token IDs", error);
        toast.error("Failed to fetch favorite token IDs");
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      searchProjectName(query);
    }, 350),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const searchProjectName = async (query: string) => {
    setLoading(true);
    try {
      const requestBody: any = { token_id_name: query };

      const response = await axios.post(
        `${BASE_URL}${SEARCHPROJECTLIST}`,
        requestBody
      );
      console.log("search response data", response.data);
      let arr: any = [];
      if (sortedFavourite) {
        response.data.forEach((token: MyProductType) => {
          if (favoriteTokenIDs.includes(token.token_id)) {
            arr.unshift({ ...token, favourite: true });
          } else {
            arr.push({ ...token, favourite: false });
          }
        });
        setProjectList(arr);
      } else {
        setProjectList(response.data);
      }
      // setProjectList(mapResponseToOptions(response.data));
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Failed to search trade pairs:", error);
        toast.error("Failed to search trade pairs");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inputValue.trim()) {
      debouncedSearch(inputValue.trim());
    } else {
      setProjectList(defaultProjectList || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, debouncedSearch]);

  const handleAddFavoriteBtn = async (token_id: number) => {
    setLoading(true);
    try {
      const requestBody: any = {
        trade_pair_id: data?.id,
        token_id: token_id,
      };

      const response = await axios.post(
        `${BASE_URL}${ADD_TOKEN_ID_FAVOURITE}`,
        requestBody,
        {
          headers: {
            Authorization: jwt,
          },
        }
      );
      console.log("search response data", response.data);
      getFavoriteTokenIDs();

      toast.success("Added to Favorite");
      // setProjectList(mapResponseToOptions(response.data));
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Failed to search trade pairs:", error);
        toast.error("Failed to search trade pairs");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveFavoriteBtn = async (token_id: number) => {
    try {
      const requestBody: any = {
        trade_pair_id: data?.id,
        token_id: token_id,
      };

      const response = await axios.post(
        `${BASE_URL}${REMOVE_TOKEN_ID_FAVOURITE}`,
        requestBody,
        {
          headers: {
            Authorization: jwt,
          },
        }
      );
      console.log("search response data", response.data);
      getFavoriteTokenIDs();

      toast.success("Remove to Favorite");
      // setProjectList(mapResponseToOptions(response.data));
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Failed to search trade pairs:", error);
        toast.error("Failed to search trade pairs");
      }
    } finally {
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  const handleDownloadCSV = () => {
    axios
      .post(
        BASE_URL + GET_ALL_PRODUCT_LIST_BY_ASSET_ID,
        {
          page: 0,
          asset_id:
            !data?.asset1_iscurrency && !data?.asset2_iscurrency
              ? data?.asset1_id
              : !data?.asset1_iscurrency
              ? data?.asset1_id
              : !data?.asset2_iscurrency
              ? data?.asset1_id
              : "",
        },
        {}
      )
      .then((response) => {
        console.log(response, "response");
        const csvData = response.data;
        if (csvData !== null && csvData !== undefined) {
          const csv = new Blob(
            [
              `${Object.keys(csvData[0]).join(",")}\n${csvData
                .map((e: any) => Object.values(e).join(","))
                .join("\n")}`,
            ],
            {
              type: "text/csv;charset=utf-8;",
            }
          );
          const url = window.URL.createObjectURL(csv);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "myCreditRequests.csv");
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while getting all trade pairs", error);
          toast.error("Error while getting all trade pair");
        }
      });
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal
      centered
      className="custom-trade-detail-modal"
      isOpen={open}
      toggle={onClose}
      backdrop="static"
    >
      <ModalHeader
        style={{ backgroundColor: "#273142" }}
        className="custom-trade-detail-header "
        toggle={onClose}
      >
        <div
          className={`d-flex justify-content-start align-items-center gap-3 ${
            isMobile ? "flex-column " : ""
          }`}
        >
          <div
            className={`d-flex justify-content-start align-items-center gap-3 w-100 ${
              isTablet ? "flex-column " : ""
            }`}
            style={{
              backgroundColor: "#323d4e",
              border: "1px solid #414b5b",
              borderRadius: 10,
              padding: isMediumMobile ? "15px" : "0px 15px",
            }}
          >
            <div className="d-flex justify-content-center align-items-center gap-3">
              <img
                className="mt-2"
                style={{ height: 80 }}
                src={WalletCreditIcon}
                alt="Rupees Icon"
              />
              <div>
                <p
                  className=" mb-0"
                  style={{
                    fontSize: isTablet ? (isMediumMobile ? 13 : 18) : 24,
                    fontWeight: 700,
                    marginTop: -10,
                  }}
                >
                  {data?.name}
                </p>
                <p className="mb-0 " style={{ fontSize: isTablet ? 10 : 10 }}>
                  {data?.full_name}
                </p>
              </div>
            </div>

            <div
              style={{
                fontSize: isTablet ? 18 : 24,
                paddingLeft: 25,
                borderLeft: isTablet ? "" : "2px solid #565f6d",
                marginLeft: 25,
                marginTop: isTablet ? -19 : 0,
              }}
              className="d-flex "
            >
              <p className="mb-0">&#x20b9; {data?.last_price}</p>

              {data?.change_percentage > 0 ? (
                <i
                  className="fa-solid fa fa-caret-up"
                  style={{ marginLeft: 20, marginTop: 5, color: "#8ce200" }}
                ></i>
              ) : data?.change_percentage === 0 ? (
                <span
                  style={{ marginLeft: 20, marginTop: 5, color: "#8ce200" }}
                ></span>
              ) : (
                <i
                  className="fa-solid fa fa-caret-down"
                  style={{ marginLeft: 20, marginTop: 5, color: "#ff0000" }}
                ></i>
              )}
              <p className="mb-0 ">
                {" "}
                <i
                  className="fas fa-chart-line p-1"
                  style={{ color: "white" }}
                ></i>{" "}
                {data?.change_percentage || "-"}%{" "}
              </p>
            </div>

            <div
              className={`d-flex justify-content-between  align-items-center ${
                isMediumMobile ? "flex-column gap-1 " : "gap-4"
              }`}
              style={{
                marginLeft: 25,
                marginBottom: isTablet ? 20 : 0,
              }}
            >
              <div>
                <p
                  className={`${isMediumMobile ? "pb-2" : ""} mb-0`}
                  style={{
                    color: "#b8bdc3",
                    borderBottom: isMediumMobile ? "2px solid #565f6d" : "none",
                  }}
                >
                  Mint Year &nbsp; &nbsp;{" "}
                  <span
                    style={{ color: "white", fontSize: 18, fontWeight: 700 }}
                  >
                    {data?.mint_year}
                  </span>
                </p>
              </div>
              <div>
                <p
                  className={`${isMediumMobile ? "pb-2" : ""} mb-0`}
                  style={{
                    borderLeft: isMediumMobile ? "none" : "2px solid #565f6d",
                    borderBottom: isMediumMobile ? "2px solid #565f6d" : "none",
                    paddingLeft: 20,
                    color: "#b8bdc3",
                  }}
                >
                  Tonnage &nbsp; &nbsp;{" "}
                  <span
                    style={{ color: "white", fontSize: 18, fontWeight: 700 }}
                  >
                    {data?.tonnage_per_credit}
                  </span>
                </p>
              </div>
              <div>
                <p
                  className="mb-0"
                  style={{
                    borderLeft: isMediumMobile ? "none" : "2px solid #565f6d",

                    paddingLeft: 20,
                    color: "#b8bdc3",
                  }}
                >
                  {" "}
                  Total Credit &nbsp; &nbsp;{" "}
                  <span
                    style={{ color: "white", fontSize: 18, fontWeight: 700 }}
                  >
                    {data?.total_credits}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        {step === 1 && (
          <>
            <Row
              className={`justify-content-start align-items-center ${
                isMobile ? "gap-3" : ""
              }`}
            >
              <Col md={4}>
                <div
                  className="input-group  cust-search "
                  style={{ paddingLeft: "1.5rem" }}
                >
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search-icon"
                    value={inputValue}
                    onChange={(e) => {
                      setInputValue(e.target.value);
                    }}
                    style={{
                      paddingLeft: 30,
                      borderRadius: 20,
                      color: "white",
                      backgroundColor: "#323D4E",
                      border: "1px solid rgb(62 72 87)",
                    }}
                  />
                  <div
                    className=""
                    style={{ position: "absolute", left: 34, top: 7 }}
                  >
                    <i className="fa fa-search"></i>
                  </div>
                </div>
              </Col>
              <Col
                md={4}
                className={`${
                  isMobile
                    ? "d-flex justify-content-end align-items-center w-100"
                    : ""
                }`}
              >
                <input
                  type="checkbox"
                  checked={sortedFavourite}
                  onChange={(e) => {
                    setSortedFavourite(e.target.checked);
                    console.log(e.target.checked, "e.target.checked");
                    if (e.target.checked) {
                      setProjectList(
                        projectList.sort((a: any, b: any) =>
                          a.favourite === b.favourite ? 0 : a.favourite ? -1 : 1
                        )
                      );
                    } else {
                      setProjectList(
                        projectList.sort((a: any, b: any) =>
                          a.favourite === b.favourite ? 0 : a.favourite ? 1 : -1
                        )
                      );
                    }
                  }}
                  style={{
                    accentColor: sortedFavourite ? "#00ABFF" : "initial",
                    color: sortedFavourite ? "#00ABFF" : "initial",
                  }}
                />
                &nbsp; &nbsp;
                <label style={{ color: "white" }}>Sorted By Favourite</label>
              </Col>
              <Col
                md={4}
                className="d-flex justify-content-end align-items-center"
                style={{ paddingRight: 40 }}
              >
                {!isTablet && (
                  <TablePagination
                    component="div"
                    count={projectList?.[0]?.total_count ?? 0} // Total number of rows
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={10}
                    rowsPerPageOptions={[]} // Hide the rows per page options
                    sx={{
                      color: "white",
                      "& .css-pdct74-MuiTablePagination-selectLabel ": {
                        color: "white",
                        marginBottom: 0,
                      },
                      "& .css-levciy-MuiTablePagination-displayedRows ": {
                        color: "white",
                        marginBottom: 0,
                      },
                      "& .Mui-disabled": {
                        color: "grey",
                        opacity: 0.5,
                      },
                      "& .MuiTablePagination-actions": {
                        "& .MuiIconButton-root.Mui-disabled": {
                          color: "grey",
                        },
                      },
                    }}
                  />
                )}

                <div
                  style={{
                    borderLeft: isMobile ? "" : "1px solid white",
                    paddingLeft: 20,
                    marginLeft: 10,
                  }}
                  className="d-flex justify-content-center align-items-center gap-3"
                >
                  <img
                    onClick={toggleDrawer(true)}
                    style={{ cursor: "pointer" }}
                    src={FilterIcon}
                    alt="Filter Icon"
                  />
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDownloadCSV();
                    }}
                    src={DownloadIcon}
                    alt="Download Icon"
                  />
                </div>
              </Col>
            </Row>

            <CategoryTable
              handleAddFavoriteBtn={handleAddFavoriteBtn}
              handleRemoveFavoriteBtn={handleRemoveFavoriteBtn}
              data={projectList}
              tradeData={data}
              setStep={setStep}
              setSingleProjectDetails={setSingleProjectDetails}
            />

            {isTablet && (
              <TablePagination
                component="div"
                count={projectList?.[0]?.total_count ?? 0} // Total number of rows
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={10}
                rowsPerPageOptions={[]} // Hide the rows per page options
                sx={{
                  color: "white",
                  "& .css-pdct74-MuiTablePagination-selectLabel ": {
                    color: "white",
                    marginBottom: 0,
                  },
                  "& .css-levciy-MuiTablePagination-displayedRows ": {
                    color: "white",
                    marginBottom: 0,
                  },
                  "& .Mui-disabled": {
                    color: "grey",
                    opacity: 0.5,
                  },
                  "& .MuiTablePagination-actions": {
                    "& .MuiIconButton-root.Mui-disabled": {
                      color: "grey",
                    },
                  },
                }}
              />
            )}
          </>
        )}

        {step === 2 && (
          <div>
            <div className="d-flex justify-content-end mb-3">
              <CustomButton
                placeholder="back"
                color="#323d4e"
                type="button"
                onClick={() => {
                  setStep(1);
                }}
              />
            </div>

            <div
              style={{
                backgroundColor: "#17202d",
                border: "1px solid #313c4d",
                borderRadius: "10px",
                margin: "10px 5px",
                padding: "10px 20px",
              }}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>
                General Information
              </span>
              <Row className="mt-2">
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}> Request Id :</span> &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.id || "-"}
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}> Registry URL :</span>{" "}
                  &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.registry_project_url ||
                    "-"}
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}> Project ID :</span> &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.project_id || "-"}
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}>
                    {" "}
                    Credit Program Name :
                  </span>{" "}
                  &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.credit_programme || "-"}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}> Project Country :</span>{" "}
                  &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.project_country || "-"}
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}> Project State :</span>{" "}
                  &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.project_state || "-"}
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}> Project Type :</span>{" "}
                  &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.project_type || "-"}
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}> Methodology ID :</span>{" "}
                  &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.methodology_id || "-"}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}> Mint Date :</span> &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.mint_year || "-"}
                </Col>
              </Row>
              {singleProjectDetails?.itemDetails?.if_registry_account && (
                <Row className="mt-2">
                  <Col xs={12} sm={6} md={4}>
                    <span style={{ color: "#999999" }}>
                      {" "}
                      Registry Account :
                    </span>{" "}
                    &nbsp;{" "}
                    {singleProjectDetails?.itemDetails?.registry_name || "-"}
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <span style={{ color: "#999999" }}> Registry A/c ID :</span>{" "}
                    &nbsp;{" "}
                    {singleProjectDetails?.itemDetails?.registry_account_id ||
                      "-"}
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <span style={{ color: "#999999" }}> Registry Name :</span>{" "}
                    &nbsp;{" "}
                    {singleProjectDetails?.itemDetails?.registry_name || "-"}
                  </Col>
                </Row>
              )}
              <Row className="mt-2">
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}>
                    {" "}
                    One Time Claimable :
                  </span>{" "}
                  &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.if_one_time_claim
                    ? "Yes"
                    : "No"}
                </Col>
                {!singleProjectDetails?.itemDetails?.if_one_time_claim && (
                  <Col xs={12} sm={6} md={3}>
                    <span style={{ color: "#999999" }}> Mint Duration :</span>{" "}
                    &nbsp; {singleProjectDetails?.itemDetails?.mint_duration}
                  </Col>
                )}
              </Row>
            </div>

            <div
              style={{
                border: "1px solid #313c4d",
                backgroundColor: "#17202d",
                borderRadius: "10px",
                margin: "10px 5px",
                padding: "10px 20px",
              }}
            >
              <span style={{ fontSize: 20, fontWeight: 600 }}>
                PROJECT PROPONENT INFORMATION{" "}
              </span>
              <Row className="mt-2">
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}>
                    {" "}
                    Credits claimed on Project ? :{" "}
                  </span>{" "}
                  &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.num_of_credits || "-"}
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}>
                    {" "}
                    Project Proponent Name :
                  </span>{" "}
                  &nbsp; {singleProjectDetails?.itemDetails?.pp_name || "-"}
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}>
                    {" "}
                    Project Proponent Address :
                  </span>{" "}
                  &nbsp; {singleProjectDetails?.itemDetails?.pp_address || "-"}
                </Col>
                <Col xs={12} sm={6} md={3}>
                  <span style={{ color: "#999999" }}> Contact No. :</span>{" "}
                  &nbsp;{" "}
                  {singleProjectDetails?.itemDetails?.pp_contact_details || "-"}
                </Col>
              </Row>
            </div>

            {singleProjectDetails?.itemDetails?.additional_accreditations && (
              <div
                style={{
                  backgroundColor: "#17202d",
                  border: "1px solid #313c4d",
                  borderRadius: "10px",
                  margin: "10px 5px",
                  padding: "10px 20px",
                }}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  ACCREDIATION INFORMATION{" "}
                </span>
                <Row className="mt-2">
                  <Col xs={12}>
                    {singleProjectDetails?.itemDetails
                      ?.additional_accreditations || "-"}
                  </Col>
                </Row>
              </div>
            )}
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default TradeDetailModal;
