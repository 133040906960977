import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  APPROVE_TOKENIZE_REQUEST,
  BASE_URL,
  GET_ALL_ASSETS,
  GET_TOKEN_CONFIG_BY_ASSET_ID_NO_PAGINATION,
} from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import CustomInputBox from "../../Component/CustomInputBox";
import CustomDropdown from "../../Component/CustomDropdown";
import { AssetType } from "../../helpers/types/AdminScreenType";
import useBreakPoints from "../../hooks/useBreakPoints";

const SDGOptions = [
  {
    value: "Goal 1: No Poverty",
    label: "Goal 1: No Poverty",
  },
  {
    value: "Goal 2: Zero Hunger",
    label: "Goal 2: Zero Hunger",
  },
  {
    value: "Goal 3: Good Health and Well-being",
    label: "Goal 3: Good Health and Well-being",
  },
  {
    value: "Goal 4: Quality Education",
    label: "Goal 4: Quality Education",
  },
  {
    value: "Goal 5: Gender Equality",
    label: "Goal 5: Gender Equality",
  },
  {
    value: "Goal 6: Clean Water and Sanitation",
    label: "Goal 6: Clean Water and Sanitation",
  },
  {
    value: "Goal 7: Affordable and Clean Energy",
    label: "Goal 7: Affordable and Clean Energy",
  },
  {
    value: "Goal 8: Decent Work and Economic Growth",
    label: "Goal 8: Decent Work and Economic Growth",
  },
  {
    value: "Goal 9: Industry, Innovation, and Infrastructure",
    label: "Goal 9: Industry, Innovation, and Infrastructure",
  },
  {
    value: "Goal 10: Reduced Inequalities",
    label: "Goal 10: Reduced Inequalities",
  },
  {
    value: "Goal 11: Sustainable Cities and Communities",
    label: "Goal 11: Sustainable Cities and Communities",
  },
  {
    value: "Goal 12: Responsible Consumption and Production",
    label: "Goal 12: Responsible Consumption and Production",
  },
  {
    value: "Goal 13: Climate Action",
    label: "Goal 13: Climate Action",
  },
  {
    value: "Goal 14: Life Below Water",
    label: "Goal 14: Life Below Water",
  },
  {
    value: "Goal 15: Life on Land",
    label: "Goal 15: Life on Land",
  },
  {
    value: "Goal 16: Peace, Justice, and Strong Institutions",
    label: "Goal 16: Peace, Justice, and Strong Institutions",
  },
  {
    value: "Goal 17: Partnerships for the Goals",
    label: "Goal 17: Partnerships for the Goals",
  },
];

interface TokenConfig {
  id: number;
  user_id: number;
  asset_id: number;
  contractid: string;
  token_id: number;
  amount: number;
  token_id_name: string;
  token_id_type: string;
  credit_programme_name: string;
  methodology_id: string;
  corsia_compatiblity: string;
  reddplusplus_compatibility: string;
  number_of_sdg_goals: number;
  sdg_goals: string[];
  crs_rating: number;
  total_count: number;
}

const ApproveTokenRequestModal = ({
  open,
  onClose,
  requestID,
  setLoadingState,
  refreshData,
}: {
  open: boolean;
  onClose: () => void;
  requestID?: number;
    setLoadingState: (isLoading: boolean) => void;
    refreshData: () => void;
}) => {
  const navigate = useNavigate();
  const jwt = GetCookies(CookieItems.JWT);
  const { isTablet } = useBreakPoints();
  const [tokenConfigs, setTokenConfigs] = useState<TokenConfig[]>([]);
  const [selectedTokenConfig, setSelectedTokenConfig] = useState<TokenConfig | null>(null);
  const [errors, setErrors] = useState({
    crs_rating: "",
    sdg_goals: "",
    asset: ""
  });
  const [tokenizeReq, setTokenizeReq] = useState({
    assetID: {
      value: "",
      label: "",
    },
    selectedAsset: "",
    numberOfCredits: 0,
    tokenID: "",
    generateNewToken: true,
    projectName: "",
    projectType: "",
    corsia_compatible: {
      value: "not compatible",
      label: "Not Compatible",
    },
    redd_plus_plus_compatible: {
      value: "not compatible",
      label: "Not Compatible",
    },
    number_of_sdg_goals: 0,
    sdg_goals: Array<string>(),
    crs_rating: 0,
  });
  const [assets, setAssets] = useState<AssetType[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchTokenConfigs = async (assetId: string) => {
    try {
      const response = await axios.post(
        BASE_URL + GET_TOKEN_CONFIG_BY_ASSET_ID_NO_PAGINATION,
        {
          asset_id: assetId,
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      );
      setTokenConfigs(response.data);
    } catch (error: any) {
      if (error.response?.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Error fetching token configurations", error);
        toast.error("Error fetching token configurations");
      }
    }
  };

  useEffect(() => {
    if (tokenizeReq.assetID.value && !tokenizeReq.generateNewToken) {
      fetchTokenConfigs(tokenizeReq.assetID.value);
    }
  }, [tokenizeReq.assetID.value, tokenizeReq.generateNewToken]);

  function handleApproveTokenizeReq() {
    setLoading(true);
    setLoadingState(true);
    // const requestData = tokenizeReq.generateNewToken
    //   ? {
    const requestData = {
          request_id: requestID,
          asset_id: tokenizeReq.assetID?.value || "",
          token_id: tokenizeReq.tokenID,
          token_id_name: tokenizeReq.projectName,
          token_id_type: tokenizeReq.projectType,
          corsia_compatible: tokenizeReq.corsia_compatible?.value || "",
          redd_plus_plus_compatible:
            tokenizeReq.redd_plus_plus_compatible?.value || "",
      number_of_sdg_goals: tokenizeReq.sdg_goals.length,
          sdg_goals: tokenizeReq.sdg_goals.map((goal: any) => goal.value),
          crs_rating: tokenizeReq.crs_rating,
    }
    // : {
    //   request_id: requestID,
    //   asset_id: tokenizeReq.assetID?.value || "",
    //   token_id: selectedTokenConfig?.token_id || "",
    // };

    axios
      .post(BASE_URL + APPROVE_TOKENIZE_REQUEST, requestData, {
        headers: {
          Authorization: jwt,
        },
      })
      .then((response) => {
        setLoading(false);
        setLoadingState(false);
        toast.success("Credit Tokenized Request Approved Successfully");
        refreshData();
        // onClose();
      })
      .catch((error) => {
        setLoading(false);
        setLoadingState(false);
        if (error.response?.status === 429) {
          toast.error("Too many requests. Please try again later.");
        } else if (error.response?.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else if (!error.response?.data.hasOwnProperty("duplicate")) {
          console.log(error);
          toast.error("Error while approving credit tokenization");
          onClose();
        } else {
          toast.error(
            "Token ID name already exists for the selected TradePair"
          );
        }
      });
  }

  useEffect(() => {
    axios
      .post(
        BASE_URL + GET_ALL_ASSETS,
        {
          asset_id: 1,
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response) => {
        setAssets(response.data);
        setTokenizeReq((prevState) => ({
          ...prevState,
          assetID: response.data[0].asset_id.toString(),
        }));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while getting asset IDs", error);
          toast.error("Error while getting asset inbformation");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // Move validation to useMemo
  const validationResult = useMemo(() => {
    const newErrors = {
      crs_rating: "",
      sdg_goals: "",
      asset: ""
    };
    let isValid = true;

    // Validate CRS Rating
    const crsRating = Number(tokenizeReq.crs_rating);
    if (crsRating < 1 || crsRating > 5 || !Number.isInteger(crsRating)) {
      newErrors.crs_rating = "CRS Rating must be a whole number between 1 and 5";
      isValid = false;
    }

    // Validate SDG Goals
    if (tokenizeReq.sdg_goals.length === 0) {
      newErrors.sdg_goals = "At least one SDG goal must be selected";
      isValid = false;
    }

    // Validate Asset Selection
    if (!tokenizeReq.assetID.value) {
      newErrors.asset = "An asset must be selected";
      isValid = false;
    }

    return { isValid, errors: newErrors };
  }, [tokenizeReq.crs_rating, tokenizeReq.sdg_goals.length, tokenizeReq.assetID.value]);

  // Update errors when validation result changes
  useEffect(() => {
    setErrors(validationResult.errors);
  }, [validationResult]);

  // Replace validateForm with a function that returns the memoized validation result
  const validateForm = useCallback(() => {
    return validationResult.isValid;
  }, [validationResult.isValid]);

  return (
    <Modal centered size="lg" isOpen={open} toggle={onClose} scrollable backdrop="static">
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        Approve Tokenize Credit Request
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142", maxHeight: "calc(100vh - 210px)" }}>
        <Row className={isTablet ? "gap-4" : ""}>
          <Col lg={6} md={12}>
            <Label>Select Asset <span style={{ color: 'red' }}>*</span></Label>
            <CustomDropdown
              options={assets.map((item) => {
                return {
                  value: item.asset_id.toString(),
                  label: item.asset_name,
                };
              })}
              value={tokenizeReq.assetID}
              onChange={(e: any) => {
                setTokenizeReq((prevState) => ({
                  ...prevState,
                  assetID: e,
                }));
                setSelectedTokenConfig(null);
              }}
              isDisabled={false}
            />
            {errors.asset && <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{errors.asset}</div>}
          </Col>
          <Col lg={6} md={12}>
            <div>
              <p className={`mb-0`}>Do you want to create a new token ID? <span style={{ color: 'red' }}>*</span></p>
              <div className="mt-2">
                <button
                  type="button"
                  onClick={() => {
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      generateNewToken: true,
                    }));
                    setSelectedTokenConfig(null);
                  }}
                  style={{
                    border: "none",
                    background: tokenizeReq.generateNewToken
                      ? "rgb(58 163 81)"
                      : "#323d4e",
                    fontSize: 12,
                    padding: "7px 13px",
                    color: "white",
                    borderRadius: "5px 0px 0px 5px",
                  }}
                >
                  YES
                </button>
                <button
                  type="button"
                  onClick={() => {
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      generateNewToken: false,
                    }));
                  }}
                  style={{
                    border: "none",
                    background: !tokenizeReq.generateNewToken
                      ? "#00abff"
                      : "#323d4e",
                    fontSize: 12,
                    padding: "7px 13px",
                    color: "white",
                    borderRadius: "0px 5px 5px 0px",
                  }}
                >
                  NO
                </button>
              </div>
            </div>
          </Col>

          {!tokenizeReq.generateNewToken && tokenizeReq.assetID.value && (
            <>
              <Col lg={12} md={12} className="mt-4">
                <Label>Select Token ID <span style={{ color: 'red' }}>*</span></Label>
                <CustomDropdown
                  options={tokenConfigs.map((config) => ({
                    value: config.token_id,
                    label: `${config.token_id} - ${config.token_id_name}`,
                  }))}
                  value={selectedTokenConfig ? {
                    value: selectedTokenConfig.token_id,
                    label: `${selectedTokenConfig.token_id} - ${selectedTokenConfig.token_id_name}`,
                  } : null}
                  onChange={(e: any) => {
                    const config = tokenConfigs.find(c => c.token_id === e.value);
                    setSelectedTokenConfig(config || null);
                    setTokenizeReq(prevState => ({
                      ...prevState,
                      tokenID: e.value.toString()
                    }));
                  }}
                  isDisabled={false}
                />
              </Col>
              {selectedTokenConfig && (
                <Col lg={12} md={12} className="mt-4">
                  <div style={{
                    backgroundColor: "#1B2531",
                    borderRadius: "12px",
                    padding: "20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
                  }}>
                    <h6 style={{
                      color: "#00abff",
                      fontSize: "16px",
                      marginBottom: "20px",
                      fontWeight: 600,
                      letterSpacing: "0.5px"
                    }}>Token Details</h6>
                    <Row>
                      <Col lg={6} className="mb-3">
                        <div style={{ backgroundColor: "#273142", padding: "15px", borderRadius: "8px" }}>
                          <Label className="mb-1" style={{ color: "#8F9BB3", fontSize: "12px", fontWeight: 500 }}>
                            Contract ID
                          </Label>
                          <p style={{
                            margin: 0,
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: 500,
                            wordBreak: "break-all"
                          }}>{selectedTokenConfig.contractid}</p>
                        </div>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <div style={{ backgroundColor: "#273142", padding: "15px", borderRadius: "8px" }}>
                          <Label className="mb-1" style={{ color: "#8F9BB3", fontSize: "12px", fontWeight: 500 }}>
                            Amount
                          </Label>
                          <p style={{
                            margin: 0,
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: 500
                          }}>{selectedTokenConfig.amount}</p>
                        </div>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <div style={{ backgroundColor: "#273142", padding: "15px", borderRadius: "8px" }}>
                          <Label className="mb-1" style={{ color: "#8F9BB3", fontSize: "12px", fontWeight: 500 }}>
                            Project Name
                          </Label>
                          <p style={{
                            margin: 0,
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: 500
                          }}>{selectedTokenConfig.token_id_name}</p>
                        </div>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <div style={{ backgroundColor: "#273142", padding: "15px", borderRadius: "8px" }}>
                          <Label className="mb-1" style={{ color: "#8F9BB3", fontSize: "12px", fontWeight: 500 }}>
                            Project Type & CRS Rating
                          </Label>
                          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                            <p style={{
                              margin: 0,
                              color: "#fff",
                              fontSize: "14px",
                              fontWeight: 500,
                              flex: 1
                            }}>{selectedTokenConfig.token_id_type}</p>
                            <div style={{
                              backgroundColor: "#1B2531",
                              padding: "4px 10px",
                              borderRadius: "12px",
                              fontSize: "12px",
                              color: "#00abff",
                              fontWeight: 600
                            }}>
                              CRS RATING : {selectedTokenConfig.crs_rating}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <div style={{ backgroundColor: "#273142", padding: "15px", borderRadius: "8px" }}>
                          <Label className="mb-1" style={{ color: "#8F9BB3", fontSize: "12px", fontWeight: 500 }}>
                            Credit Programme
                          </Label>
                          <p style={{
                            margin: 0,
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: 500
                          }}>{selectedTokenConfig.credit_programme_name}</p>
                        </div>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <div style={{ backgroundColor: "#273142", padding: "15px", borderRadius: "8px" }}>
                          <Label className="mb-1" style={{ color: "#8F9BB3", fontSize: "12px", fontWeight: 500 }}>
                            Methodology ID
                          </Label>
                          <p style={{
                            margin: 0,
                            color: "#fff",
                            fontSize: "14px",
                            fontWeight: 500
                          }}>{selectedTokenConfig.methodology_id}</p>
                        </div>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <div style={{ backgroundColor: "#273142", padding: "15px", borderRadius: "8px" }}>
                          <Label className="mb-1" style={{ color: "#8F9BB3", fontSize: "12px", fontWeight: 500 }}>
                            CORSIA Compatibility
                          </Label>
                          <p style={{
                            margin: 0,
                            color: selectedTokenConfig.corsia_compatiblity === "compatible" ? "#3AA351" : "#FF3D71",
                            fontSize: "14px",
                            fontWeight: 500,
                            textTransform: "capitalize"
                          }}>{selectedTokenConfig.corsia_compatiblity}</p>
                        </div>
                      </Col>
                      <Col lg={6} className="mb-3">
                        <div style={{ backgroundColor: "#273142", padding: "15px", borderRadius: "8px" }}>
                          <Label className="mb-1" style={{ color: "#8F9BB3", fontSize: "12px", fontWeight: 500 }}>
                            REDD++ Compatibility
                          </Label>
                          <p style={{
                            margin: 0,
                            color: selectedTokenConfig.reddplusplus_compatibility === "compatible" ? "#3AA351" : "#FF3D71",
                            fontSize: "14px",
                            fontWeight: 500,
                            textTransform: "capitalize"
                          }}>{selectedTokenConfig.reddplusplus_compatibility}</p>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div style={{ backgroundColor: "#273142", padding: "15px", borderRadius: "8px" }}>
                          <Label className="mb-1" style={{ color: "#8F9BB3", fontSize: "12px", fontWeight: 500 }}>
                            SDG Goals
                          </Label>
                          <div style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "8px"
                          }}>
                            {selectedTokenConfig.sdg_goals.map((goal, index) => (
                              <span key={index} style={{
                                backgroundColor: "#1B2531",
                                padding: "6px 12px",
                                borderRadius: "16px",
                                fontSize: "12px",
                                color: "#fff",
                                fontWeight: 500
                              }}>
                                {goal}
                              </span>
                            ))}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}
            </>
          )}

          {tokenizeReq.generateNewToken === true && (
            <>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>Project Name: <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  type="text"
                  className=""
                  value={tokenizeReq.projectName}
                  onchange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      projectName: e.target.value,
                    }))
                  }
                  placeholder="Enter Project Name"
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>Project Type: <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  type="text"
                  className=""
                  value={tokenizeReq.projectType}
                  onchange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      projectType: e.target.value,
                    }))
                  }
                  placeholder="Enter Project Type"
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>CRS Rating : <span style={{ color: 'red' }}>*</span></Label>
                <CustomInputBox
                  type="number"
                  className=""
                  value={tokenizeReq.crs_rating.toString()}
                  onchange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      crs_rating: Number(e.target.value),
                    }))
                  }
                  placeholder="Enter CRS Rating"
                />
                {errors.crs_rating && <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{errors.crs_rating}</div>}
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>Project CORSIA Compatible? <span style={{ color: 'red' }}>*</span></Label>
                <CustomDropdown
                  options={[
                    {
                      value: "not compatible",
                      label: "Not Compatible",
                    },
                    {
                      value: "compatible",
                      label: "Compatible",
                    },
                  ]}
                  value={tokenizeReq.corsia_compatible}
                  onChange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      corsia_compatible: e,
                    }))
                  }
                  isDisabled={false}
                />
              </Col>
              <Col lg={6} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>Project REDD++ Compatible? <span style={{ color: 'red' }}>*</span></Label>
                <CustomDropdown
                  options={[
                    {
                      value: "not compatible",
                      label: "Not Compatible",
                    },
                    {
                      value: "compatible",
                      label: "Compatible",
                    },
                  ]}
                  value={tokenizeReq.redd_plus_plus_compatible}
                  onChange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      redd_plus_plus_compatible: e,
                    }))
                  }
                  isDisabled={false}
                />
              </Col>
              <Col lg={12} md={12} className={`${isTablet ? "" : "mt-4"}`}>
                <Label>SDG Goals <span style={{ color: 'red' }}>*</span></Label>
                <CustomDropdown
                  options={SDGOptions}
                  value={tokenizeReq.sdg_goals}
                  onChange={(e: any) =>
                    setTokenizeReq((prevState) => ({
                      ...prevState,
                      sdg_goals: e,
                    }))
                  }
                  multiple={true}
                  isDisabled={false}
                  menuPlacement={"top"}
                />
                {errors.sdg_goals && <div style={{ color: 'red', fontSize: '12px', marginTop: '4px' }}>{errors.sdg_goals}</div>}
              </Col>
            </>
          )}
        </Row>
      </ModalBody>

      <ModalFooter className="pt-4" style={{ backgroundColor: "#273142" }}>
        <CustomButton
          placeholder="Submit"
          color="#00abff"
          type="button"
          disabled={loading || (!tokenizeReq.generateNewToken && !selectedTokenConfig) || !validateForm()}
          loading={loading}
          onClick={() => {
            handleApproveTokenizeReq();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ApproveTokenRequestModal;
