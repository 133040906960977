import React from "react";
import "react-international-phone/style.css";
import {
  Input,
  InputGroup,
  // Temporarily disabled dropdown components
  // Dropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from "reactstrap";
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
} from "react-international-phone";

interface CustomPhoneInputProps {
  label: string;
  value: string;
  error?: string;
  onChange: (phone: string) => void;
  defaultCountry: CountryIso2;
}

const CustomPhoneInput: React.FC<CustomPhoneInputProps> = ({
  label,
  value,
  error,
  onChange,
  defaultCountry, // Keep this prop for future compatibility
}) => {
  // Find India in the default countries
  const indiaCountry = defaultCountries.find(c => parseCountry(c).iso2 === 'in');
  if (!indiaCountry) {
    throw new Error('India country data not found');
  }

  // Handle input change manually instead of using usePhoneInput
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    // Remove any non-numeric characters
    newValue = newValue.replace(/\D/g, '');

    // Limit to 10 digits
    newValue = newValue.slice(0, 10);

    // Add +91 prefix when sending to parent
    onChange('+91' + newValue);

    // Set error if number is not exactly 10 digits
    if (newValue.length > 0 && newValue.length !== 10) {
      error = "Phone number must be exactly 10 digits";
    }
  };

  // Remove +91 prefix for display
  const displayValue = value.startsWith('+91') ? value.slice(3) : value;

  return (
    <div className="custom-input">
      <InputGroup>
        {/* Static India flag and +91 prefix */}
        <div
          style={{
            height: 48,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#323d4e",
            borderColor: "#444e5d",
            border: "1px solid #444e5d",
            padding: "0 10px",
            gap: "5px"
          }}
        >
          <FlagImage iso2="in" style={{ display: "flex" }} />
          <span style={{ color: "white", userSelect: "none" }}>+91</span>
        </div>

        {/* Original dropdown code - commented out for future reference
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            caret
            style={{
              height: 48,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#323d4e",
              borderColor: "#444e5d",
              border: "1px solid #444e5d",
            }}
          >
            <FlagImage iso2={country.iso2} style={{ display: "flex" }} />
          </DropdownToggle>
          <DropdownMenu
            style={{
              maxHeight: "300px",
              overflow: "auto",
              backgroundColor: "#323d4e",
            }}
          >
            {defaultCountries.map((c: any) => {
              const countryData = parseCountry(c);
              return (
                <DropdownItem
                  key={countryData.iso2}
                  onClick={() => setCountry(countryData.iso2)}
                  style={{ color: "#fff" }}
                >
                  <FlagImage
                    iso2={countryData.iso2}
                    style={{ marginRight: "8px" }}
                  />
                  <span style={{ marginRight: "8px" }}>{countryData.name}</span>
                  <span style={{ color: "#fff" }}>
                    +{countryData.dialCode}
                  </span>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
        */}

        <Input
          type="tel"
          name="phone"
          id="phone"
          bsSize={"lg"}
          placeholder={label}
          value={displayValue.replace(/\D/g, '')}
          onChange={handleInputChange}
          maxLength={10}
          style={{
            height: 47,
            backgroundColor: "#323d4e",
            color: "white",
            borderColor: error ? "#dc3545" : "#495057",
          }}
          invalid={!!error}
        />
      </InputGroup>
      {error && <div className="text-danger small mt-1">{error}</div>}
    </div>
  );
};

export default CustomPhoneInput;
