import React, { useContext, useState, useEffect } from "react";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import RuleIcon from '@mui/icons-material/Rule';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

import logo from "../assets/images/logo.png";
import wallet from "../assets/svg/wallet.svg";
import Trading from "../assets/svg/Trade.svg";
import TradePair from "../assets/svg/TradePair.svg";
import MyOrder from "../assets/svg/MyOrder.svg";
import CorporateRequest from "../assets/svg/CorporateRequest.svg";
import Transaction from "../assets/svg/transaction.svg";
import MenuIcon from "../assets/svg/Menu.svg";
import { UserContext } from "../helpers/types/contextType";
import { AccessControl, Role } from "../helpers/types/role";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import useBreakPoints from "../hooks/useBreakPoints";
import { CookieItems, GetCookies } from "../helpers/cookies_helper";

export const Sidebar = (): JSX.Element => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  const { userId } = userContext;
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isMobile } = useBreakPoints();

  useEffect(() => {
    const jwt = GetCookies(CookieItems.JWT);
    setIsAuthenticated(!!jwt && !!userId);
  }, [userId]);

  if (!isAuthenticated) {
    return <></>;
  }

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setDrawerOpen(open);
    };

  return isAuthenticated ? (
    <>
      <div className="d-flex justify-content-center align-items-center gap-4">
        {/* <Button onClick={toggleDrawer(true)}>
					<div className="frame-1">
						<div
							className="frame-3"
							onClick={() => {
								toggleDrawer(true);
							}}
						>
							<div className="rectangle"></div>
							<div className="rectangle"></div>
							<div className="rectangle"></div>
						</div>
					</div>
				</Button> */}

        <img
          onClick={toggleDrawer(true)}
          style={{ cursor: "pointer", marginLeft: isMobile ? 0 : 16 }}
          src={MenuIcon}
          alt="Logo"
        />
      </div>

      <Drawer
        anchor={isMobile ? "right" : "left"}
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: {
            backgroundColor: "#252f3f", // Custom Background Color
            color: "white", // Custom Text Color
          },
        }}
      >
        <Box
          sx={{ width: 250, backgroundColor: "#273142" }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <div className="nav-dropdown-menu">
            {!isMobile && (
              <div className="d-flex justify-content-center align-items-center mt-4">
                <img className="logo" alt="Logo" height={30} src={logo} />
              </div>
            )}
            <List
              sx={{
                color: "white",
                marginLeft: 1,
                borderRadius: "5px 0px 0px 5px",
              }}
            >
              {userId !== null &&
                userId.role !== null &&
                AccessControl.wallet.includes(userId.role as Role) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      className={`${
                        location.pathname === "/wallet"
                          ? "custom-sidebar-list"
                          : "custom-sidebar-list-2"
                      }`}
                      component={Link}
                      to="/wallet"
                    >
                      <ListItemIcon>
                        <img src={wallet} alt="wallet" />
                      </ListItemIcon>
                      <ListItemText primary={"Wallet"} />
                    </ListItemButton>
                  </ListItem>
                )}

              {userId !== null &&
                userId.role !== null &&
                AccessControl.trading.includes(userId.role as Role) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      className={`${
                        location?.pathname?.split("/")[1] === "trading"
                          ? "custom-sidebar-list"
                          : "custom-sidebar-list-2"
                      }`}
                      component={Link}
                      to="/trading/1"
                    >
                      <ListItemIcon>
                        <img src={Trading} alt="Trading" />
                      </ListItemIcon>
                      <ListItemText primary={"Trading"} />
                    </ListItemButton>
                  </ListItem>
                )}

              {userId !== null &&
                userId.role !== null &&
                AccessControl.explore.includes(userId.role as Role) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      className={`${
                        location.pathname === "/explore"
                          ? "custom-sidebar-list"
                          : "custom-sidebar-list-2"
                      }`}
                      component={Link}
                      to="/explore"
                    >
                      <ListItemIcon>
                        <img src={TradePair} alt="TradePair" />
                      </ListItemIcon>
                      <ListItemText primary={"Explore Trade Pairs"} />
                    </ListItemButton>
                  </ListItem>
                )}

              {/* {userId !== null &&
                userId.role !== null &&
                AccessControl.myorders.includes(userId.role as Role) && (
                  <>
                    <hr className="mb-2" />
                    <span
                      style={{ paddingLeft: 18, opacity: "90%", fontSize: 14 }}
                    >
                      Personalized
                    </span>{" "}
                  </>
                )} */}

              {userId !== null &&
                userId.role !== null &&
                AccessControl.myorders.includes(userId.role as Role) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      className={`${
                        location.pathname === "/myorders"
                          ? "custom-sidebar-list"
                          : "custom-sidebar-list-2"
                      }`}
                      component={Link}
                      to="/myorders"
                    >
                      <ListItemIcon>
                        <img src={MyOrder} alt="MyOrder" />
                      </ListItemIcon>
                      <ListItemText primary={"My Orders"} />
                    </ListItemButton>
                  </ListItem>
                )}
              {/* {userId !== null &&
                userId.role !== null &&
                AccessControl.myorders.includes(userId.role as Role) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      className={`${
                        location.pathname === "/transactions"
                          ? "custom-sidebar-list"
                          : "custom-sidebar-list-2"
                      }`}
                      component={Link}
                      to="/transactions"
                    >
                      <ListItemIcon>
                        <img src={Transaction} alt="Transaction" />
                      </ListItemIcon>
                      <ListItemText primary={"My Transactions"} />
                    </ListItemButton>
                  </ListItem>
                )} */}
              {userId !== null &&
                userId.role !== null &&
                AccessControl.myrequests.includes(userId.role as Role) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      className={`${
                        location.pathname === "/myrequests"
                          ? "custom-sidebar-list"
                          : "custom-sidebar-list-2"
                      }`}
                      component={Link}
                      to="/myrequests"
                    >
                      <ListItemIcon>
                      <AssignmentIcon sx={{ color: 'white', fontSize: 32 }} />
                      </ListItemIcon>
                      <ListItemText primary={"My Credit Requests"} />
                    </ListItemButton>
                  </ListItem>
                )}

              {userId !== null &&
                userId.role !== null &&
                AccessControl.admin.includes(userId.role as Role) && (
                  <>
                    {" "}
                    <hr className="mb-2" />
                    <span
                      style={{ paddingLeft: 18, opacity: "90%", fontSize: 14 }}
                    >
                      Admin
                    </span>{" "}
                  </>
                )}

              {userId !== null &&
                userId.role !== null &&
                AccessControl.admin.includes(userId.role as Role) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      className={`${
                        location.pathname === "/credit-request-management"
                          ? "custom-sidebar-list"
                          : "custom-sidebar-list-2"
                      }`}
                      component={Link}
                      to="/credit-request-management"
                    >
                      <ListItemIcon>
                      <RuleIcon sx={{ color: 'white', fontSize: 32 }} />
                      </ListItemIcon>
                      <ListItemText primary={"Credit Request Management"} />
                    </ListItemButton>
                  </ListItem>
                )}

              {userId !== null &&
                userId.role !== null &&
                AccessControl.corprequestmngmt.includes(
                  userId.role as Role
                ) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      className={`${
                        location.pathname === "/corporate-request-management"
                          ? "custom-sidebar-list"
                          : "custom-sidebar-list-2"
                      }`}
                      component={Link}
                      to="/corporate-request-management"
                    >
                      <ListItemIcon>
                        <img src={CorporateRequest} alt="CorporateRequest" />
                      </ListItemIcon>
                      <ListItemText primary={"Corporate Request Management"} />
                    </ListItemButton>
                  </ListItem>
                )}

              {userId !== null &&
                userId.role !== null &&
                AccessControl.admin.includes(userId.role as Role) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      className={`${
                        location.pathname === "/addassets"
                          ? "custom-sidebar-list"
                          : "custom-sidebar-list-2"
                      }`}
                      component={Link}
                      to="/addassets"
                    >
                      <ListItemIcon>
                      <AddCircleOutlineIcon sx={{ color: 'white', fontSize: 32 }} />
                      </ListItemIcon>
                      <ListItemText primary={"Add Assets"} />
                    </ListItemButton>
                  </ListItem>
                )}

              {userId !== null &&
                userId.role !== null &&
                AccessControl.admin.includes(userId.role as Role) && (
                  <ListItem disablePadding>
                    <ListItemButton
                      className={`${location.pathname === "/user-management"
                        ? "custom-sidebar-list"
                        : "custom-sidebar-list-2"
                        }`}
                      component={Link}
                      to="/user-management"
                    >
                      <ListItemIcon>
                      <ManageAccountsIcon sx={{ color: 'white', fontSize: 32 }} />
                      </ListItemIcon>
                      <ListItemText primary={"User Management"} />
                    </ListItemButton>
                  </ListItem>
                )}

              {/* Add more menu items as needed */}
            </List>
          </div>
        </Box>
      </Drawer>
    </>
  ) : (
    <></>
  );
};
