import React, { useContext, useEffect, useState } from "react";
import useBreakPoints from "../../hooks/useBreakPoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Box, Drawer, Modal, TablePagination } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FilterIcon from "../../assets/svg/Filter-Icon.svg";
import { UserContext } from "../../helpers/types/contextType";
import { Col, Label } from "reactstrap";
import CustomDropdown from "../../Component/CustomDropdown";
import { BASE_URL } from "../../helpers/api_helper";
import axios from "axios";
import CustomButton from "../../Component/CustomButton";
import { UnixUTCtoLocalTime } from "../../helpers/time_converter_helper";

interface UserType {
    id: number;
    email: string;
    username: string;
    name: string;
    address: string;
    is_active: boolean;
    role: string;
    registration_timestamp: number;
    last_login: number;
}

interface UserResponseType {
    users: UserType[];
    total_count: number;
    total_pages: number;
    current_page: number;
}

const roleOptions = [
    { label: "All", value: "All" },
    { label: "Admin", value: "admin" },
    { label: "User", value: "user" },
    { label: "Corporate", value: "corporate" },
    { label: "Unverified", value: "unverified" },
    { label: "Unverified Corporate", value: "unverified_corporate" },
];

const roleColors = {
    admin: "#00abff",
    user: "#fd9a56",
    corporate: "#9b59b6",
    unverified: "#95a5a6",
    unverified_corporate: "#34495e",
};

const statusColors = {
    active: "#1ccab8",
    inactive: "#e94953",
};

const UserManagement = () => {
    const { isMobile, isTablet } = useBreakPoints();
    const isSmallScreen = isMobile || isTablet;
    const userContext = useContext(UserContext);
    if (!userContext) {
        throw new Error("UserContext is undefined");
    }
    const { userId } = userContext;
    const navigate = useNavigate();

    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [users, setUsers] = useState<UserType[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [filters, setFilters] = useState({
        role: { label: "All", value: "All" },
        page: 0,
    });
    const [updateRoleModal, setUpdateRoleModal] = useState({
        open: false,
        userId: null as number | null,
        selectedRole: null as { label: string; value: string } | null,
    });
    const [searchQuery, setSearchQuery] = useState("");
    const [searchBy, setSearchBy] = useState({ label: "Name", value: "name" });
    const [isSearchExpanded, setIsSearchExpanded] = useState(false);
    const [pageSize, setPageSize] = useState(25);

    const searchOptions = [
        { label: "Name", value: "name" },
        { label: "Email", value: "email" },
        { label: "User ID", value: "id" }
    ];

    useEffect(() => {
        const calculatePageSize = () => {
            const screenHeight = window.innerHeight;
            if (isSmallScreen) {
                return Math.max(10, Math.floor((screenHeight - 200) / 160));
            }
            return Math.max(25, Math.floor((screenHeight - 250) / 45));
        };

        const handleResize = () => {
            setPageSize(calculatePageSize());
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isSmallScreen]);

    useEffect(() => {
        fetchUsers();
    }, [filters]);

    const fetchUsers = async () => {
        try {
            const response = await axios.post(
                BASE_URL + "admin/users",
                {
                    page: filters.page,
                    page_size: pageSize,
                    role: filters.role.value === "All" ? null : filters.role.value,
                },
                {
                    headers: {
                        Authorization: userId?.jwt,
                    },
                }
            );
            const data: UserResponseType = response.data;
            setUsers(data.users);
            setTotalCount(data.total_count);
        } catch (error: any) {
            if (error.response?.status === 401) {
                toast.error("Session expired, please login again", {
                    toastId: "sessionExp",
                });
                localStorage.clear();
                navigate("/logout");
            } else {
                console.error("Error while fetching users", error);
                toast.error("Error while fetching users");
            }
        }
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setFilters({ ...filters, page: newPage });
    };

    const toggleDrawer = (newOpen: boolean) => () => {
        setDrawerOpen(newOpen);
    };

    const handleUpdateRole = async () => {
        if (!updateRoleModal.selectedRole || !updateRoleModal.userId) return;

        try {
            await axios.post(
                BASE_URL + "admin/update_user_role",
                {
                    user_id: updateRoleModal.userId,
                    role: updateRoleModal.selectedRole.value,
                },
                {
                    headers: {
                        Authorization: userId?.jwt,
                    },
                }
            );
            toast.success("User role updated successfully");
            setUpdateRoleModal({
                open: false,
                userId: null,
                selectedRole: null,
            });
            fetchUsers();
        } catch (error: any) {
            if (error.response?.status === 401) {
                toast.error("Session expired, please login again");
                localStorage.clear();
                navigate("/logout");
            } else {
                console.error("Error while updating user role", error);
                toast.error("Error while updating user role");
            }
        }
    };

    const filteredUsers = users.filter(user => {
        if (!searchQuery) return true;

        const query = searchQuery.toLowerCase();
        switch (searchBy.value) {
            case "name":
                return user.name.toLowerCase().includes(query);
            case "email":
                return user.email.toLowerCase().includes(query);
            case "id":
                return user.id.toString().includes(query);
            default:
                return true;
        }
    });

    const renderMobileView = (user: UserType, index: number) => (
        <div
            key={user.id}
            style={{
                backgroundColor: "#1b2431",
                borderRadius: "12px",
                padding: "20px",
                marginBottom: "15px",
                border: "1px solid rgba(68, 78, 93, 0.5)",
                position: "relative",
                overflow: "hidden",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
        >
            <div style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "4px",
                height: "100%",
                background: `linear-gradient(180deg, ${roleColors[user.role as keyof typeof roleColors] || "#00abff"} 0%, rgba(0, 171, 255, 0.2) 100%)`,
            }} />

            <div style={{
                position: "absolute",
                top: 15,
                right: 15,
                fontSize: 12,
                color: "#8795a9",
                opacity: 0.5
            }}>
                #{filters.page * 25 + index + 1}
            </div>

            <div className="d-flex justify-content-between align-items-start mb-3" style={{ paddingLeft: "15px" }}>
                <div>
                    <div style={{ fontSize: 18, fontWeight: 600, marginBottom: 4 }}>{user.name}</div>
                    <div style={{
                        fontSize: 14,
                        color: "#8795a9",
                        padding: "4px 12px",
                        background: "rgba(135, 149, 169, 0.1)",
                        borderRadius: "4px",
                        display: "inline-block"
                    }}>{user.email}</div>
                </div>
            </div>

            <div className="d-flex flex-wrap gap-2" style={{ paddingLeft: "15px" }}>
                <span
                    style={{
                        padding: "4px 12px",
                        background: user.is_active ? statusColors.active : statusColors.inactive,
                        color: "white",
                        borderRadius: "26px",
                        fontSize: 13,
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "6px"
                    }}
                >
                    <span style={{
                        width: "6px",
                        height: "6px",
                        borderRadius: "50%",
                        background: "white",
                        opacity: 0.8
                    }} />
                    {user.is_active ? "Active" : "Inactive"}
                </span>
                <span
                    style={{
                        padding: "4px 12px",
                        background: roleColors[user.role as keyof typeof roleColors] || "#00abff",
                        color: "white",
                        borderRadius: "26px",
                        fontSize: user.role.length > 9 ? 12 : 13,
                        textTransform: "capitalize",
                        minWidth: user.role.length > 9 ? "140px" : "80px",
                        textAlign: "center",
                        display: "inline-block"
                    }}
                >
                    {user.role.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                </span>
            </div>

            <div style={{
                fontSize: 14,
                color: "#8795a9",
                marginTop: 15,
                paddingLeft: "15px",
                background: "rgba(27, 36, 49, 0.5)",
                padding: "12px 15px",
                borderRadius: "8px",
                border: "1px solid rgba(68, 78, 93, 0.2)"
            }}>
                <div className="d-flex gap-2">
                    <span style={{ color: "#8795a9", opacity: 0.7 }}>ID:</span>
                    <span>{user.id}</span>
                </div>
                <div className="d-flex gap-2">
                    <span style={{ color: "#8795a9", opacity: 0.7 }}>Username:</span>
                    <span>{user.username}</span>
                </div>
                {user.address && (
                    <div className="d-flex gap-2">
                        <span style={{ color: "#8795a9", opacity: 0.7 }}>Address:</span>
                        <span>{user.address}</span>
                    </div>
                )}
                <div className="d-flex gap-2">
                    <span style={{ color: "#8795a9", opacity: 0.7 }}>Registered:</span>
                    <span>{UnixUTCtoLocalTime(user.registration_timestamp)}</span>
                </div>
                <div className="d-flex gap-2">
                    <span style={{ color: "#8795a9", opacity: 0.7 }}>Last Login:</span>
                    <span>{UnixUTCtoLocalTime(user.last_login)}</span>
                </div>
            </div>

            <div style={{ marginTop: 15, paddingLeft: "15px" }}>
                <CustomButton
                    placeholder={"Update Role"}
                    color={"#07bc0c"}
                    style={{
                        padding: "8px 20px",
                        fontSize: 14,
                        width: "100%",
                        background: "linear-gradient(90deg, #07bc0c 0%, #049e08 100%)",
                        boxShadow: "0 2px 4px rgba(7, 188, 12, 0.2)"
                    }}
                    type="button"
                    onClick={() => {
                        setUpdateRoleModal({
                            open: true,
                            userId: user.id,
                            selectedRole: {
                                label: user.role.replace("_", " ").replace(/\b\w/g, l => l.toUpperCase()),
                                value: user.role
                            },
                        });
                    }}
                />
            </div>
        </div>
    );

    const renderDesktopView = () => (
        <table>
            <thead>
                <tr>
                    <th style={{
                        textWrap: "nowrap",
                        borderTop: "1px solid #444e5d",
                        borderBottom: "1px solid #444e5d",
                        borderLeft: "1px solid #444e5d",
                        borderRight: "none",
                        borderRadius: "12px 0 0 12px",
                    }}>ID</th>
                    <th style={{
                        textWrap: "nowrap",
                        borderTop: "1px solid #444e5d",
                        borderBottom: "1px solid #444e5d",
                        borderLeft: "none",
                        borderRight: "none",
                    }}>Email</th>
                    <th style={{
                        textWrap: "nowrap",
                        borderTop: "1px solid #444e5d",
                        borderBottom: "1px solid #444e5d",
                        borderLeft: "none",
                        borderRight: "none",
                    }}>Username</th>
                    <th style={{
                        textWrap: "nowrap",
                        borderTop: "1px solid #444e5d",
                        borderBottom: "1px solid #444e5d",
                        borderLeft: "none",
                        borderRight: "none",
                    }}>Name</th>
                    <th style={{
                        textWrap: "nowrap",
                        borderTop: "1px solid #444e5d",
                        borderBottom: "1px solid #444e5d",
                        borderLeft: "none",
                        borderRight: "none",
                    }}>Address</th>
                    <th style={{
                        textWrap: "nowrap",
                        borderTop: "1px solid #444e5d",
                        borderBottom: "1px solid #444e5d",
                        borderLeft: "none",
                        borderRight: "none",
                    }}>Status</th>
                    <th style={{
                        textWrap: "nowrap",
                        borderTop: "1px solid #444e5d",
                        borderBottom: "1px solid #444e5d",
                        borderLeft: "none",
                        borderRight: "none",
                    }}>Current Role</th>
                    <th style={{
                        textWrap: "nowrap",
                        borderTop: "1px solid #444e5d",
                        borderBottom: "1px solid #444e5d",
                        borderLeft: "none",
                        borderRight: "none",
                    }}>Registered</th>
                    <th style={{
                        textWrap: "nowrap",
                        borderTop: "1px solid #444e5d",
                        borderBottom: "1px solid #444e5d",
                        borderLeft: "none",
                        borderRight: "none",
                    }}>Last Login</th>
                    <th style={{
                        textWrap: "nowrap",
                        borderTop: "1px solid #444e5d",
                        borderBottom: "1px solid #444e5d",
                        borderLeft: "none",
                        borderRight: "1px solid #444e5d",
                        borderRadius: "0 12px 12px 0",
                    }}>Action</th>
                </tr>
            </thead>
            <tbody>
                {users.map((user, index) => (
                    <tr key={index}>
                        <td style={{ textWrap: "wrap", padding: "3px 20px" }}>{user.id}</td>
                        <td style={{ textWrap: "wrap", padding: "3px 20px" }}>{user.email}</td>
                        <td style={{ textWrap: "wrap", padding: "3px 20px" }}>{user.username}</td>
                        <td style={{ textWrap: "wrap", padding: "3px 20px" }}>{user.name}</td>
                        <td style={{ textWrap: "wrap", padding: "3px 20px" }}>{user.address}</td>
                        <td style={{ textWrap: "wrap", padding: "3px 20px" }}>
                            <span style={{
                                padding: "5px 15px",
                                background: user.is_active ? statusColors.active : statusColors.inactive,
                                color: "white",
                                borderRadius: "26px",
                                fontSize: 15,
                                lineHeight: "16.37px",
                            }}>
                                {user.is_active ? "Active" : "Inactive"}
                            </span>
                        </td>
                        <td style={{ textWrap: "wrap", padding: "3px 20px" }}>
                            <span style={{
                                padding: "5px 15px",
                                background: roleColors[user.role as keyof typeof roleColors] || "#00abff",
                                color: "white",
                                borderRadius: "26px",
                                fontSize: user.role.length > 9 ? 12 : 15,
                                lineHeight: user.role.length > 9 ? "14px" : "16.37px",
                                textTransform: "capitalize",
                                whiteSpace: user.role.length > 9 ? "normal" : "nowrap",
                                display: "inline-block",
                                margin: "2px 0",
                                minHeight: user.role.length > 9 ? "32px" : "auto",
                                textAlign: "center",
                                minWidth: user.role.length > 9 ? "140px" : "80px",
                                maxWidth: user.role.length > 9 ? "140px" : "none",
                                wordBreak: "keep-all",
                                wordWrap: "break-word"
                            }}>
                                {user.role.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")}
                            </span>
                        </td>
                        <td style={{ textWrap: "wrap", padding: "3px 20px" }}>{UnixUTCtoLocalTime(user.registration_timestamp)}</td>
                        <td style={{ textWrap: "wrap", padding: "3px 20px" }}>{UnixUTCtoLocalTime(user.last_login)}</td>
                        <td>
                            <CustomButton
                                placeholder={"Update Role"}
                                color={"#07bc0c"}
                                style={{
                                    padding: "8px 30px",
                                    fontSize: 15,
                                    lineHeight: "16.37px",
                                }}
                                type="button"
                                onClick={() => {
                                    setUpdateRoleModal({
                                        open: true,
                                        userId: user.id,
                                        selectedRole: {
                                            label: user.role.replace("_", " ").replace(/\b\w/g, l => l.toUpperCase()),
                                            value: user.role
                                        },
                                    });
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    return (
        <div className="mt-3 p-4 pt-0">
            <div className="d-flex justify-content-between align-items-center w-100 mb-4">
                <span style={{
                    textTransform: "capitalize",
                    fontSize: isSmallScreen ? 20 : 32,
                    fontWeight: 700,
                    lineHeight: isSmallScreen ? "24px" : "43.65px",
                    letterSpacing: "-0.11px",
                }}>
                    User Management
                </span>
                <div className="d-flex align-items-center gap-3">
                    {!isSmallScreen && users?.length > 0 && (
                        <TablePagination
                            component="div"
                            count={totalCount || pageSize}
                            page={filters.page}
                            onPageChange={handleChangePage}
                            rowsPerPage={pageSize}
                            rowsPerPageOptions={[]}
                            sx={{
                                color: "white",
                                "& .MuiTablePagination-selectLabel": {
                                    color: "white",
                                    marginBottom: 0,
                                },
                                "& .MuiTablePagination-displayedRows": {
                                    color: "white",
                                    marginBottom: 0,
                                },
                                "& .Mui-disabled": {
                                    color: "grey",
                                    opacity: 0.5,
                                },
                                "& .MuiTablePagination-actions": {
                                    "& .MuiIconButton-root.Mui-disabled": {
                                        color: "grey",
                                    },
                                    "& .MuiIconButton-root": {
                                        color: users?.length > 0 ? "white" : "grey",
                                        pointerEvents: users?.length > 0 ? "auto" : "none",
                                    },
                                },
                            }}
                        />
                    )}
                    <img
                        onClick={toggleDrawer(true)}
                        style={{ cursor: "pointer" }}
                        src={FilterIcon}
                        alt="Filter Icon"
                    />
                </div>
            </div>

            <div className={`admin-table ${isSmallScreen ? "" : "table-responsive"}`}>
                {isSmallScreen ? (
                    <>
                        <div style={{
                            marginBottom: 15,
                            background: "#1b2431",
                            borderRadius: "12px",
                            overflow: "hidden",
                            transition: "all 0.3s ease"
                        }}>
                            <div
                                style={{
                                    padding: "12px 15px",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                    background: "#1b2431",
                                    borderRadius: "12px"
                                }}
                            >
                                <div style={{ minWidth: "120px" }}>
                                    <CustomDropdown
                                        options={searchOptions}
                                        value={searchBy}
                                        onChange={(e: any) => setSearchBy(e)}
                                        isDisabled={false}
                                        placeholder="Search by"
                                    />
                                </div>
                                <input
                                    type="text"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    placeholder={`Enter ${searchBy.label.toLowerCase()}...`}
                                    style={{
                                        flex: 1,
                                        padding: "8px 12px",
                                        backgroundColor: "#273142",
                                        border: "1px solid rgba(68, 78, 93, 0.5)",
                                        borderRadius: "8px",
                                        color: "white",
                                        fontSize: "14px",
                                        outline: "none"
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            {filteredUsers.map((user, index) => renderMobileView(user, index))}
                        </div>
                        {users?.length > 0 && (
                            <div className="mt-3 mb-4">
                                <TablePagination
                                    component="div"
                                    count={totalCount || pageSize}
                                    page={filters.page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={pageSize}
                                    rowsPerPageOptions={[]}
                                    sx={{
                                        color: "white",
                                        backgroundColor: "#1b2431",
                                        borderRadius: "8px",
                                        padding: "8px",
                                        "& .MuiTablePagination-selectLabel": {
                                            color: "white",
                                            marginBottom: 0,
                                        },
                                        "& .MuiTablePagination-displayedRows": {
                                            color: "white",
                                            marginBottom: 0,
                                        },
                                        "& .Mui-disabled": {
                                            color: "grey",
                                            opacity: 0.5,
                                        },
                                        "& .MuiTablePagination-actions": {
                                            "& .MuiIconButton-root.Mui-disabled": {
                                                color: "grey",
                                            },
                                            "& .MuiIconButton-root": {
                                                color: users?.length > 0 ? "white" : "grey",
                                                pointerEvents: users?.length > 0 ? "auto" : "none",
                                            },
                                        },
                                    }}
                                />
                            </div>
                        )}
                    </>
                ) : (
                    renderDesktopView()
                )}
            </div>

            <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                PaperProps={{
                    sx: {
                        width: isSmallScreen ? "100%" : "400px",
                        backgroundColor: "#273142",
                        color: "white",
                        padding: "20px",
                    }
                }}
            >
                <Box
                    sx={{
                        height: "100%",
                        padding: "20px",
                    }}
                    role="presentation"
                >
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <span
                            style={{
                                fontSize: 24,
                                fontWeight: 600,
                            }}
                        >
                            Filters
                        </span>
                    </div>
                    <Col md={12} className="mt-4">
                        <CustomButton
                            placeholder="Clear Filters"
                            color="#323D4E"
                            style={{
                                width: "60%",
                                padding: "10px",
                                fontSize: 16,
                                marginBottom: "10px",
                            }}
                            onClick={() => {
                                setFilters({
                                    role: { label: "All", value: "All" },
                                    page: 0,
                                });
                                setTimeout(() => {
                                    setDrawerOpen(false);
                                }, 200);
                            }}
                        />
                    </Col>
                    <div className="flex-column">
                        <Col md={12} className="mb-4">
                            <Label
                                style={{
                                    fontSize: 16,
                                    marginBottom: 10,
                                }}
                            >
                                Filter by Role
                            </Label>
                            <CustomDropdown
                                options={roleOptions}
                                value={filters.role}
                                onChange={(e: any) => {
                                    setFilters({ ...filters, role: e });
                                    setTimeout(() => {
                                        setDrawerOpen(false);
                                    }, 200);
                                }}
                                isDisabled={false}
                                placeholder="Filter by Role"
                            />
                        </Col>

                    </div>
                </Box>
            </Drawer>

            <Modal
                open={updateRoleModal.open}
                onClose={() => setUpdateRoleModal({ ...updateRoleModal, open: false })}
                aria-labelledby="update-role-modal"
                aria-describedby="modal-to-update-user-role"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: isSmallScreen ? '90%' : 400,
                        bgcolor: '#273142',
                        borderRadius: '8px',
                        boxShadow: 24,
                        p: 4,
                        color: 'white',
                    }}
                >
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <span style={{ fontSize: 24, fontWeight: 600 }}>Update Role</span>
                            <CloseIcon
                                onClick={() => setUpdateRoleModal({ ...updateRoleModal, open: false })}
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: 24,
                                    color: 'white',
                                    '&:hover': {
                                        opacity: 0.8
                                    }
                                }}
                            />
                        </div>
                        <div style={{ height: '1px', backgroundColor: '#444e5d', marginBottom: '20px' }} />

                        <div className="mb-4">
                            <Label style={{ fontSize: 16, marginBottom: 10 }}>Select Role</Label>
                            <CustomDropdown
                                options={roleOptions.filter(role => role.value !== "All")}
                                value={updateRoleModal.selectedRole}
                                onChange={(e: any) => setUpdateRoleModal({ ...updateRoleModal, selectedRole: e })}
                                isDisabled={false}
                                placeholder="Select Role"
                            />
                        </div>

                        <div className="d-flex justify-content-end gap-3">
                            <CustomButton
                                placeholder="Cancel"
                                color="#323D4E"
                                style={{
                                    padding: "8px 20px",
                                    fontSize: 15,
                                }}
                                onClick={() => setUpdateRoleModal({ ...updateRoleModal, open: false })}
                            />
                            <CustomButton
                                placeholder="Update"
                                color="#07bc0c"
                                style={{
                                    padding: "8px 20px",
                                    fontSize: 15,
                                }}
                                onClick={handleUpdateRole}
                                disabled={!updateRoleModal.selectedRole}
                            />
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

export default UserManagement; 