import React, { useEffect, useState } from "react";
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BASE_URL,
  UPDATE_PASSWORD,
  // VERIFY_BANK_DETAILS,
} from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import CustomInputBox from "../../Component/CustomInputBox";

const ChangePasswordModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const handleError = useErrorHandler();
  const jwt = GetCookies(CookieItems.JWT);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [changePassword, setChangePassword] = useState({
    status: false,
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  function ValidatePassword(password: string): string {
    if (changePassword.newPassword.length < 8) {
      toast.error("Password must be atleast 8 characters long");
      return "Password must be atleast 8 characters long";
    }
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      toast.error(
        "Password must contain atleast one number, one special character and one alphabet"
      );
      return "Password must contain atleast one number, one special character and one alphabet";
    }
    return "";
  }

  function handleSubmit() {
    if (ValidatePassword(changePassword.newPassword) !== "") {
      return;
    } else {
      setLoading(true);
      axios
        .post(
          BASE_URL + UPDATE_PASSWORD,
          {
            old_password: changePassword.oldPassword,
            new_password: changePassword.newPassword,
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((res) => {
          setLoading(false);

          setChangePassword({
            status: false,
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            console.error("Error while updating password", err);
            handleError(new Error("Error while updating password"));
          }
        });
    }
  }

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal centered isOpen={open} toggle={onClose} backdrop="static">
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        CHANGE PASSWORD
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        <Row className="gap-4">
          <Col md={12} className="">
            <Label>Old Password</Label>
            <div className="position-relative">
              <CustomInputBox
                value={changePassword.oldPassword}
                onchange={(e: any) =>
                  setChangePassword({
                    ...changePassword,
                    oldPassword: e.target.value,
                  })
                }
                placeholder="Enter Old Password"
                className="form-control"
                type={showOldPassword ? "text" : "password"}
              />
              <div
                className="position-absolute"
                style={{ right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                onClick={() => setShowOldPassword(!showOldPassword)}
              >
                {showOldPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          </Col>
          <Col md={12} className="">
            <Label>New Password</Label>
            <div className="position-relative">
              <CustomInputBox
                value={changePassword.newPassword}
                onchange={(e: any) =>
                  setChangePassword({
                    ...changePassword,
                    newPassword: e.target.value,
                  })
                }
                placeholder="Enter New Password"
                className="form-control"
                type={showNewPassword ? "text" : "password"}
              />
              <div
                className="position-absolute"
                style={{ right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          </Col>
          <Col md={12} className="">
            <Label>Confirm New Password</Label>
            <div className="position-relative">
              <CustomInputBox
                value={changePassword.confirmPassword}
                onchange={(e: any) =>
                  setChangePassword({
                    ...changePassword,
                    confirmPassword: e.target.value,
                  })
                }
                placeholder="Confirm New Password"
                className="form-control"
                type={showConfirmPassword ? "text" : "password"}
              />
              <div
                className="position-absolute"
                style={{ right: "10px", top: "50%", transform: "translateY(-50%)", cursor: "pointer" }}
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </div>
            </div>
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="pt-4" style={{ backgroundColor: "#273142" }}>
        <CustomButton
          placeholder="Update"
          color="#00abff"
          disabled={loading}
          type="button"
          onClick={() => {
            handleSubmit();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export default ChangePasswordModal;
