import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  // APPROVE_CREDIT_REQUEST,
  BASE_URL,
  GET_MY_CREDIT_REQUESTS,
} from "../../helpers/api_helper";
import axios from "axios";
import {
  AdminFilterType,
  AdminRequestType,
} from "../../helpers/types/AdminScreenType";
import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import useBreakPoints from "../../hooks/useBreakPoints";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import { UnixUTCtoLocalTime } from "../../helpers/time_converter_helper";
import CustomDropdown from "../../Component/CustomDropdown";

import { Box, Drawer, TablePagination } from "@mui/material";
import FilterIcon from "../../assets/svg/Filter-Icon.svg";
import DownloadIcon from "../../assets/svg/Download-Icon.svg";

import CustomInputBox from "../../Component/CustomInputBox";
import { Col, Label } from "reactstrap";
import CustomButton from "../../Component/CustomButton";
import RetirePopup from "../Wallet/RetirePopup";
import { ModeType, UserContext } from "../../helpers/types/contextType";

const statusOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Request More Information",
    value: "requested more information",
  },
  { label: "Pending", value: "pending" },
  { label: "Reject", value: "rejected" },
  { label: "Approved", value: "approved" },
];

const reqTypeOptions = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Deposit",
    value: "deposit",
  },
  { label: "Retire", value: "retire" },
];

const MyCreditRequest = () => {
  const { isMobile } = useBreakPoints();
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const jwt = GetCookies(CookieItems.JWT);
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  const { userId } = userContext;

  const [adminRequest, setAdminRequests] = useState<AdminRequestType[]>([]);
  // const [totalRequests, setTotalRequests] = useState<Number | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalStatus, setModalStatus] = useState({
    updateStatus: false,
    acceptTokenize: false,
  });
  console.log(adminRequest, "adminRequest");
  // const [requestID, setRequestID] = useState<number | null>(null);
  const [filters, setFilters] = useState<AdminFilterType>({
    page: 0,
    req_type: { label: "all", value: "all" },
    status: { label: "all", value: "all" },
    userName: null,
    asset_name: null,
  });
  const [edit, setEdit] = useState<AdminRequestType | null>(null);
  const [modalState, setModalState] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState({
    open: false,
    id: null,
    data: [],
  });
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  useEffect(() => {
    if (jwt !== null) {
      getMyCreditRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt, modalStatus, filters]);

  const getMyCreditRequests = async () => {
    await axios
      .post(
        BASE_URL + GET_MY_CREDIT_REQUESTS,
        {
          page: filters.page,
          req_type:
            filters.req_type.value === "all" ? null : filters.req_type.value,
          status: filters.status.value === "all" ? null : filters.status.value,
          asset_name: filters.asset_name,
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response) => {
        setAdminRequests(response.data);
        setTotalCount(response?.data?.[0]?.total_count);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        }
        console.error("Error while getting all requests for admin", error);
        handleError(error);
      });
  };

  // function handleApproveRetire(row: any) {
  //   axios
  //     .post(
  //       BASE_URL + APPROVE_CREDIT_REQUEST,
  //       {
  //         request_id: row.id,
  //         amount: row.num_of_credits,
  //         asset_id: row.asset_id,
  //         token_id: row.token_id,
  //       },
  //       {
  //         headers: {
  //           Authorization: jwt,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       setAdminRequests(response.data);
  //     })
  //     .catch((error: any) => {
  //       if (error.response.status === 401) {
  //         toast.error("Session expired, please login again", {
  //           toastId: "sessionExp",
  //         });
  //         localStorage.clear();
  //         navigate("/logout");
  //       } else {
  //         console.error("Error while approving retire request", error);
  //         toast.error("Error while approving retire request");
  //       }
  //     });
  // }

  const handleDownloadCsv = async () => {
    try {
      await axios
        .post(
          BASE_URL + GET_MY_CREDIT_REQUESTS,
          {
            req_type:
              filters.req_type.value === "all" ? null : filters.req_type.value,
            status:
              filters.status.value === "all" ? null : filters.status.value,
            asset_name: filters.asset_name,
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((response) => {
          const csvData = response.data;
          if (
            "myCreditRequests.csv" &&
            csvData !== null &&
            csvData !== undefined
          ) {
            const csv = new Blob(
              [
                `${Object.keys(csvData[0]).join(",")}\n${csvData
                  .map((e: any) => Object.values(e).join(","))
                  .join("\n")}`,
              ],
              {
                type: "text/csv;charset=utf-8;",
              }
            );
            const url = window.URL.createObjectURL(csv);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "myCreditRequests.csv");
            document.body.appendChild(link);
            link.click();
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          }
          console.error("Error while getting all requests for admin", error);
          handleError(error);
        });
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Error while get my orders csv", error);
        toast.error("Error while get my orders csv");
        return null;
      }
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setFilters({ ...filters, page: newPage });
  };
  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  const handleChangeOpen = (data: any) => {
    if (openSubMenu.open && openSubMenu.id === data.id) {
      setOpenSubMenu({
        open: false,
        id: null,
        data: [],
      });
    } else {
      setOpenSubMenu({
        open: true,
        id: data.id,
        data: data,
      });
    }
  };

  return (
    <div className="mt-3 p-4 pt-0">
      <div className=" d-flex justify-content-between align-items-center w-100">
        <span
          style={{
            textTransform: "capitalize",
            fontSize: isMobile ? 20 : 32,
            fontWeight: 700,
            lineHeight: "43.65px",
            letterSpacing: "-0.11px",
          }}
        >
          My Credit Requests{" "}
        </span>

        <div className="">
          <div
            style={{ paddingRight: 30 }}
            className="d-flex justify-content-end align-items-center w-100"
          >
            <div>
              {!isMobile && adminRequest?.length > 0 && (
                <TablePagination
                  component="div"
                  count={totalCount || 25}
                  page={filters.page}
                  onPageChange={handleChangePage}
                  rowsPerPage={25}
                  rowsPerPageOptions={[]}
                  sx={{
                    color: "white",
                    "& .css-pdct74-MuiTablePagination-selectLabel ": {
                      color: "white",
                      marginBottom: 0,
                    },
                    "& .css-levciy-MuiTablePagination-displayedRows ": {
                      color: "white",
                      marginBottom: 0,
                    },
                    "& .Mui-disabled": {
                      color: "grey",
                      opacity: 0.5,
                    },
                    "& .MuiTablePagination-actions": {
                      "& .MuiIconButton-root.Mui-disabled": {
                        color: "grey",
                      },
                      "& .MuiIconButton-root": {
                        color: adminRequest?.length > 0 ? "white" : "grey",
                        pointerEvents: adminRequest?.length > 0 ? "auto" : "none",
                      },
                    },
                  }}
                />
              )}
            </div>
            <div
              style={{
                borderLeft: isMobile ? "" : "1px solid white",
                paddingLeft: 20,
                marginLeft: 10,
              }}
              className="d-flex justify-content-center align-items-center gap-3"
            >
              <img
                onClick={toggleDrawer(true)}
                style={{ cursor: "pointer" }}
                src={FilterIcon}
                alt="Filter Icon"
              />
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDownloadCsv();
                }}
                src={DownloadIcon}
                alt="Download Icon"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="admin-table table-responsive">
        <table className="accordion-table">
          <thead>
            <tr>
              <th
                style={{
                  textWrap: "wrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "1px solid #444e5d",
                  borderRight: "none",
                  borderRadius: "12px 0px 0px 12px",
                }}
                className="expand"
              ></th>
              <th
                style={{
                  textWrap: "wrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                ID
              </th>
              <th
                style={{
                  textWrap: "wrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Request Type
              </th>
              <th
                style={{
                  textWrap: "wrap",
                  minWidth: isMobile ? "200px" : "",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Asset Name{" "}
              </th>
              <th
                style={{
                  textWrap: "wrap",
                  minWidth: "300px",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Status
              </th>
              <th
                style={{
                  textWrap: "wrap",
                  minWidth: isMobile ? "200px" : "",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Date
              </th>
              <th
                style={{
                  textWrap: "wrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "1px solid #444e5d",
                  borderRadius: "0px 12px 12px 0px",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {adminRequest?.map((item: any, index: number) => (
              <>
                <tr key={index} className="header-level">
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{ cursor: "pointer", background: "#1b2431" }}
                    className="expand"
                  >
                    <i
                      className={`${
                        openSubMenu.id === item.id
                          ? "fa fa-chevron-down"
                          : "fa fa-chevron-right"
                      }`}
                    ></i>
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    {item?.id}
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    <span
                      style={{
                        textTransform: "capitalize",
                        width: 100,
                        textAlign: "center",
                        display: "inline-block",
                        background:
                          item?.req_type === "deposit"
                            ? "#1ccab8"
                            : "rgb(237 78 28)",
                        color: "white",
                        padding: "5px 15px",
                        borderRadius: "26px",
                        fontSize: 15,
                        lineHeight: "16.37px",
                      }}
                    >
                      {item?.req_type || ""}
                    </span>
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 10px",
                    }}
                  >
                    {item?.asset_name}
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    <span
                      style={{
                        padding: "5px 15px",
                        background:
                          item?.status === "approved"
                            ? "#1ccab8"
                            : item?.status === "pending"
                            ? "rgb(77 202 28)"
                            : item?.status === "requested more information"
                            ? "rgb(202 94 28)"
                            : item?.status === "rejected"
                            ? "#cb1b2c"
                            : "#1ccab8",
                        color: "white",
                        borderRadius: "26px",
                        textTransform: "capitalize",
                        fontSize: 15,
                        lineHeight: "16.37px",
                        width: 150,
                        textAlign: "center",
                        display: "inline-block",
                      }}
                    >
                      {item?.status === "requested more information"
                        ? "Req-More-Info"
                        : item?.status}
                    </span>
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    {UnixUTCtoLocalTime(item?.timestamp)}
                  </td>
                  <td>
                    {" "}
                    <button
                      style={{
                        padding: "5px 15px",
                        width: "75.88px",
                        textAlign: "center",
                        display: "inline-block",
                        background: item?.status === "approved" || item?.status === "rejected"
                          ? "#444e5d" // Greyed out background for disabled state
                          : "#00abff",
                        color: item?.status === "approved" || item?.status === "rejected"
                          ? "#999999" // Greyed out text for disabled state
                          : "white",
                        borderRadius: "2px",
                        border: "none",
                        fontSize: 15,
                        lineHeight: "16.37px",
                        cursor: item?.status === "approved" || item?.status === "rejected"
                          ? "not-allowed"
                          : "pointer",
                        opacity: item?.status === "approved" || item?.status === "rejected"
                          ? 0.7
                          : 1,
                      }}
                      disabled={item?.status === "approved" || item?.status === "rejected"}
                      type="button"
                      onClick={() => {
                        if (item?.req_type === "deposit") {
                          navigate("/tokenize-credit", {
                            state: { edit: item },
                          });
                        } else if (item?.req_type === "retire") {
                          setEdit(item);
                          setModalState(true);
                        }
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
                <tr className="sub-level">
                  <td style={{ background: "#1b2431" }} className="expand"></td>
                  <td colSpan={6}>
                    <div
                      className={`${
                        openSubMenu.id === item.id ? "open" : "close"
                      } w-100 mb-3`}
                      style={{
                        backgroundColor: "#17202d",
                        outline: "1px solid #313d4f",
                        borderRadius: 12,
                      }}
                    >
                      {item?.req_type === "deposit" && (
                        <>
                          <div className="stepper-horizontal" id="stepper1">
                            <div className={`step done`}>
                              <div className="step-circle">
                                <span>1</span>
                              </div>
                              <div className="step-title d-flex justify-content-center">
                                <span
                                  style={{
                                    textWrap: "wrap",
                                    width: "130px",
                                    color: "white",
                                    marginTop: "8px",
                                  }}
                                >
                                  Credit Request Form Completed
                                </span>
                              </div>
                              <div className="step-bar-left"></div>
                              <div className="step-bar-right"></div>
                            </div>
                            <div
                              className={`step ${
                                item?.status === "pending" ||
                                item?.status === "requested more information" ||
                                item?.status === "approved" ||
                                item?.status === "rejected"
                                  ? "done"
                                  : ""
                              }`}
                            >
                              <div className="step-circle">
                                <span>2</span>
                              </div>
                              <div className="step-title d-flex justify-content-center">
                                <span
                                  style={{
                                    textWrap: "wrap",
                                    width: "130px",
                                    color: "white",
                                    marginTop: "8px",
                                  }}
                                >
                                  Review In Progress
                                </span>
                              </div>
                              <div className="step-bar-left"></div>
                              <div className="step-bar-right"></div>
                            </div>
                            <div
                              className={`step ${
                                item?.status === "requested more information" ||
                                item?.status === "approved" ||
                                item?.status === "rejected"
                                  ? "done"
                                  : ""
                              }`}
                            >
                              <div className="step-circle">
                                <span>3</span>
                              </div>
                              <div className="step-title d-flex justify-content-center">
                                <span
                                  style={{
                                    textWrap: "wrap",
                                    width: "130px",
                                    color: "white",
                                    marginTop: "8px",
                                  }}
                                >
                                  Requested More Information
                                </span>
                              </div>
                              <div className="step-bar-left"></div>
                              <div className="step-bar-right"></div>
                            </div>
                            <div
                              className={`step ${
                                item?.status === "approved"
                                  ? "done"
                                  : item?.status === "rejected"
                                  ? "done rejected"
                                  : ""
                              }`}
                            >
                              <div className="step-circle">
                                <span>4</span>
                              </div>
                              <div className="step-title d-flex justify-content-center">
                                <span
                                  style={{
                                    textWrap: "wrap",
                                    width: "80px",
                                    color: "white",
                                    marginTop: "8px",
                                  }}
                                >
                                  Final Review
                                </span>
                              </div>
                              <div className="step-bar-left"></div>
                              <div className="step-bar-right"></div>
                            </div>
                          </div>

                          <div>
                            <div
                              style={{
                                backgroundColor: "#17202d",
                                borderRadius: "10px",
                                margin: "10px 5px",
                                padding: "10px 20px",
                                textAlign: "left",
                              }}
                            >
                              {" "}
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                Credit Request Status{" "}
                              </span>
                              <div
                                className="d-flex justify-content-start align-items-center mt-2"
                                style={{ gap: 10 }}
                              >
                                <div style={{ width: "100%", fontSize: 15 }}>
                                  Credit request accepted successfully, transfer
                                  of assets acknowledged
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#17202d",
                                borderRadius: "10px",
                                margin: "10px 5px",
                                padding: "10px 20px",
                                textAlign: "left",
                              }}
                            >
                              {" "}
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                General Information
                              </span>
                              <div
                                className="d-flex justify-content-start align-items-center mt-2"
                                style={{ gap: 10, fontSize: 15 }}
                              >
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Registry Name:
                                  </span>{" "}
                                  &nbsp; {item?.registry_name || "-"}
                                </div>
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    If Registry Account :
                                  </span>{" "}
                                  &nbsp;{" "}
                                  {item?.if_registry_account
                                    ? "Yes"
                                    : "No" || "-"}
                                </div>
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Registry URL :
                                  </span>{" "}
                                  &nbsp; {item?.registry_project_url || "-"}
                                </div>
                              </div>
                              <div
                                className="d-flex justify-content-start align-items-center mt-2"
                                style={{ gap: 10, fontSize: 15 }}
                              >
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Number of Credits :
                                  </span>{" "}
                                  &nbsp; {item?.num_of_credits || "-"}
                                </div>
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Registry Account ID :
                                  </span>{" "}
                                  &nbsp; {item?.registry_account_id || "-"}
                                </div>
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Methodology ID:
                                  </span>{" "}
                                  &nbsp; {item?.methodology_id || "-"}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#17202d",
                                borderRadius: "10px",
                                margin: "10px 5px",
                                padding: "10px 20px",
                                textAlign: "left",
                              }}
                            >
                              {" "}
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                Project Information
                              </span>
                              <div
                                className="d-flex justify-content-start align-items-center mt-2"
                                style={{ gap: 10, fontSize: 15 }}
                              >
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Credit Program Name :
                                  </span>{" "}
                                  &nbsp; {item?.credit_programme || "-"}
                                </div>
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Project Type :
                                  </span>{" "}
                                  &nbsp; {item?.project_type || "-"}
                                </div>
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Project State :
                                  </span>{" "}
                                  &nbsp; {item?.project_state || "-"}
                                </div>
                              </div>
                              <div
                                className="d-flex justify-content-start align-items-center mt-2"
                                style={{ gap: 10, fontSize: 15 }}
                              >
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Project Country :
                                  </span>{" "}
                                  &nbsp; {item?.project_country || "-"}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#17202d",
                                borderRadius: "10px",
                                margin: "10px 5px",
                                padding: "10px 20px",
                                textAlign: "left",
                              }}
                            >
                              {" "}
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                Mint Information{" "}
                              </span>
                              <div
                                className="d-flex justify-content-start align-items-center mt-2"
                                style={{ gap: 10, fontSize: 15 }}
                              >
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    One Time Claimable:
                                  </span>{" "}
                                  &nbsp; {item?.if_one_time_claim || "-"}
                                </div>
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Mint Duration :
                                  </span>{" "}
                                  &nbsp; {item?.mint_duration || "-"}
                                </div>
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Mint Year :
                                  </span>{" "}
                                  &nbsp; {item?.mint_year || "-"}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#17202d",
                                borderRadius: "10px",
                                margin: "10px 5px",
                                padding: "10px 20px",
                                textAlign: "left",
                              }}
                            >
                              {" "}
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                Project Proponent Information{" "}
                              </span>
                              <div
                                className="d-flex justify-content-start align-items-center mt-2"
                                style={{ gap: 10, fontSize: 15 }}
                              >
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    Project Proponent Name :
                                  </span>{" "}
                                  &nbsp; {item?.pp_name || "-"}
                                </div>
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Project Proponent Address:
                                  </span>{" "}
                                  &nbsp; {item?.project_country || "-"}
                                </div>
                                <div style={{ width: "33%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Project Proponent Phone :
                                  </span>{" "}
                                  &nbsp; {item?.pp_contact_details || "-"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {item?.req_type === "retire" && (
                        <>
                          <div>
                            <div
                              style={{
                                backgroundColor: "#17202d",
                                borderRadius: "10px",
                                margin: "10px 5px",
                                padding: "10px 20px",
                                textAlign: "left",
                              }}
                            >
                              {" "}
                              <span style={{ fontSize: 20, fontWeight: 600 }}>
                                General Information
                              </span>
                              <div
                                className="d-flex justify-content-start align-items-center mt-2"
                                style={{ gap: 10, fontSize: 15 }}
                              >
                                <div style={{ width: "25%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    If Registry Account :
                                  </span>{" "}
                                  &nbsp;{" "}
                                  {item?.if_registry_account
                                    ? "Yes"
                                    : "No" || "-"}
                                </div>
                                <div style={{ width: "25%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Registry Name :
                                  </span>{" "}
                                  &nbsp; {item?.registry_name || "-"}
                                </div>
                                <div style={{ width: "25%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Registry Account ID :{" "}
                                  </span>{" "}
                                  &nbsp; {item?.registry_account_id || "-"}
                                </div>
                                <div style={{ width: "25%" }}>
                                  <span style={{ color: "#999999" }}>
                                    {" "}
                                    Number of Credits :
                                  </span>{" "}
                                  &nbsp; {item?.num_of_credits || "-"}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        {isMobile && adminRequest?.length > 0 && (
          <TablePagination
            component="div"
            count={totalCount || 25}
            page={filters.page}
            onPageChange={handleChangePage}
            rowsPerPage={25}
            rowsPerPageOptions={[]}
            sx={{
              color: "white",
              "& .css-pdct74-MuiTablePagination-selectLabel ": {
                color: "white",
                marginBottom: 0,
              },
              "& .css-levciy-MuiTablePagination-displayedRows ": {
                color: "white",
                marginBottom: 0,
              },
              "& .Mui-disabled": {
                color: "grey",
                opacity: 0.5,
              },
              "& .MuiTablePagination-actions": {
                "& .MuiIconButton-root.Mui-disabled": {
                  color: "grey",
                },
                "& .MuiIconButton-root": {
                  color: adminRequest?.length > 0 ? "white" : "grey",
                  pointerEvents: adminRequest?.length > 0 ? "auto" : "none",
                },
              },
            }}
          />
        )}
      </div>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ backgroundColor: "#273142", color: "white", height: "100%" }}
          role="presentation"
        >
          <div className="p-4 flex-column w-100">
            <Col md={12}>
              <Label>Search by Request Type</Label>
              <CustomDropdown
                options={reqTypeOptions}
                value={filters.req_type}
                onChange={(e: any) => {
                  setFilters({ ...filters, req_type: e });
                }}
                isDisabled={false}
                placeholder="Search by Request Type"
              />
            </Col>
            <Col md={12} className="mt-4">
              <Label>Search by Status</Label>
              <CustomDropdown
                options={statusOptions}
                value={filters.status}
                onChange={(e: any) => {
                  setFilters({ ...filters, status: e });
                }}
                isDisabled={false}
                placeholder="Search by Status"
              />
            </Col>

            <Col md={12} className="mt-4">
              <Label>Search by Asset Name</Label>
              <CustomInputBox
                type="text"
                value={filters.asset_name || ""}
                placeholder="Search by Asset Name"
                className="w-100"
                onchange={(e: any) => {
                  setFilters({ ...filters, asset_name: e.target.value });
                }}
              />
            </Col>

            <Col md={12} className="mt-4 text-end d-flex gap-2 justify-content-end">
              <CustomButton
                placeholder="Clear"
                color="#323D4E"
                onClick={() => {
                  setFilters({
                    page: 0,
                    req_type: {
                      label: "All",
                      value: "all",
                    },
                    status: {
                      label: "All",
                      value: "all",
                    },
                    asset_name: null,
                    userName: null,
                  });
                }}
              />
              <CustomButton
                placeholder="Apply Filters"
                color="#3CAB3D"
                onClick={() => {
                  setDrawerOpen(false);
                }}
              />
            </Col>
          </div>
        </Box>
      </Drawer>

      {edit?.id && modalState && (
        <RetirePopup
          userId={userId}
          isOpen={modalState}
          setIsOpen={setModalState}
          onclose={() => {
            setModalState(false);
            getMyCreditRequests();
          }}
          selectedAsset={{
            asset_id: edit.asset_id || 0,
            name: edit.asset_name || "",
            amount: 0,
            is_currency: false,
            mode: ModeType.Credit,
            locked_balance: 0,
          }}
          edit={edit}
        />
      )}
    </div>
  );
};

export default MyCreditRequest;
