import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";

import CustomInputBox from "../../Component/CustomInputBox";
import useBreakPoints from "../../hooks/useBreakPoints";
import { toast } from "react-toastify";
import { UserData } from "../../helpers/types/contextType";
import { TokenIDType, WalletEntry } from "../../helpers/types/WalletType";
import { AdminRequestType } from "../../helpers/types/AdminScreenType";
import { useNavigate } from "react-router-dom";
import { useErrorHandler } from "react-error-boundary";
import axios from "axios";
import {
  BASE_URL,
  GET_TOKEN_ID,
  RETIRE_CREDITS,
  UPDATE_CREDIT_REQUEST,
} from "../../helpers/api_helper";

export interface RetireCreditsModalProps {
  isOpen: boolean;
  setIsOpen: any;
  userId: UserData | null;
  selectedAsset: WalletEntry;
  edit?: AdminRequestType;
  onclose?: any;
}

const RetirePopup = ({
  userId,
  isOpen,
  setIsOpen,
  selectedAsset,
  edit,
  onclose,
}: RetireCreditsModalProps) => {
  const { isMobile, isMediumMobile } = useBreakPoints();
  const [retire, setRetire] = useState({
    tokenID: "",
    assetName: selectedAsset.name,
    accountID: "",
    numberOfCredits: "",
    registryName: "",
  });
  const [registry, setRegistry] = useState(false);
  const [tokenIDs, setTokenIDs] = useState<TokenIDType[]>([]);
  const [selectedTokenID, setSelectedTokenID] = useState<TokenIDType | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  useEffect(() => {
    if (edit) {
      setRetire({
        ...retire,
        numberOfCredits: edit.num_of_credits.toString(),
        assetName: edit.asset_name,
        registryName: edit.registry_name,
        accountID: edit.registry_account_id,
        tokenID: edit.token_id.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .post(
        BASE_URL + GET_TOKEN_ID,
        {
          asset_id: selectedAsset.asset_id,
        },
        {
          headers: {
            Authorization: userId?.jwt,
          },
        }
      )
      .then((response) => {
        setTokenIDs(response.data);
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while getting token IDs", error);
          handleError(new Error("Error while getting token IDs"));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const retireCredits = async () => {
    if (edit === undefined) {
      if (!selectedTokenID?.token_id) {
        toast.error("Please select a token to retire");
        return;
      }
      try {
        setLoading(true);

        await axios
          .post(
            BASE_URL + RETIRE_CREDITS,
            {
              asset_name: retire.assetName,
              asset_id: selectedAsset.asset_id,
              if_registry_account: registry,
              registry_name: registry ? retire.registryName : "",
              registry_account_id: registry ? retire.accountID : "",
              token_id: selectedTokenID?.token_id,
              num_of_credits: retire.numberOfCredits,
              project_type: null,
            },
            {
              headers: {
                Authorization: userId?.jwt,
              },
            }
          )
          .then((res) => {
            setLoading(false);
            toast.success("Retire Credit request Placed Successfully");
            setIsOpen(false);
            onclose();
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status === 401) {
              toast.error("Session expired, please login again", {
                toastId: "sessionExp",
              });
              localStorage.clear();
              navigate("/logout");
            } else if (err.response.status === 400 && err.response.data.error === "Insufficient balance") {
              toast.error("Insufficient balance");
            } else {
              handleError(new Error("Tokenization Failed"));
            }
            setLoading(false);
          });
      } catch (error) {
        setLoading(false);
        console.log(error);
        toast.error("Error while approving retire credit request");
      }
    } else {
      axios
        .post(
          BASE_URL + UPDATE_CREDIT_REQUEST,
          {
            request_id: edit.id,
            asset_name: retire.assetName,
            if_registry_account: registry.toString(),
            registry_name: registry ? retire.registryName : "",
            registry_account_id: registry ? retire.accountID : "",
            token_id: selectedTokenID?.token_id,
            num_of_credits: retire.numberOfCredits,
            project_type: edit.project_type,

            project_id: edit.project_id,
            project_country: edit.project_country,
            project_state: edit.project_state,
            registry_project_url: edit.registry_project_url,
            methodology_id: edit.methodology_id,
            credit_programme: edit.credit_programme,
            additional_accreditations: edit.additional_accreditations,
            mint_year: edit.mint_year,
            if_one_time_claim: edit.if_one_time_claim.toString(),
            mint_duration: edit.mint_duration,
            applicant_is_not_pp: edit.applicant_is_not_pp.toString(),
            pp_name: edit.pp_name,
            pp_address: edit.pp_address,
            pp_contact_details: edit.pp_contact_details,
          },
          {
            headers: {
              Authorization: userId?.jwt,
            },
          }
        )
        .then((res) => {
          toast.success("Credit Retire Request Updated");
          setLoading(false);
          onclose();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error while updating retire request");
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  return (
    <Modal size="xl" centered isOpen={isOpen} toggle={() => setIsOpen(false)}>
      <ModalHeader
        style={{ backgroundColor: "#273142" }}
        toggle={() => setIsOpen(false)}
      >
        {edit ? "UPDATE RETIRE CREDITS" : "RETIRE CREDITS"}{" "}
      </ModalHeader>
      <ModalBody
        style={{ backgroundColor: "#273142" }}
        className="retrie-table"
      >
        <Table
          responsive
          bordered={false}
          className="mb-0"
          style={{
            maxHeight: 400,
            overflow: "auto",
          }}
        >
          <thead style={{}}>
            <tr>
              <th
                style={{
                  width: "25%",
                  minWidth: 200,
                  background: "#323d4e",
                  paddingLeft: 15,
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#afb4bb",
                  borderRadius: "10px 0px 0px 0px",
                  border: "0",
                }}
              >
                <span
                  style={{
                    marginTop: 10,
                    borderRight: "1px solid #afb4bb",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  Token ID
                </span>
              </th>
              <th
                style={{
                  width: "25%",
                  minWidth: 150,
                  background: "#323d4e",
                  paddingLeft: 15,
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#afb4bb",
                  borderBottom: "0",
                }}
              >
                <span
                  style={{
                    marginTop: 10,
                    borderRight: "1px solid #afb4bb",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  Total Balance
                </span>
              </th>
              <th
                style={{
                  width: "25%",
                  minWidth: 150,
                  background: "#323d4e",
                  paddingLeft: 15,
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#afb4bb",
                  borderBottom: "0",
                }}
              >
                <span
                  style={{
                    marginTop: 10,
                    borderRight: "1px solid #afb4bb",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  Locked Balance
                </span>
              </th>
              <th
                style={{
                  width: "25%",
                  minWidth: 200,
                  background: "#323d4e",
                  paddingLeft: 15,
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#afb4bb",
                  borderRadius: "0px 10px 0px 0px",
                  border: "0",
                }}
              >
                <span style={{ marginTop: 10 }}>Select Token To Retire</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {tokenIDs.map((item: any) => (
              <tr>
                <td
                  style={{
                    background: "#323d4e",
                    paddingLeft: 15,
                    color: "white",
                    border: "none",
                  }}
                >
                  {item.token_id}
                </td>
                <td
                  style={{
                    background: "#323d4e",
                    color: "white",
                    border: "none",
                  }}
                >
                  {item.amount}
                </td>
                <td
                  style={{
                    background: "#323d4e",
                    paddingLeft: 15,
                    color: "white",
                    border: "none",
                  }}
                >
                  {item.locked_balance}
                </td>
                <td
                  style={{
                    background: "#323d4e",
                    paddingLeft: 15,
                    color: "white",
                    border: "none",
                  }}
                >
                  <Input
                    type="checkbox"
                    name="tokenID"
                    onClick={() => setSelectedTokenID(item)}
                    checked={selectedTokenID?.token_id === item.token_id}
                    required={true}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <div
          className={`d-flex justify-content-start ${
            isMediumMobile
              ? "flex-column align-items-start gap-2"
              : "align-items-center gap-4"
          } mt-5`}
        >
          <p
            className={`mb-0`}
            style={{
              width: isMobile ? (isMediumMobile ? "100%" : "60%") : "auto",
            }}
          >
            Do you have a registry account in the same registry ?{" "}
          </p>
          <div>
            <button
              type="button"
              onClick={() => {
                setRegistry(true);
              }}
              style={{
                border: registry ? "1px solid #00abff" : "1px solid #444e5d",
                background: registry ? "#00abff" : "#323d4e",
                fontSize: 12,
                padding: "7px 13px",
                color: "white",
                borderRadius: "5px 0px 0px 5px",
              }}
            >
              YES
            </button>
            <button
              type="button"
              onClick={() => {
                setRegistry(false);
              }}
              style={{
                border: !registry ? "1px solid #00abff" : "1px solid #444e5d",
                background: !registry ? "#00abff" : "#323d4e",
                fontSize: 12,
                padding: "7px 13px",
                color: "white",
                borderRadius: "0px 5px 5px 0px",
              }}
            >
              NO
            </button>
          </div>
        </div>

        {/* Registry Form Data  */}

        <Row className={`mt-5 ${isMobile ? "gap-4" : ""}`}>
          {registry && (
            <>
              <Col md={4}>
                <Label>Registry Account ID</Label>
                <CustomInputBox
                  value={retire.accountID}
                  onchange={(e: any) =>
                    setRetire({ ...retire, accountID: e.target.value })
                  }
                  placeholder="Enter Registry Account ID"
                  className="form-control"
                  type="text"
                />
              </Col>

              <Col md={4}>
                <Label>Registry Name</Label>
                <CustomInputBox
                  value={retire.registryName}
                  onchange={(e: any) =>
                    setRetire({ ...retire, registryName: e.target.value })
                  }
                  placeholder="Enter Registry Name"
                  className="form-control"
                  type="text"
                />
              </Col>
            </>
          )}

          <Col md={4}>
            <Label>Amount to Retire</Label>
            <CustomInputBox
              value={retire.numberOfCredits}
              onchange={(e: any) => {
                const value = e.target.value;
                // Only allow positive whole numbers
                if (value === '' || (/^\d+$/.test(value) && parseInt(value) > 0)) {
                  setRetire({ ...retire, numberOfCredits: value });
                }
              }}
              placeholder="Enter Amount to Retire"
              className="form-control"
              type="number"
            />
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="pt-4" style={{ backgroundColor: "#273142" }}>
        <Button color="secondary" onClick={() => setIsOpen(false)}>
          Cancel
        </Button>{" "}
        <Button
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            retireCredits();
          }}
          disabled={loading || tokenIDs.length === 0 || !retire.numberOfCredits || parseInt(retire.numberOfCredits) <= 0}
          color="primary"
        >
          Submit
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default RetirePopup;
