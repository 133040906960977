import React, { useState, useEffect } from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

// import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BASE_URL,
  CREATE_CORPORATE_PROFILE,
  // VERIFY_BANK_DETAILS,
} from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import CustomInputBox from "../../Component/CustomInputBox";
import { CorporateFileType } from "../../helpers/types/Profile";
import useBreakPoints from "../../hooks/useBreakPoints";

interface ValidationErrors {
  compaName: string;
  hqAddressLine1: string;
  hqAddressLine2: string;
  hqAddressLine3: string;
  city: string;
  state: string;
  country: string;
  pinCode: string;
  panNumber: string;
  Pan: string;
  tanNumber: string;
  Tan: string;
  incNumber: string;
  CerificateINC: string;
  gstNumber: string;
  GSTCerificate: string;
  Memorandum: string;
  Articles: string;
  bankName: string;
  ifc: string;
  accountNumber: string;
  upiID: string;
  cancelledCheque: string;
}

const CorporateDetailModel = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  // const handleError = useErrorHandler();
  const jwt = GetCookies(CookieItems.JWT);
  const navigate = useNavigate();
  const { isTablet, isMobile } = useBreakPoints();

  const [loading, setLoading] = useState<boolean>(false);

  const [corporateInfo, setcorporateInfo] = useState({
    compaName: "",
    hqAddressLine1: "",
    hqAddressLine2: "",
    hqAddressLine3: "",
    accountNumber: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    bankName: "",
    ifc: "",
    panNumber: "",
    tanNumber: "",
    upiID: "",
    incNumber: "",
    gstNumber: "",
    bankNumber: "",
  });
  const [fileUpload, setFileUpload] = useState<CorporateFileType>({
    CerificateINC: null,
    GSTCerificate: null,
    Pan: null,
    Tan: null,
    Memorandum: null,
    Articles: null,
    cancelledCheque: null,
  });
  const [step, setStep] = useState<number>(1);
  const [terms, setTerms] = useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationErrors>({
    compaName: "",
    hqAddressLine1: "",
    hqAddressLine2: "",
    hqAddressLine3: "",
    city: "",
    state: "",
    country: "",
    pinCode: "",
    panNumber: "",
    Pan: "",
    tanNumber: "",
    Tan: "",
    incNumber: "",
    CerificateINC: "",
    gstNumber: "",
    GSTCerificate: "",
    Memorandum: "",
    Articles: "",
    bankName: "",
    ifc: "",
    accountNumber: "",
    upiID: "",
    cancelledCheque: "",
  });
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});

  const handleSubmit = () => {
    if (!terms) {
      toast.error("Please agree to the terms and conditions");
    } else {
      if (!validateStep(3)) {
        toast.error("Please fill all required fields correctly");
      } else {
        setLoading(true);
        const formData = new FormData();
        formData.append(
          "certificate_of_incorporation",
          fileUpload?.CerificateINC!
        );
        formData.append("gst_certificate", fileUpload?.GSTCerificate!);
        formData.append("pan", fileUpload?.Pan!);
        formData.append("tan", fileUpload?.Tan!);
        formData.append("memorandum_o_a", fileUpload?.Memorandum!);
        formData.append("articles_o_a", fileUpload?.Articles!);
        formData.append("cancelled_cheque", fileUpload?.cancelledCheque!);
        formData.append("company_name", corporateInfo.compaName);
        formData.append("hq_address_line_1", corporateInfo.hqAddressLine1);
        formData.append("hq_address_line_2", corporateInfo.hqAddressLine2);
        formData.append("hq_address_line_3", corporateInfo.hqAddressLine3);
        formData.append("city", corporateInfo.city);
        formData.append("state", corporateInfo.state);
        formData.append("country", corporateInfo.country);
        formData.append("pin_code", corporateInfo.pinCode);
        formData.append(
          "certificate_of_incorporation_number",
          corporateInfo.incNumber
        );
        formData.append("pan_number", corporateInfo.panNumber);
        formData.append("tan_number", corporateInfo.tanNumber);
        formData.append("gst_reg_id", corporateInfo.gstNumber);
        formData.append("bank_account_number", corporateInfo.accountNumber);
        formData.append("bank_name", corporateInfo.bankName);
        formData.append("upi_id", corporateInfo.upiID);
        formData.append("ifsc_code", corporateInfo.ifc);

        axios
          .post(BASE_URL + CREATE_CORPORATE_PROFILE, formData, {
            headers: {
              Authorization: jwt,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            toast.success("Corporate Profile Added Successfully");
            setLoading(false);
            onClose();
          })
          .catch((err) => {
            setLoading(false);
            if (err.response.status === 401) {
              toast.error("Session expired, please login again", {
                toastId: "sessionExp",
              });
              localStorage.clear();
              navigate("/logout");
            } else {
              toast.error("Failed To Add Corporate Profile, Please try Again");
              console.error("Failed To Add Corporate Profile", err);
            }
          });
      }
    }
  };

  const validateField = (name: string, value: any) => {
    let error = "";

    if (!value || (typeof value === "string" && value.trim() === "")) {
      if (name !== "upiID") {
        error = "This field is required";
      }
    } else {
      switch (name) {
        case "panNumber":
          if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value)) {
            if (/^[a-z]{5}[0-9]{4}[a-z]{1}$/.test(value)) {
              error = "A PAN Number must have capital letters";
            } else {
              error = "Invalid PAN Number format. Correct format: 5 capital letters, 4 digits, 1 capital letter (e.g., ABCDE1234F)";
            }
          }
          break;
        case "tanNumber":
          if (!/^[A-Z]{4}[0-9]{5}[A-Z]{1}$/.test(value)) {
            if (/^[a-z]{4}[0-9]{5}[a-z]{1}$/.test(value)) {
              error = "A TAN Number must have capital letters";
            } else {
              error = "Invalid TAN Number format. Correct format: 4 capital letters, 5 digits, 1 capital letter (e.g., ABCD12345E)";
            }
          }
          break;
        case "gstNumber":
          if (!/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(value)) {
            if (/^[0-9]{2}[a-z]{5}[0-9]{4}[a-z]{1}[1-9a-z]{1}z[0-9a-z]{1}$/.test(value)) {
              error = "A GST Number must have capital letters";
            } else {
              error = "Invalid GST Number format. Correct format: 2 digits, 5 capital letters, 4 digits, 1 capital letter, 1 alphanumeric, Z, 1 alphanumeric (e.g., 12ABCDE3456F7Z8)";
            }
          }
          break;
        case "ifc":
          if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
            if (/^[a-z]{4}0[a-z0-9]{6}$/.test(value)) {
              error = "An IFSC Code must have capital letters";
            } else {
              error = "Invalid IFSC Code format. Correct format: 4 capital letters, 0, 6 alphanumeric characters (e.g., ABCD0123456)";
            }
          }
          break;
        case "accountNumber":
          if (!/^[A-Za-z0-9]{6,40}$/.test(value)) {
            error = "Invalid Account Number format. Must be 6 to 40 characters";
          }
          break;
        case "pinCode":
          if (!/^\d{6}$/.test(value)) {
            error = "Invalid Pincode format. Must be exactly 6 digits";
          }
          break;
        case "upiID":
          if (value && !/^[\w.-]+@[\w.-]+$/.test(value)) {
            error = "Invalid UPI ID format. Must be in the format username@provider (e.g., example@upi)";
          }
          break;
        case "incNumber":
          if (!/^[A-Za-z0-9]{21}$/.test(value)) {
            error = "Company Incorporation Number must be exactly 21 characters long";
          }
          break;
      }
    }
    return error;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string,
    pdf?: boolean
  ) => {
    if (e.target.type === "file") {
      const selectedFile = e.target.files?.[0];
      if (selectedFile) {
        const allowedExtensions = [".jpeg", ".jpg", ".png"];
        const pdfExtensions = [".pdf"];
        const fileExtension = selectedFile.name.slice(
          ((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2
        );

        if (
          (pdf === undefined || pdf === false) &&
          allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)
        ) {
          setFileUpload((prevState: any) => ({
            ...fileUpload,
            [key]: selectedFile,
          }));
          setErrors(prev => ({ ...prev, [key]: "" }));
        } else if (
          pdf === true &&
          pdfExtensions.includes(`.${fileExtension.toLowerCase()}`)
        ) {
          setFileUpload((prevState: any) => ({
            ...fileUpload,
            [key]: selectedFile,
          }));
          setErrors(prev => ({ ...prev, [key]: "" }));
        } else {
          e.target.value = "";
          setErrors(prev => ({
            ...prev,
            [key]: pdf ? "Only PDF files are allowed" : "Only JPEG, JPG, and PNG files are allowed",
          }));
          if (pdf === true) {
            toast.error("Only PDF files are allowed");
          } else {
            toast.error("Only JPEG, JPG, and PNG files are allowed");
          }
        }
      }
    } else {
      const value = e.target.value;
      setcorporateInfo(prev => ({
        ...prev,
        [key]: value,
      }));
      const error = validateField(key, value);
      setErrors(prev => ({ ...prev, [key]: error }));
    }
    setTouched(prev => ({ ...prev, [key]: true }));
  };

  const validateStep = (stepNumber: number) => {
    let stepErrors: { [key: string]: string } = {};
    let hasErrors = false;

    if (stepNumber === 1) {
      // Company Information
      const fields = ["compaName", "hqAddressLine1", "hqAddressLine2", "hqAddressLine3",
        "city", "state", "country", "pinCode"];
      fields.forEach(field => {
        const error = validateField(field, corporateInfo[field as keyof typeof corporateInfo]);
        if (error) {
          stepErrors[field] = error;
          hasErrors = true;
        }
      });

      // PAN and TAN details
      const panError = validateField("panNumber", corporateInfo.panNumber);
      const tanError = validateField("tanNumber", corporateInfo.tanNumber);
      if (panError) {
        stepErrors.panNumber = panError;
        hasErrors = true;
      }
      if (tanError) {
        stepErrors.tanNumber = tanError;
        hasErrors = true;
      }
      if (!fileUpload.Pan) {
        stepErrors.Pan = "PAN Card document is required";
        hasErrors = true;
      }
      if (!fileUpload.Tan) {
        stepErrors.Tan = "TAN Card document is required";
        hasErrors = true;
      }
    } else if (stepNumber === 2) {
      // Incorporation details
      const incError = validateField("incNumber", corporateInfo.incNumber);
      const gstError = validateField("gstNumber", corporateInfo.gstNumber);
      if (incError) {
        stepErrors.incNumber = incError;
        hasErrors = true;
      }
      if (gstError) {
        stepErrors.gstNumber = gstError;
        hasErrors = true;
      }
      if (!fileUpload.CerificateINC) {
        stepErrors.CerificateINC = "Incorporation Certificate is required";
        hasErrors = true;
      }
      if (!fileUpload.GSTCerificate) {
        stepErrors.GSTCerificate = "GST Certificate is required";
        hasErrors = true;
      }
    } else if (stepNumber === 3) {
      // Association and Bank details
      if (!fileUpload.Memorandum) {
        stepErrors.Memorandum = "Memorandum document is required";
        hasErrors = true;
      }
      if (!fileUpload.Articles) {
        stepErrors.Articles = "Articles document is required";
        hasErrors = true;
      }
      if (!fileUpload.cancelledCheque) {
        stepErrors.cancelledCheque = "Void Cheque is required";
        hasErrors = true;
      }

      const bankFields = ["bankName", "ifc", "accountNumber"];
      bankFields.forEach(field => {
        const error = validateField(field, corporateInfo[field as keyof typeof corporateInfo]);
        if (error) {
          stepErrors[field] = error;
          hasErrors = true;
        }
      });

      if (corporateInfo.upiID) {
        const upiError = validateField("upiID", corporateInfo.upiID);
        if (upiError) {
          stepErrors.upiID = upiError;
          hasErrors = true;
        }
      }
    }

    setErrors(prev => ({ ...prev, ...stepErrors }));

    // Set all fields in the current step as touched
    const touchedFields: { [key: string]: boolean } = {};
    Object.keys(stepErrors).forEach(field => {
      touchedFields[field] = true;
    });
    setTouched(prev => ({ ...prev, ...touchedFields }));

    if (hasErrors) {
      const errorMessages = Object.entries(stepErrors)
        .filter(([_, error]) => error)
        .map(([field, error]) => `${field}: ${error}`);
      toast.error(errorMessages.join('\n'));
    }

    return !hasErrors;
  };

  const handleNextStep = () => {
    if (validateStep(step)) {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal centered size="xl" isOpen={open} toggle={onClose} backdrop="static">
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        ADD CORPORATE DETAILS
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        {step === 1 && (
          <Row
            style={{
              backgroundColor: "#1b2431",
              borderRadius: "10px",
              margin: "10px 5px",
              padding: "0px 23px 20px 10px",
            }}
          >
            <Col
              className=""
              style={{ marginTop: 12, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  COMPANY INFORMATION
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={4} md={12}>
                  <Label>Company Name <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.compaName}
                    onchange={(e: any) => handleInputChange(e, "compaName")}
                    placeholder="Enter Company Name"
                    className={`form-control ${touched.compaName && errors.compaName ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                  />
                  {touched.compaName && errors.compaName && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.compaName}
                    </div>
                  )}
                </Col>
                <Col lg={4} md={12}>
                  <Label>Address Line 1 <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.hqAddressLine1}
                    onchange={(e: any) => handleInputChange(e, "hqAddressLine1")}
                    placeholder="Enter Address Line 1"
                    className={`form-control ${touched.hqAddressLine1 && errors.hqAddressLine1 ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                  />
                  {touched.hqAddressLine1 && errors.hqAddressLine1 && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.hqAddressLine1}
                    </div>
                  )}
                </Col>
                <Col
                  lg={4}
                  md={12}
                  className={`${isTablet ? (isMobile ? "" : "mt-3") : ""}`}
                >
                  <Label>Address Line 2 <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.hqAddressLine2}
                    onchange={(e: any) => handleInputChange(e, "hqAddressLine2")}
                    placeholder="Enter Address Line 2"
                    className={`form-control ${touched.hqAddressLine2 && errors.hqAddressLine2 ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                  />
                  {touched.hqAddressLine2 && errors.hqAddressLine2 && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.hqAddressLine2}
                    </div>
                  )}
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>Address Line 3 <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.hqAddressLine3}
                    onchange={(e: any) => handleInputChange(e, "hqAddressLine3")}
                    placeholder="Enter Address Line 3"
                    className={`form-control ${touched.hqAddressLine3 && errors.hqAddressLine3 ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                  />
                  {touched.hqAddressLine3 && errors.hqAddressLine3 && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.hqAddressLine3}
                    </div>
                  )}
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>City <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.city}
                    onchange={(e: any) => handleInputChange(e, "city")}
                    placeholder="Enter City"
                    className={`form-control ${touched.city && errors.city ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                  />
                  {touched.city && errors.city && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.city}
                    </div>
                  )}
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>State <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.state}
                    onchange={(e: any) => handleInputChange(e, "state")}
                    placeholder="Enter State"
                    className={`form-control ${touched.state && errors.state ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                  />
                  {touched.state && errors.state && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.state}
                    </div>
                  )}
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>Country <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.country}
                    onchange={(e: any) => handleInputChange(e, "country")}
                    placeholder="Enter Country"
                    className={`form-control ${touched.country && errors.country ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                  />
                  {touched.country && errors.country && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.country}
                    </div>
                  )}
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>Pincode <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.pinCode}
                    onchange={(e: any) => handleInputChange(e, "pinCode")}
                    placeholder="Enter Pincode"
                    className={`form-control ${touched.pinCode && errors.pinCode ? "is-invalid" : ""}`}
                    type="number"
                    required={true}
                  />
                  {touched.pinCode && errors.pinCode && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.pinCode}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              className=""
              style={{ marginTop: 40, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  PAN DETAILS
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={6} md={12}>
                  <Label>PAN Number <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.panNumber}
                    onchange={(e: any) => handleInputChange(e, "panNumber")}
                    placeholder="Enter PAN Number"
                    className={`form-control ${touched.panNumber && errors.panNumber ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                    minLength={10}
                    maxLength={10}
                  />
                  {touched.panNumber && errors.panNumber && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.panNumber}
                    </div>
                  )}
                </Col>
                <Col lg={6} md={12}>
                  <Label>Upload PAN Card Front Page <span style={{ color: 'red' }}>*</span></Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        cursor: "pointer",
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                        height: 47,
                      }}
                      value={fileUpload.Pan?.name}
                    />
                    {fileUpload.Pan && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(fileUpload.Pan as Blob),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}
                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-3"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-3"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, "Pan", true)}
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              className=""
              style={{ marginTop: 40, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  TAN DETAILS
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={6} md={12}>
                  <Label>TAN Number <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.tanNumber}
                    onchange={(e: any) => handleInputChange(e, "tanNumber")}
                    placeholder="Enter TAN Number"
                    className={`form-control ${touched.tanNumber && errors.tanNumber ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                    minLength={10}
                    maxLength={10}
                  />
                  {touched.tanNumber && errors.tanNumber && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.tanNumber}
                    </div>
                  )}
                </Col>
                <Col lg={6} md={12}>
                  <Label>Upload TAN Card <span style={{ color: 'red' }}>*</span></Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        height: 47,
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                      }}
                      value={fileUpload.Tan?.name}
                    />
                    {fileUpload.Tan && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(fileUpload.Tan as Blob),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}
                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-4"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-4"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, "Tan", true)}
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {step === 2 && (
          <Row
            style={{
              backgroundColor: "#1b2431",
              borderRadius: "10px",
              margin: "10px 5px",
              padding: "0px 23px 20px 10px",
            }}
          >
            <Col
              className=""
              style={{ marginTop: 12, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  INCORPORATION DETAILS{" "}
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={6} md={12}>
                  <Label>Company Incorporation Number <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.incNumber}
                    onchange={(e: any) => {
                      const value = e.target.value.slice(0, 21); // Limit to 21 characters
                      handleInputChange({ ...e, target: { ...e.target, value } }, "incNumber");
                    }}
                    placeholder="Enter Company Incorporation Number (21 characters)"
                    className={`form-control ${touched.incNumber && errors.incNumber ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                    minLength={21}
                    maxLength={21}
                  />
                  {touched.incNumber && errors.incNumber && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.incNumber}
                    </div>
                  )}
                </Col>
                <Col lg={6} md={12}>
                  <Label>Upload Incorporation Certification <span style={{ color: 'red' }}>*</span></Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        height: 47,
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                      }}
                      value={fileUpload.CerificateINC?.name}
                    />
                    {fileUpload.CerificateINC && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(
                              fileUpload.CerificateINC as Blob
                            ),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}
                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-5"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-5"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, "CerificateINC", true)}
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              className=""
              style={{ marginTop: 40, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  GST DETAILS{" "}
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={6} md={12}>
                  <Label>GST Number <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.gstNumber}
                    onchange={(e: any) => handleInputChange(e, "gstNumber")}
                    placeholder="Enter GST Number"
                    className={`form-control ${touched.gstNumber && errors.gstNumber ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                    minLength={15}
                    maxLength={15}
                  />
                  {touched.gstNumber && errors.gstNumber && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.gstNumber}
                    </div>
                  )}
                </Col>
                <Col lg={6} md={12}>
                  <Label>Upload GST Certificate <span style={{ color: 'red' }}>*</span></Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        height: 47,
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                      }}
                      value={fileUpload.GSTCerificate?.name}
                    />
                    {fileUpload.GSTCerificate && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(
                              fileUpload.GSTCerificate as Blob
                            ),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}
                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-6"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-6"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, "GSTCerificate", true)}
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        {step === 3 && (
          <Row
            style={{
              backgroundColor: "#1b2431",
              borderRadius: "10px",
              margin: "10px 5px",
              padding: "0px 23px 20px 10px",
            }}
          >
            <Col
              className=""
              style={{ marginTop: 12, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  ASSOCIATION DETAILS{" "}
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={6} md={12}>
                  <Label>Memorandum of Association <span style={{ color: 'red' }}>*</span></Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                        height: 47,
                      }}
                      value={fileUpload.Memorandum?.name}
                    />
                    {fileUpload.Memorandum && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(fileUpload.Memorandum as Blob),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}
                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-7"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-7"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, "Memorandum", true)}
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <Label>Article of Association <span style={{ color: 'red' }}>*</span></Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                        height: 47,
                      }}
                      value={fileUpload.Articles?.name}
                    />

                    {fileUpload.Articles && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(fileUpload.Articles as Blob),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}

                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-8"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-8"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, "Articles", true)}
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              className=""
              style={{ marginTop: 40, paddingRight: 0 }}
              md={12}
            >
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{}}
              >
                <span style={{ fontSize: 20, fontWeight: 600 }}>
                  BANK DETAILS
                </span>
              </div>

              <Row className={`mt-4 ${isTablet ? "gap-4" : ""}`}>
                <Col lg={4} md={12}>
                  <Label>Bank Name <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.bankName}
                    onchange={(e: any) => handleInputChange(e, "bankName")}
                    placeholder="Enter Bank Name"
                    className={`form-control ${touched.bankName && errors.bankName ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                    minLength={3}
                    maxLength={200}
                  />
                  {touched.bankName && errors.bankName && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.bankName}
                    </div>
                  )}
                </Col>
                <Col lg={4} md={12}>
                  <Label>IFSC Code <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.ifc}
                    onchange={(e: any) => handleInputChange(e, "ifc")}
                    placeholder="Enter IFSC Code"
                    className={`form-control ${touched.ifc && errors.ifc ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                    minLength={11}
                    maxLength={11}
                  />
                  {touched.ifc && errors.ifc && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.ifc}
                    </div>
                  )}
                </Col>
                <Col lg={4} md={12}>
                  <Label>Account Number <span style={{ color: 'red' }}>*</span></Label>
                  <CustomInputBox
                    value={corporateInfo.accountNumber}
                    onchange={(e: any) => handleInputChange(e, "accountNumber")}
                    placeholder="Enter Account Number"
                    className={`form-control ${touched.accountNumber && errors.accountNumber ? "is-invalid" : ""}`}
                    type="text"
                    required={true}
                    minLength={6}
                    maxLength={40}
                  />
                  {touched.accountNumber && errors.accountNumber && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.accountNumber}
                    </div>
                  )}
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>UPI ID</Label>
                  <CustomInputBox
                    value={corporateInfo.upiID}
                    onchange={(e: any) => handleInputChange(e, "upiID")}
                    placeholder="Enter UPI ID (Optional)"
                    className={`form-control ${touched.upiID && errors.upiID ? "is-invalid" : ""}`}
                    type="text"
                    required={false}
                  />
                  {touched.upiID && errors.upiID && (
                    <div className="invalid-feedback" style={{ display: 'block', color: 'red', fontSize: '12px' }}>
                      {errors.upiID}
                    </div>
                  )}
                </Col>
                <Col lg={4} md={12} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>Void Cheque <span style={{ color: 'red' }}>*</span></Label>
                  <div className="file file--upload ">
                    <Input
                      type="text"
                      className="custom-upload-file"
                      disabled
                      style={{
                        background: "#323d4e",
                        border: "#323d4e",
                        color: "white",
                        height: 47,
                      }}
                      value={fileUpload.cancelledCheque?.name}
                    />

                    {fileUpload.cancelledCheque && (
                      <label
                        style={{
                          background: "#3cab3d",
                          borderColor: "#3cab3d",
                          height: 47,
                          borderRadius: 0,
                        }}
                        onClick={() => {
                          window.open(
                            URL.createObjectURL(
                              fileUpload.cancelledCheque as Blob
                            ),
                            "_blank"
                          );
                        }}
                        className="preciew-btn"
                      >
                        <i className="fa fa-file-pdf-o"></i>Preview
                      </label>
                    )}

                    <label
                      style={{
                        height: 47,
                      }}
                      htmlFor="input-file-9"
                    >
                      <i className="fa fa-upload"></i>Upload
                    </label>
                    <input
                      id="input-file-9"
                      type="file"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(e, "cancelledCheque", true)}
                      required
                      accept=".pdf"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </ModalBody>

      {step !== 3 && (
        <ModalFooter className="pt-4" style={{ backgroundColor: "#273142" }}>
          {" "}
          {step === 1 && (
            <CustomButton
              placeholder="Next"
              color="#00abff"
              type="button"
              onClick={handleNextStep}
            />
          )}
          {step === 2 && (
            <>
              <CustomButton
                placeholder="Previous"
                color="#323d4e"
                type="button"
                onClick={handlePreviousStep}
              />
              <CustomButton
                placeholder="Next"
                color="#00abff"
                type="button"
                disabled={loading}
                onClick={handleNextStep}
              />
            </>
          )}
        </ModalFooter>
      )}
      {step === 3 && (
        <div
          className="p-4 pt-2 d-flex justify-content-between gap-2"
          style={{ backgroundColor: "#273142" }}
        >
          <div className="d-flex align-items-center gap-3">
            <input
              type="checkbox"
              id="terms"
              name="terms"
              value="terms"
              placeholder="terms"
              required={true}
              checked={terms}
              onChange={(e: any) => {
                setTerms(e.target.checked);
              }}
            />
            <label className="v-aligh">
              I agree to all terms and conditions.
            </label>
          </div>
          <div className="d-flex justify-content-between gap-2">
            <CustomButton
              placeholder="Previous"
              color="#323d4e"
              type="button"
              onClick={handlePreviousStep}
            />
            <CustomButton
              placeholder="Submit"
              color="#00abff"
              disabled={loading}
              type="button"
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export default CorporateDetailModel;
