import React, { useContext, useEffect, useRef, useState } from "react";
import useBreakPoints from "../../hooks/useBreakPoints";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
// import { useErrorHandler } from "react-error-boundary";
import {
  MyOrderType,
  MyOrdersFilterType,
} from "../../helpers/types/MyordersTypes";
import {
  ACTION_DICONNECT_SUBSCRIPTION,
  ACTION_REFRESSH_MY_ORDERS,
  BASE_URL,
  BLOCK_CHAIN,
  CANCELORDERBYORDERID,
  MY_ORDERS,
  MY_ORDERS_DETAILS,
  SOCKET_SUB_MY_ORDERS,
} from "../../helpers/api_helper";
import axios from "axios";
import {
  ConvertToUnixUtc,
  UnixUTCtoLocalTime,
} from "../../helpers/time_converter_helper";
import CustomButton from "../../Component/CustomButton";
import WebSocketService from "../../services/WebSocketsService";

import { Box, Drawer, TablePagination, createTheme } from "@mui/material";
import FilterIcon from "../../assets/svg/Filter-Icon.svg";
import DownloadIcon from "../../assets/svg/Download-Icon.svg";
import { UserContext } from "../../helpers/types/contextType";
import { Col, Label } from "reactstrap";
import CustomDropdown from "../../Component/CustomDropdown";

// import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ThemeProvider } from "react-bootstrap";
import CustomInputBox from "../../Component/CustomInputBox";
import moment from "moment";

const orderSideOptions = [
  { label: "All", value: "All" },
  { label: "Buy", value: "buy" },
  { label: "Sell", value: "sell" },
];

const orderTypeOptions = [
  { label: "All", value: "All" },
  { label: "Limit", value: "limit" },
  { label: "Market", value: "market" },
];

const statusOptions = [
  { label: "All", value: "All" },
  { label: "Fulfilled", value: "fulfilled" },
  { label: "Unfulfilled", value: "unfulfilled" },
  { label: "Canceled", value: "canceled" },
];

const activeOptions = [
  { label: "All", value: "All" },
  { label: "True", value: "True" },
  { label: "False", value: "False" },
];

const MyOrders = () => {
  const { isMobile, isTablet } = useBreakPoints();
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  const { userId } = userContext;
  const navigate = useNavigate();

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "rgb(114,114,114)",
        light: "white",
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            // General styles for icons
            color: "white",
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
      },
      MuiSvgIcon: {
        // Targeting all SVG icons
        styleOverrides: {
          root: {
            color: "white", // This will change the color of all SVG icons including the date picker icon
          },
          colorSecondary: {
            color: "white",
          },
        },
      },
      MuiIcon: {
        styleOverrides: {
          colorPrimary: {
            color: "white",
          },
          colorSecondary: {
            color: "white",
          },
          colorAction: {
            color: "white",
          },
        },
      },
    },
  });

  const [openSubMenu, setOpenSubMenu] = useState({
    open: false,
    id: null,
    data: [],
  });
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [webSocketService, setWebSocketService] =
    useState<WebSocketService | null>(null);
  const [myOrders, setMyOrders] = useState<MyOrderType[]>([]);
  // const [downloadCSV, setDownloadCSV] = useState<MyOrderType[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filters, setFilters] = useState<MyOrdersFilterType>({
    fromDate: null,
    toDate: null,
    tradePairId: null,
    orderSide: { label: "All", value: "All" },
    orderType: { label: "All", value: "All" },
    price: null,
    amount: null,
    filled: null,
    status: null,
    isActive: { label: "All", value: "All" },
    expiry: null,
    page: 0,
  });
  const webSocketServiceRef = useRef(webSocketService);

  useEffect(() => {
    return () => {
      if (webSocketServiceRef.current !== null) {
        webSocketServiceRef.current.sendMessage({
          data: {
            trade_pair_id: -100,
            subscription: SOCKET_SUB_MY_ORDERS,
          },
          action: ACTION_DICONNECT_SUBSCRIPTION,
        });
      }
    };
  }, []);

  useEffect(() => {
    webSocketServiceRef.current = webSocketService;
  }, [webSocketService]);

  useEffect(() => {
    if (userId !== null && userId.jwt !== null) {
      setWebSocketService(new WebSocketService(userId?.jwt));
    }
  }, [userId]);

  const cancelOrderByOrderID = async (
    orderID: string,
    tradePairID: number,
    orderSide: string
  ) => {
    try {
      const response = await axios.post(
        BASE_URL + CANCELORDERBYORDERID,
        {
          order_id: orderID,
          trade_pair_id: tradePairID,
          order_side: orderSide,
        },
        {
          headers: {
            Authorization: userId?.jwt,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Error while cancelling order", error);
        toast.error("Error while cancelling order");
      }
    }
  };

  useEffect(() => {
    if (
      webSocketService !== null &&
      webSocketService?.isSocketConnected() !== true
    ) {
      console.log("Connect =========================> 3");

      webSocketService.connect(SOCKET_SUB_MY_ORDERS, -100);

      webSocketService.setOnMessageHandler((newData: any) => {
        if (newData?.expired) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          if (
            newData &&
            newData !== undefined &&
            newData !== null &&
            Array.isArray(newData)
            // newData.every(item => typeof item === 'object' && !Array.isArray(item)) &&
            // newData.authentication_required === undefined &&
            // newData.authenticated === undefined
          ) {
            console.log("my_orders_data", newData);
            setMyOrders(newData);
            setTotalCount(newData?.[0]?.total_count || 25);
          }
        }
      });
    }
    if (webSocketService?.isSocketConnected() === true) {
      webSocketService.sendMessage({
        data: {
          page: filters.page,
          order_side:
            filters.orderSide?.value !== "All"
              ? filters.orderSide?.value.toLowerCase()
              : null,
          is_active:
            filters.isActive?.value !== "All"
              ? filters.isActive?.value === "True"
                ? true
                : false
              : null,
          order_type:
            filters.orderType?.value !== "All"
              ? filters.orderType?.value.toLowerCase()
              : null,
          status:
            filters.status?.value !== "All" && filters.status?.value !== null
              ? filters.status?.value.toLowerCase()
              : null,

          from_date: filters.fromDate
            ? ConvertToUnixUtc(filters.fromDate.format("YYYY-MM-DD"))
            : null,
          to_date: filters.toDate
            ? ConvertToUnixUtc(filters.toDate.format("YYYY-MM-DD"))
            : null,
          expiry: filters.expiry
            ? ConvertToUnixUtc(filters.expiry.format("YYYY-MM-DD"))
            : null,
          price: filters.price === "" ? null : filters.price,
          amount: filters.amount === "" ? null : filters.amount,
          filled: filters.filled === "" ? null : filters.filled,
        },
        action: ACTION_REFRESSH_MY_ORDERS,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webSocketService, webSocketService?.isSocketConnected(), filters]);

  const handleDownloadCsv = async () => {
    try {
      const result = await axios.post(
        BASE_URL + MY_ORDERS,
        {
          order_side:
            filters.orderSide?.value !== "All"
              ? filters.orderSide?.value.toLowerCase()
              : null,
          is_active:
            filters.isActive?.value !== "All"
              ? filters.isActive?.value === "True"
                ? true
                : false
              : null,
          order_type:
            filters.orderType?.value !== "All"
              ? filters.orderType?.value.toLowerCase()
              : null,
          status:
            filters.status?.value !== "All" && filters.status?.value !== null
              ? filters.status?.value.toLowerCase()
              : null,

          from_date: filters.fromDate
            ? ConvertToUnixUtc(filters.fromDate.format("YYYY-MM-DD"))
            : null,
          to_date: filters.toDate
            ? ConvertToUnixUtc(filters.toDate.format("YYYY-MM-DD"))
            : null,
          expiry: filters.expiry
            ? ConvertToUnixUtc(filters.expiry.format("YYYY-MM-DD"))
            : null,
          price: filters.price === "" ? null : filters.price,
          amount: filters.amount === "" ? null : filters.amount,
          filled: filters.filled === "" ? null : filters.filled,
        },
        {
          headers: {
            Authorization: userId?.jwt,
          },
        }
      );

      const csvData = await result.data;
      if ("myOrders.csv" && csvData !== null && csvData !== undefined) {
        const csv = new Blob(
          [
            `${Object.keys(csvData[0]).join(",")}\n${csvData
              .map((e: any) => Object.values(e).join(","))
              .join("\n")}`,
          ],
          {
            type: "text/csv;charset=utf-8;",
          }
        );
        const url = window.URL.createObjectURL(csv);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "myOrders.csv");
        document.body.appendChild(link);
        link.click();
      }
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Error while get my orders csv", error);
        toast.error("Error while get my orders csv");
        return null;
      }
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setFilters({ ...filters, page: newPage });
  };
  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  const handleChangeOpen = (data: any) => {
    if (openSubMenu.open && openSubMenu.id === data.id) {
      setOpenSubMenu({
        open: false,
        id: null,
        data: [],
      });
    } else {
      axios
        .post(
          BASE_URL + MY_ORDERS_DETAILS,
          {
            order_id: data.id,
          },
          {
            headers: {
              Authorization: userId?.jwt,
            },
          }
        )
        .then((res) => {
          setOpenSubMenu({
            open: true,
            data: res.data,
            id: data.id,
          });
        })
        .catch((err) => {
          console.error("Error while get order details", err);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            toast.error("Error while order details for Order ID: " + data.id);
          }
        });
    }
  };

  return (
    <div className="mt-3 p-4 pt-0">
      <div className="d-flex justify-content-between align-items-center w-100">
        <span
          style={{
            textTransform: "capitalize",
            fontSize: isMobile || isTablet ? 20 : 32,
            fontWeight: 700,
            lineHeight: "43.65px",
            letterSpacing: "-0.11px",
          }}
        >
          My Orders{" "}
        </span>
        <div className="">
          <div
            style={{ paddingRight: 30 }}
            className="d-flex justify-content-end align-items-center w-100"
          >
            <div>
              {!isMobile && !isTablet && myOrders?.length > 0 && (
                <TablePagination
                  component="div"
                  count={totalCount || 25}
                  page={filters.page}
                  onPageChange={handleChangePage}
                  rowsPerPage={25}
                  rowsPerPageOptions={[]}
                  sx={{
                    color: "white",
                    "& .css-pdct74-MuiTablePagination-selectLabel ": {
                      color: "white",
                      marginBottom: 0,
                    },
                    "& .css-levciy-MuiTablePagination-displayedRows ": {
                      color: "white",
                      marginBottom: 0,
                    },
                    "& .Mui-disabled": {
                      color: "grey",
                      opacity: 0.5,
                    },
                    "& .MuiTablePagination-actions": {
                      "& .MuiIconButton-root.Mui-disabled": {
                        color: "grey",
                      },
                      "& .MuiIconButton-root": {
                        color: myOrders?.length > 0 ? "white" : "grey",
                        pointerEvents: myOrders?.length > 0 ? "auto" : "none",
                      },
                    },
                  }}
                />
              )}
            </div>
            <div
              style={{
                borderLeft: isMobile || isTablet ? "" : "1px solid white",
                paddingLeft: 20,
                marginLeft: 10,
              }}
              className="d-flex justify-content-center align-items-center gap-3"
            >
              <img
                onClick={toggleDrawer(true)}
                style={{ cursor: "pointer" }}
                src={FilterIcon}
                alt="Filter Icon"
              />
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleDownloadCsv();
                }}
                src={DownloadIcon}
                alt="Download Icon"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Desktop View */}
      {!isMobile && !isTablet && (
        <div className="admin-table table-responsive">
        <table className="accordion-table">
          <thead>
            <tr>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "1px solid #444e5d",
                  borderRight: "none",
                  borderRadius: "12px 0 0 12px",
                }}
                className="expand"
              ></th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                ID
              </th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Placement Time
              </th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Trade Pair{" "}
              </th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Order Side
              </th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Order Type
              </th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Price
              </th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Quantity
              </th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Filled
              </th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Status
              </th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Expiry
              </th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "none",
                }}
              >
                Is Active
              </th>
              <th
                style={{
                  textWrap: "nowrap",
                  borderTop: "1px solid #444e5d",
                  borderBottom: "1px solid #444e5d",
                  borderLeft: "none",
                  borderRight: "1px solid #444e5d",
                  borderRadius: "0 12px 12px 0px",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {myOrders?.map((item: any, index: number) => (
              <>
                <tr key={index} className="header-level">
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{ cursor: "pointer", background: "#1b2431" }}
                    className="expand"
                  >
                    <i
                      style={{ color: "white", fontSize: 12, fontWeight: 400 }}
                      className={`${openSubMenu.id === item.id
                          ? "fa fa-chevron-down"
                          : "fa fa-chevron-right"
                      }`}
                    ></i>
                  </td>{" "}
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    {item?.id}
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    {UnixUTCtoLocalTime(item?.order_placement_time)}
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 10px",
                    }}
                  >
                    {item?.name}
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    <span
                      style={{
                        // padding: "2px 15px",
                        background:
                          item?.order_side === "sell"
                            ? "#e94953"
                            : item?.order_side === "buy"
                            ? "#1ccab8"
                            : "",
                        color: "white",
                        borderRadius: "26px",
                        textWrap: "wrap",
                        textTransform: "capitalize",
                        padding: "5px 20px",

                        fontSize: 15,
                        lineHeight: "16.37px",
                      }}
                    >
                      {item?.order_side || ""}
                    </span>
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    {item?.order_type}
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    {item?.price}
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    {item?.amount}
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    {item?.filled}
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    <span
                      style={{
                        padding: "5px 15px",
                        background:
                          item?.status === "fulfilled"
                            ? "#fd9a56"
                            : item?.status === "canceled"
                            ? "#e94953"
                            : item?.status === "partially-fulfilled"
                            ? "#00abff"
                            : "#1ccab8",
                        color: "white",
                        borderRadius: "26px",
                        fontSize: 15,
                        lineHeight: "16.37px",
                        textTransform: "capitalize",
                      }}
                    >
                      {item?.status.charAt(0).toUpperCase() +
                        item.status.slice(1) || ""}
                    </span>
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    {UnixUTCtoLocalTime(item?.expiry)}
                  </td>
                  <td
                    onClick={() => handleChangeOpen(item)}
                    style={{
                      cursor: "pointer",
                      textWrap: "wrap",
                      padding: "3px 20px",
                    }}
                  >
                    {item?.is_active ? "Yes" : "No"}
                  </td>
                  <td>
                    <CustomButton
                      placeholder={"Cancel"}
                      color={"#ff4c54"}
                      disabled={
                        item.status === "canceled" ||
                        item.status === "fulfilled"
                      }
                      style={{
                        padding: "8px 30px",
                        fontSize: 15,
                        lineHeight: "16.37px",
                      }}
                      type="submit"
                      onClick={() =>
                        cancelOrderByOrderID(
                          item?.id,
                          item?.trade_pair_id,
                          item?.order_side
                        )
                      }
                    />{" "}
                  </td>
                </tr>
                <tr className="sub-level">
                  <td style={{ background: "#1b2431" }} className="expand"></td>

                  <td colSpan={12}>
                    <table
                      style={{ width: "100%" }}
                      className={openSubMenu.id === item.id ? "open" : "close"}
                    >
                      <thead>
                        <th
                          style={{
                            background: "#1e2939",
                            borderTop: "1px solid #444e5d",
                            borderBottom: "1px solid #444e5d",
                            borderLeft: "1px solid #444e5d",
                            borderRight: "none",
                          }}
                        >
                          Buy ID
                        </th>
                        <th
                          style={{
                            background: "#1e2939",
                            borderTop: "1px solid #444e5d",
                            borderBottom: "1px solid #444e5d",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                        >
                          Sell ID
                        </th>
                        <th
                          style={{
                            background: "#1e2939",
                            borderTop: "1px solid #444e5d",
                            borderBottom: "1px solid #444e5d",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                        >
                          Price
                        </th>
                        <th
                          style={{
                            background: "#1e2939",
                            borderTop: "1px solid #444e5d",
                            borderBottom: "1px solid #444e5d",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          style={{
                            background: "#1e2939",
                            borderTop: "1px solid #444e5d",
                            borderBottom: "1px solid #444e5d",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                        >
                          Date
                        </th>
                        <th
                          style={{
                            background: "#1e2939",
                            borderTop: "1px solid #444e5d",
                            borderBottom: "1px solid #444e5d",
                            borderLeft: "none",
                            borderRight: "none",
                          }}
                        >
                          Matched Tokenid
                        </th>
                        <th
                          style={{
                            background: "#1e2939",
                            borderTop: "1px solid #444e5d",
                            borderBottom: "1px solid #444e5d",
                            borderLeft: "none",
                            borderRight: "1px solid #444e5d",
                          }}
                        >
                          Txhash
                        </th>
                      </thead>
                      <tbody
                        style={{
                          border: "1px solid #313d4f",
                          borderRadius: 12,
                        }}
                      >
                        {openSubMenu?.data?.map((subItem: any, j: number) => (
                          <tr key={j} style={{ padding: 10 }}>
                            <td style={{ background: "#17202d", padding: 8 }}>
                              {subItem?.buy_order_id}
                            </td>
                            <td style={{ background: "#17202d", padding: 8 }}>
                              {subItem?.sell_order_id}
                            </td>
                            <td style={{ background: "#17202d", padding: 8 }}>
                              {subItem?.price}
                            </td>
                            <td style={{ background: "#17202d", padding: 8 }}>
                              {subItem?.amount}
                            </td>
                            <td style={{ background: "#17202d", padding: 8 }}>
                              {moment
                                .unix(subItem.timestamp)
                                .format("DD/MM/YYYY")}
                            </td>
                            <td style={{ background: "#17202d", padding: 8 }}>
                              {subItem?.token_idqty}
                            </td>
                            <td style={{ background: "#17202d" }}>
                              {" "}
                              {subItem.txhash !== "0x123" ? (
                                <CustomButton
                                  type="button"
                                  onClick={() =>
                                    window.open(
                                      `${BLOCK_CHAIN}${subItem.txhash}`,
                                      "_blank"
                                    )
                                  }
                                  style={{
                                    padding: "5px 15px",
                                    fontSize: 12,
                                    lineHeight: "16.37px",
                                  }}
                                  divclassName="m-0"
                                  placeholder="Tx HASH"
                                  color="#3498DB"
                                />
                              ) : (
                                "pending"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      )}

      {/* Mobile/Tablet View */}
      {(isMobile || isTablet) && (
        <div style={{
          paddingBottom: isMobile ? '120px' : '20px' // Reduced padding for tablet
        }}>
          <div className="mobile-orders-container"
            style={{
              maxWidth: isTablet ? "800px" : "100%",
            }}>
            {myOrders?.map((item: any, index: number) => (
              <div
                key={index}
                className="order-card"
                style={{
                  backgroundColor: "#273142",
                  borderRadius: "12px",
                  padding: isTablet ? "20px" : "16px",
                  marginBottom: "12px",
                  border: "1px solid #313d4f",
                }}
              >
                {/* Header Section */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <span style={{ fontSize: isTablet ? 16 : 14, color: "#999999" }}>Order ID</span>
                    <p className="mb-0" style={{ fontSize: isTablet ? 18 : 16, fontWeight: 600 }}>
                      {item?.id}
                    </p>
                  </div>
                  <span
                    style={{
                      padding: "5px 15px",
                      background:
                        item?.status === "fulfilled"
                          ? "#fd9a56"
                          : item?.status === "canceled"
                            ? "#e94953"
                            : item?.status === "partially-fulfilled"
                              ? "#00abff"
                              : "#1ccab8",
                      color: "white",
                      borderRadius: "26px",
                      fontSize: isTablet ? 16 : 14,
                      lineHeight: "16.37px",
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.status.charAt(0).toUpperCase() + item.status.slice(1)}
                  </span>
                </div>

                {/* Trade Info */}
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <span style={{ fontSize: isTablet ? 16 : 14, color: "#999999" }}>Trade Pair</span>
                    <p className="mb-0" style={{ fontSize: isTablet ? 17 : 15 }}>
                      {item?.name}
                    </p>
                  </div>
                  <span
                    style={{
                      padding: "5px 15px",
                      background:
                        item?.order_side === "sell"
                          ? "#e94953"
                          : item?.order_side === "buy"
                            ? "#1ccab8"
                            : "",
                      color: "white",
                      borderRadius: "26px",
                      fontSize: isTablet ? 16 : 14,
                      textTransform: "capitalize",
                    }}
                  >
                    {item?.order_side}
                  </span>
                </div>

                {/* Price & Quantity */}
                <div className="d-flex justify-content-between mb-3">
                  <div style={{ flex: 1 }}>
                    <span style={{ fontSize: isTablet ? 16 : 14, color: "#999999" }}>Price</span>
                    <p className="mb-0" style={{ fontSize: isTablet ? 17 : 15 }}>
                      {item?.price}
                    </p>
                  </div>
                  <div style={{ flex: 1 }}>
                    <span style={{ fontSize: isTablet ? 16 : 14, color: "#999999" }}>Quantity</span>
                    <p className="mb-0" style={{ fontSize: isTablet ? 17 : 15 }}>
                      {item?.amount}
                    </p>
                  </div>
                  <div style={{ flex: 1 }}>
                    <span style={{ fontSize: isTablet ? 16 : 14, color: "#999999" }}>Filled</span>
                    <p className="mb-0" style={{ fontSize: isTablet ? 17 : 15 }}>
                      {item?.filled}
                    </p>
                  </div>
                </div>

                {/* Time & Expiry */}
                <div className="d-flex justify-content-between mb-3">
                  <div>
                    <span style={{ fontSize: isTablet ? 16 : 14, color: "#999999" }}>Placement Time</span>
                    <p className="mb-0" style={{ fontSize: isTablet ? 16 : 14 }}>
                      {UnixUTCtoLocalTime(item?.order_placement_time)}
                    </p>
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <span style={{ fontSize: isTablet ? 16 : 14, color: "#999999" }}>Expiry</span>
                    <p className="mb-0" style={{ fontSize: isTablet ? 16 : 14 }}>
                      {UnixUTCtoLocalTime(item?.expiry)}
                    </p>
                  </div>
                </div>

                {/* Actions */}
                <div className="d-flex justify-content-between align-items-center">
                  {parseFloat(item?.filled) > 0 && (
                    <CustomButton
                      placeholder={"View Details"}
                      color={"#00abff"}
                      style={{
                        padding: isTablet ? "10px 20px" : "8px 16px",
                        fontSize: isTablet ? 16 : 14,
                      }}
                      type="button"
                      onClick={() => handleChangeOpen(item)}
                    />
                  )}
                  <CustomButton
                    placeholder={"Cancel"}
                    color={"#ff4c54"}
                    disabled={item.status === "canceled" || item.status === "fulfilled"}
                    style={{
                      padding: isTablet ? "10px 20px" : "8px 16px",
                      fontSize: isTablet ? 16 : 14,
                    }}
                    type="button"
                    onClick={() =>
                      cancelOrderByOrderID(
                        item?.id,
                        item?.trade_pair_id,
                        item?.order_side
                      )
                    }
                  />
                </div>

                {/* Expanded Details */}
                {openSubMenu.id === item.id && (
                  <div
                    className="order-details mt-3 pt-3"
                    style={{
                      borderTop: "1px solid #444e5d",
                    }}
                  >
                    <p style={{ fontSize: isTablet ? 18 : 16, fontWeight: 600, marginBottom: 12 }}>
                      Transaction Details
                    </p>
                    {openSubMenu?.data?.map((subItem: any, j: number) => (
                      <div
                        key={j}
                        className="transaction-card mb-2"
                        style={{
                          backgroundColor: "#17202d",
                          borderRadius: "8px",
                          padding: isTablet ? "16px" : "12px",
                        }}
                      >
                        <div className="d-flex justify-content-between mb-2">
                          <div>
                            <span style={{ fontSize: isTablet ? 15 : 13, color: "#999999" }}>Buy ID</span>
                            <p className="mb-0" style={{ fontSize: isTablet ? 16 : 14 }}>
                              {subItem?.buy_order_id}
                            </p>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <span style={{ fontSize: isTablet ? 15 : 13, color: "#999999" }}>Sell ID</span>
                            <p className="mb-0" style={{ fontSize: isTablet ? 16 : 14 }}>
                              {subItem?.sell_order_id}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <div>
                            <span style={{ fontSize: isTablet ? 15 : 13, color: "#999999" }}>Price</span>
                            <p className="mb-0" style={{ fontSize: isTablet ? 16 : 14 }}>
                              {subItem?.price}
                            </p>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <span style={{ fontSize: isTablet ? 15 : 13, color: "#999999" }}>Quantity</span>
                            <p className="mb-0" style={{ fontSize: isTablet ? 16 : 14 }}>
                              {subItem?.amount}
                            </p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div>
                            <span style={{ fontSize: isTablet ? 15 : 13, color: "#999999" }}>Date</span>
                            <p className="mb-0" style={{ fontSize: isTablet ? 16 : 14 }}>
                              {moment.unix(subItem.timestamp).format("DD/MM/YYYY")}
                            </p>
                          </div>
                          <div style={{ textAlign: "right" }}>
                            {subItem.txhash !== "0x123" ? (
                              <CustomButton
                                type="button"
                                onClick={() =>
                                  window.open(`${BLOCK_CHAIN}${subItem.txhash}`, "_blank")
                                }
                                style={{
                                  padding: isTablet ? "6px 14px" : "5px 12px",
                                  fontSize: isTablet ? 14 : 12,
                                }}
                                divclassName="m-0"
                                placeholder="View Transaction"
                                color="#3498DB"
                              />
                            ) : (
                              <span style={{ fontSize: isTablet ? 16 : 14, color: "#999999" }}>pending</span>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>

          {/* Mobile/Tablet Pagination */}
          {myOrders?.length > 0 && (
            <div style={{
              position: isMobile ? "fixed" : "static",
              bottom: isMobile ? 56 : "auto",
              left: isMobile ? 0 : "auto",
              right: isMobile ? 0 : "auto",
              backgroundColor: "#1b2431",
              padding: "6px 16px",
              borderTop: "1px solid #444e5d",
              zIndex: isMobile ? 10 : "auto",
              marginTop: isTablet ? "20px" : 0
            }}>
              <TablePagination
                component="div"
                count={totalCount || 25}
                page={filters.page}
                onPageChange={handleChangePage}
                rowsPerPage={25}
                rowsPerPageOptions={[]}
                sx={{
                  color: "white",
                  padding: "0 16px",
                  "& .MuiTablePagination-displayedRows": {
                    color: "white",
                    margin: 0,
                    fontSize: "15px"
                  },
                  "& .MuiTablePagination-actions": {
                    marginLeft: 2,
                    "& .MuiIconButton-root": {
                      color: "white",
                      padding: "6px",
                      width: "36px",
                      height: "36px",
                    },
                    "& .Mui-disabled": {
                      color: "grey",
                    }
                  },
                  "& .MuiToolbar-root": {
                    minHeight: "48px",
                    padding: "0 8px",
                    justifyContent: "flex-end"
                  }
                }}
              />
            </div>
          )}
        </div>
      )}

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ backgroundColor: "#273142", color: "white" }}
          role="presentation"
        >
          <div className="p-4 flex-column w-100">
            <Col md={12}>
              <Label>Search by Order Side</Label>
              <CustomDropdown
                options={orderSideOptions}
                value={filters.orderSide}
                onChange={(e: any) => {
                  setFilters({ ...filters, orderSide: e, page: 0 });
                }}
                isDisabled={false}
                placeholder="Search by Order Side"
              />
            </Col>
            <Col md={12} className="mt-4">
              <Label>Search by Is Active</Label>
              <CustomDropdown
                options={activeOptions}
                value={filters.isActive}
                onChange={(e: any) => {
                  setFilters({ ...filters, isActive: e, page: 0 });
                }}
                isDisabled={false}
                placeholder="Search by Is Active"
              />
            </Col>
            <Col md={12} className="mt-4">
              <Label>Search by Order Type</Label>
              <CustomDropdown
                options={orderTypeOptions}
                value={filters.orderType}
                onChange={(e: any) => {
                  setFilters({ ...filters, orderType: e, page: 0 });
                }}
                isDisabled={false}
                placeholder="Search by Order Type"
              />
            </Col>
            <Col md={12} className="mt-4">
              <Label>Search by Status</Label>
              <CustomDropdown
                options={statusOptions}
                value={filters.status}
                onChange={(e: any) => {
                  setFilters({ ...filters, status: e, page: 0 });
                }}
                isDisabled={false}
                placeholder="Search by Status"
              />
            </Col>
            <Col md={12} className="mt-4">
              <Label>Search by Order Placement From Date: </Label>
              <ThemeProvider theme={darkTheme}>
                {" "}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="From Date"
                      value={filters.fromDate}
                      format="YYYY-MM-DD"
                      onChange={(newValue) => {
                        setFilters({ ...filters, fromDate: newValue, page: 0 });
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          color: "white",
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "white",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    />
                    <DatePicker
                      label="To Date"
                      value={filters.toDate}
                      onChange={(newValue) => {
                        setFilters({ ...filters, toDate: newValue, page: 0 });
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          color: "white",
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "white",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </ThemeProvider>
            </Col>
            <Col md={12} className="mt-4">
              <Label>Search by Expiry : </Label>
              <ThemeProvider theme={darkTheme}>
                {" "}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Expiry"
                      value={filters.expiry}
                      format="YYYY-MM-DD"
                      onChange={(newValue) => {
                        setFilters({ ...filters, expiry: newValue, page: 0 });
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          color: "white",
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                        },
                        "& .MuiInputLabel-root": {
                          color: "white",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "white",
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </ThemeProvider>
            </Col>

            <Col md={12} className="mt-4">
              <Label>Search by Price</Label>
              <CustomInputBox
                type="text"
                value={filters.price || ""}
                placeholder="Search by Price"
                className="w-100"
                onchange={(e: any) => {
                  setFilters({ ...filters, price: e.target.value, page: 0 });
                }}
              />
            </Col>
            <Col md={12} className="mt-4">
              <Label>Search by Amount</Label>
              <CustomInputBox
                type="text"
                value={filters.amount || ""}
                placeholder="Search by Amount"
                className="w-100"
                onchange={(e: any) => {
                  setFilters({ ...filters, amount: e.target.value, page: 0 });
                }}
              />
            </Col>
            <Col md={12} className="mt-4 text-end d-flex gap-2 justify-content-end">
              <CustomButton
                placeholder="Clear"
                color="#323D4E"
                onClick={() => {
                  setFilters({
                    page: 0,
                    orderSide: {
                      label: "All",
                      value: "all",
                    },
                    isActive: {
                      label: "All",
                      value: "all",
                    },
                    orderType: {
                      label: "All",
                      value: "all",
                    },
                    status: {
                      label: "All",
                      value: "all",
                    },
                    fromDate: null,
                    toDate: null,
                    tradePairId: null,
                    price: null,
                    amount: null,
                    expiry: null,
                    filled: null
                  });
                }}
              />
              <CustomButton
                placeholder="Apply Filters"
                color="#3CAB3D"
                onClick={() => {
                  setDrawerOpen(false);
                }}
              />
            </Col>
          </div>
        </Box>
      </Drawer>
    </div>
  );
};

export default MyOrders;
