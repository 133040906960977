import React, { useEffect, useState } from "react";
import { Col, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

import { useErrorHandler } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL, VERIFY_BANK_DETAILS } from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import CustomInputBox from "../../Component/CustomInputBox";
import CustomPhoneInput from "../../Component/CustomPhoneInput";

const BankVerificationModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [bankInfo, setBankInfo] = useState({
    name: "",
    remarks: "",
    bankName: "",
    phone: "",
    accountNumber: "",
    ifc: "",
    upiID: "",
  });

  const [errors, setErrors] = useState({
    bankName: "",
    ifc: "",
    accountNumber: "",
    phone: "",
  });

  const handleError = useErrorHandler();
  const jwt = GetCookies(CookieItems.JWT);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);

  const validateBankName = (value: string) => {
    if (!/^[A-Za-z\s]+$/.test(value)) {
      return "Bank name should only contain letters and spaces";
    }
    if (value.trim().length === 0) {
      return "Bank name is required";
    }
    return "";
  };

  const validateIFSC = (value: string) => {
    if (!/^[A-Z]{4}0[A-Z0-9]{6}$/.test(value)) {
      return "Invalid IFSC code format";
    }
    return "";
  };

  const validateAccountNumber = (value: string) => {
    if (!/^[A-Za-z0-9]+$/.test(value)) {
      return "Account number should only contain letters and numbers";
    }
    if (value.trim().length === 0) {
      return "Account number is required";
    }
    if (value.length < 9 || value.length > 18) {
      return "Account number must be between 9 and 18 characters long";
    }
    return "";
  };

  const handleInputChange = (field: string, value: string) => {
    let sanitizedValue = value;

    switch (field) {
      case "bankName":
        // Only allow letters and spaces
        sanitizedValue = value.replace(/[^A-Za-z\s]/g, '');
        break;
      case "accountNumber":
        // Only allow letters and numbers
        sanitizedValue = value.replace(/[^A-Za-z0-9]/g, '');
        break;
      case "ifc":
        // Only allow letters and numbers, convert to uppercase
        sanitizedValue = value.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
        break;
    }

    setBankInfo({ ...bankInfo, [field]: sanitizedValue });

    // Validate after sanitizing
    switch (field) {
      case "bankName":
        setErrors({ ...errors, bankName: validateBankName(sanitizedValue) });
        break;
      case "ifc":
        setErrors({ ...errors, ifc: validateIFSC(sanitizedValue) });
        break;
      case "accountNumber":
        setErrors({ ...errors, accountNumber: validateAccountNumber(sanitizedValue) });
        break;
    }
  };

  const validateForm = () => {
    const bankNameError = validateBankName(bankInfo.bankName);
    const ifcError = validateIFSC(bankInfo.ifc);
    const accountNumberError = validateAccountNumber(bankInfo.accountNumber);
    const phoneError = !bankInfo.phone ? "Phone Number is required" : "";

    setErrors({
      bankName: bankNameError,
      ifc: ifcError,
      accountNumber: accountNumberError,
      phone: phoneError,
    });

    return !(bankNameError || ifcError || accountNumberError || phoneError);
  };

  const handleSubmit = () => {
    if (!terms) {
      toast.error("Please agree to the terms and conditions");
      return;
    }

    if (!validateForm()) {
      return;
    }

    setLoading(true);
    axios
      .post(
        BASE_URL + VERIFY_BANK_DETAILS,
        {
          name: bankInfo.bankName,
          bankAccount: bankInfo.accountNumber,
          ifsc: bankInfo.ifc,
          phone: bankInfo.phone,
          remarks: "",
        },
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((res) => {
        toast.success("Bank Details Verified Successfully");
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        if (
          err.response !== undefined &&
          err.response !== null &&
          err.response.data.verification_status === "failed"
        ) {
          toast.error("Bank Details Verification Failed, Please try Again");
        } else if (err.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while verifying Bank Details", err);
          handleError(new Error("Error while verifying Bank Details"));
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal centered isOpen={open} toggle={onClose} backdrop="static">
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        ADD YOUR BANK DETAILS
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        <Row>
          <Col md={6} className="">
            <Label>Bank Name<span className="text-danger">*</span></Label>
            <CustomInputBox
              value={bankInfo.bankName}
              onchange={(e: any) => handleInputChange("bankName", e.target.value)}
              placeholder="Enter Bank Name"
              className={`form-control ${errors.bankName ? 'is-invalid' : ''}`}
              type="text"
              required={true}
            />
            {errors.bankName && <div className="text-danger small mt-1">{errors.bankName}</div>}
          </Col>
          <Col md={6} className="">
            <Label>IFSC Code<span className="text-danger">*</span></Label>
            <CustomInputBox
              value={bankInfo.ifc}
              onchange={(e: any) => handleInputChange("ifc", e.target.value.toUpperCase())}
              placeholder="Enter IFSC Code"
              className={`form-control ${errors.ifc ? 'is-invalid' : ''}`}
              type="text"
              required={true}
            />
            {errors.ifc && <div className="text-danger small mt-1">{errors.ifc}</div>}
          </Col>
          <Col md={6} className="mt-3">
            <Label>Account Number<span className="text-danger">*</span></Label>
            <CustomInputBox
              value={bankInfo.accountNumber}
              onchange={(e: any) => handleInputChange("accountNumber", e.target.value)}
              placeholder="Enter Account Number"
              className={`form-control ${errors.accountNumber ? 'is-invalid' : ''}`}
              type="text"
              required={true}
            />
            {errors.accountNumber && <div className="text-danger small mt-1">{errors.accountNumber}</div>}
          </Col>
          <Col md={6} className="mt-3">
            <Label>Phone Number<span className="text-danger">*</span></Label>
            <CustomPhoneInput
              label="Enter Phone Number"
              value={bankInfo.phone}
              onChange={(phone: string) => {
                setBankInfo({ ...bankInfo, phone });
                setErrors({ ...errors, phone: "" });
              }}
              error={errors.phone}
              defaultCountry="in"
            />
          </Col>
        </Row>
      </ModalBody>

      <div
        className="p-4 d-flex justify-content-between gap-2"
        style={{ backgroundColor: "#273142" }}
      >
        <div className="d-flex align-items-center gap-3">
          <input
            type="checkbox"
            id="terms"
            name="terms"
            value="terms"
            placeholder="terms"
            required={true}
            checked={terms}
            onChange={(e: any) => {
              setTerms(e.target.checked);
            }}
          />
          <label className="v-aligh">
            I agree to all terms and conditions.
          </label>
        </div>
        <div className="d-flex justify-content-between gap-2">
          <CustomButton
            placeholder="Submit"
            color="#00abff"
            disabled={loading}
            type="button"
            onClick={() => {
              handleSubmit();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BankVerificationModal;
