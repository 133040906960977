import React, { useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../helpers/types/contextType";
import {
  CookieItems,
  DeleteCookie,
  GetCookies,
} from "../helpers/cookies_helper";
import axios from "axios";
import { BASE_URL, VALIDATE_JWT } from "../helpers/api_helper";
import { Role } from "../helpers/types/role";

interface ProtectedRouteProps {
  component: React.ComponentType<any>;
  LogoutFlag?: boolean;
  acceptedRoles?: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  LogoutFlag,
  ...rest
}) => {
  const userContext = useContext(UserContext);
  if (!userContext) {
    throw new Error("UserContext is undefined");
  }
  const { userId, handleChangeUserId } = userContext;
  const jwt = GetCookies(CookieItems.JWT);
  const role = GetCookies(CookieItems.ROLE);
  const navigate = useNavigate();
  const location = useLocation();

  // Refs to track validation state
  const isValidating = useRef(false);
  const lastValidationTime = useRef(0);
  const validationTimeout = useRef<NodeJS.Timeout | null>(null);

  // Handle routing and permissions
  useEffect(() => {
    if (LogoutFlag) {
      DeleteCookie(CookieItems.JWT);
      DeleteCookie(CookieItems.ROLE);
      localStorage.clear();
      handleChangeUserId({ userID: null, email: null, jwt: null, role: null });
      navigate("/signin", { replace: true });
      return;
    }

    if (
      (userId === null || userId.jwt === null || userId.role === null) &&
      (jwt === undefined || role === undefined)
    ) {
      navigate("/signin", { replace: true });
    } else if (
      role !== undefined &&
      rest.acceptedRoles !== undefined &&
      !rest?.acceptedRoles.includes(role as Role)
    ) {
      navigate("/profile", { replace: true });
    }
  }, [jwt, role, userId?.jwt, userId?.role, LogoutFlag, rest.acceptedRoles, handleChangeUserId, navigate]);

  // Validate JWT with debounce and cooldown
  const validateToken = async () => {
    // Prevent concurrent validations
    if (isValidating.current) {
      console.log('🔄 Validation already in progress, skipping...');
      return;
    }

    // Check cooldown (minimum 1 second between validations)
    const now = Date.now();
    if (now - lastValidationTime.current < 1000) {
      console.log('⏳ Validation on cooldown, skipping...');
      return;
    }

    if (jwt === undefined || role === undefined) {
      return;
    }

    try {
      isValidating.current = true;
      console.log('🔒 JWT Validation at:', new Date().toISOString());
      console.log('📍 Current path:', location.pathname);

      const response = await axios.post(BASE_URL + VALIDATE_JWT, { token: jwt });
      console.log('✅ Token validated successfully');

      // Update last validation time
      lastValidationTime.current = Date.now();

      // Schedule next validation
      if (validationTimeout.current) {
        clearTimeout(validationTimeout.current);
      }
      validationTimeout.current = setTimeout(validateToken, 5 * 60 * 1000);

    } catch (error) {
      console.error('❌ Token validation failed:', error);
      DeleteCookie(CookieItems.JWT);
      localStorage.clear();
      DeleteCookie(CookieItems.ROLE);
      handleChangeUserId({
        userID: null,
        email: null,
        jwt: null,
        role: null,
      });
      navigate("/signin", { replace: true });
    } finally {
      isValidating.current = false;
    }
  };

  // Setup validation on mount and major path changes
  useEffect(() => {
    const currentPath = location.pathname.split('/')[1];
    console.log('🔄 Setting up validation for path:', currentPath);

    // Debounce the validation call
    const timeoutId = setTimeout(validateToken, 100);

    return () => {
      clearTimeout(timeoutId);
      if (validationTimeout.current) {
        clearTimeout(validationTimeout.current);
      }
    };
  }, [jwt, role, location.pathname]);

  if (
    (userId === null || userId.jwt === null || userId.role === null) &&
    jwt === undefined &&
    role === undefined
  ) {
    return <></>;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
