import React, { useEffect, useState } from "react";
import {
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  BASE_URL,
  UPDATE_CORPORATE_REQUEST_STATUS,
  UPDATE_CREDIT_REQUEST_STATUS,
} from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import CustomInputBox from "../../Component/CustomInputBox";
import CustomDropdown from "../../Component/CustomDropdown";

const statusOptions = [
  {
    label: "Request More Information",
    value: "requested more information",
  },
  { label: "Pending", value: "pending" },
  { label: "Reject", value: "rejected" },
];
const statusOptions2 = [
  {
    label: "Request More Information",
    value: "requested more information",
  },
  { label: "Pending", value: "pending" },
  { label: "Reject", value: "rejected" },
  { label: "Approved", value: "approved" },
];

const UpdateStatusModal = ({
  open,
  onClose,
  requestID,
  corporateStatus,
  corporateID,
}: {
  open: boolean;
  onClose: () => void;
  requestID?: number;
  corporateStatus?: boolean;
  corporateID?: number | null;
}) => {
  const navigate = useNavigate();
  const jwt = GetCookies(CookieItems.JWT);
  const [newStatus, setNewStatus] = useState<{
    label: string;
    value: string;
  }>({
    label: "Request More Information",
    value: "requested more information",
  });
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  console.log(requestID);
  function handleUpdateStatus() {
    setLoading(true);

    if (corporateStatus) {
      axios
        .post(
          BASE_URL + UPDATE_CORPORATE_REQUEST_STATUS,
          {
            request_id: corporateID,
            status: newStatus?.value,
            reason: reason,
            user_id: requestID,
            jwt: jwt,
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          toast.success("Status Updated Successfully");
          onClose();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          if (error.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            toast.error("Error while updating status");
          }
          onClose();
        });
    } else {
      axios
        .post(
          BASE_URL + UPDATE_CREDIT_REQUEST_STATUS,
          {
            request_id: requestID,
            status: newStatus?.value,
            reason: reason,
            jwt: jwt,
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((response) => {
          toast.success("Status Updated Successfully");
          onClose();
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error while updating status", error);
          if (error.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            toast.error("Error while updating status");
          }
          onClose();
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal centered isOpen={open} toggle={onClose} backdrop="static">
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        Update Request Status
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        <Row>
          <Col md={12} className="">
            <Label>Select Status</Label>
            <CustomDropdown
              options={corporateStatus ? statusOptions2 : statusOptions}
              value={newStatus}
              onChange={(e: any) => setNewStatus(e)}
              isDisabled={false}
            />
          </Col>
          <Col md={12} className="mt-2">
            <Label>Reason</Label>
            <CustomInputBox
              value={reason}
              onchange={(e: any) => setReason(e.target.value)}
              placeholder="Enter Reason"
              className="form-control"
              type="text"
            />
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="pt-4" style={{ backgroundColor: "#273142" }}>
        <CustomButton
          placeholder="Submit"
          color="#00abff"
          type="button"
          disabled={loading}
          onClick={() => {
            handleUpdateStatus();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export default UpdateStatusModal;
