// API CONFIGURATION
export const BASE_URL = "https://betaapi.cenzex.com/";
// export const BASE_URL = "http://ec2-3-111-137-85.ap-south-1.compute.amazonaws.com:5005/";
// export const BASE_URL = "http://127.0.0.1:5005/";
export const BLOCK_CHAIN = "https://amoy.polygonscan.com/tx/";
export const GET_CURRENCY_EP = "get_currency";
export const GET_CREDITS_EP = "get_credits";
export const GET_DEPOSIT_HIST_EP = "get_deposit_withdrawal_history";
export const GET_WITHRAWL_HIST_EP = "getwithdrawalhistory";
export const FIAT_DEPOSIT_EP = "add_balance";
export const FIAT_WITHRAWL_EP = "subtract_balance";
export const CLAIM_CREDITS_EP = "add_credits";
export const RETIRE_CREDITS_EP = "subtract_credits";
export const GET_ALL_TRADE_PAIRS_EP = "get_all_trade_pairs";
export const PLACE_LIMIT_ORDER_EP = "place_order";
export const PLACE_MARKET_ORDER_EP = "place_market_order";
export const GET_PERCENTAGE_HOLDING_EP = "get_percentage_holdings";
export const GET_RECENT_TRADES = "get_recent_trades";
export const GET_VITAL_SIGNS = "get_vital_signs";
export const GET_CURRENT_BALANCE = "getcurrentbalance";
export const GET_OHLCV = "get_ohlcv";
export const GET_PENDING_ORDER = "get_pending_orders";
export const GET_MARKET_DEPTH = "getmarketdepth";
export const GET_ORDER_BOOK = "get_orderbook";
export const CANCEL_OPEN_ORDER = "cancel_all_orders";
export const COOKIE_TIMEOUT_VALUE = 4;
export const COOKIE_TO_UNIT = "HOURS";
export const VALIDATE_JWT = "validate_jwt";
export const GET_PROFILE_EP = "get_profile";
export const UPDATE_PASSWORD = "update_password";
export const GET_VERIFICATION_LEVEL = "get_verification_level";
export const GET_VERIFICATION_LEVEL_CORP = "get_verification_level_corp";
export const SEND_OTP = "send_email_otp";
export const VERIFY_OTP = "verify_email_otp";
export const TOKENIZE_CREDITS = "tokenize_credits";
export const RETIRE_CREDITS = "retire_credits";
export const AADHAAR_VERIFICATION = "verify_aadhaar";
export const PAN_VERIFICATION = "verify_pan";
export const MY_ORDERS = "my_orders";
export const MY_ORDERS_DETAILS = "get_order_trade_details";
export const GET_TOKEN_ID = "get_token_id";
export const VERIFY_BANK_DETAILS = "verify_bank_account";
export const GET_MY_TRANSACTIONS = "my_transactions";
export const GET_ACCOUNT_ACTIVITY = "get_account_activity";
export const GET_ALL_REQUESTS = "all_credit_requests";
export const GET_ALL_REQUESTS_COUNT = "get_total_credit_requests_count";
export const UPDATE_CREDIT_REQUEST_STATUS = "update_credit_requests_status";
export const UPDATE_CREDIT_REQUEST = "update_credit_request";
export const APPROVE_CREDIT_REQUEST = "approve_retire_request";
export const GET_MY_CREDIT_REQUESTS = "my_credit_requests";
export const APPROVE_TOKENIZE_REQUEST = "approve_tokenize_request";
export const GET_ALL_ASSETS = "get_all_assets";
export const CREATE_CORPORATE_PROFILE = "create_corporate_profile";
export const ADD_REP_DETAILS = "add_director_representitive";
export const GET_CORPORATE_FORM_STATUS = "check_user_in_corporate_profile";
export const GET_ALL_CORP_REQUESTS = "get_all_corporate_requests";
export const GET_ALL_TRADE_PAIRS_EXPLORE =
  "get_trade_pairs_with_last_price_and_change";
export const GET_CORPORATE_REQUESTS_COUNT = "get_pending_corporate_requests";
export const UPDATE_CORPORATE_REQUEST_STATUS =
  "update_corporate_requests_status";
export const GET_CORPORATE_REQUEST_FILE = "get_corporate_requests_file";
export const SEND_FORGOT_PASSWORD_OTP = "forgot_password_send_email_otp";
export const VERIFY_FORGOT_PASSWORD_OTP = "verify_forgot_password_otp";
export const RESET_PASSWORD = "forgot_password_update_password";
export const GET_PENDING_CREDIT_REQUESTS = "get_user_pending_cr_requests";
export const GET_TOKEN_ID_TRADE_PAIR = "get_all_token_ids_for_tradepair";
export const TWOFA_SET_PROFILE = "2FA_set_profile";
export const TWOFA_VERIFY_OTP = "2FA_verify_otp_profile";
export const TWOFA_VERIFY_OTP_CORP = "2FA_verify_otp_profile_corp";
export const TwoFA_VERIFY_OTP_SIGNIN = "2FA_verify_otp_signin";
export const GET_ALL_PRODUCT_LIST_BY_ASSET_ID = "get_tokenidconfig_by_asset_id";
export const GET_TOKEN_CONFIG_BY_ASSET_ID_NO_PAGINATION = "get_tokenidconfig_by_asset_id_without_pagination";
export const SEARCHTRADEPAIR = "search_trade_pairs_by_full_name";
export const SEARCHPROJECTLIST = "search_tokenidconfig_by_token_id_name";
export const CANCELORDERBYORDERID = "cancel_order_by_orderID";
export const SEARCHTOKENID = "search_tokenidconfig";
export const GET_ALL_TRADE_PAIR_CATEGORY = "get_all_trade_pair_category";
export const GET_PROJECT_DETAIL_BY_TOKEN_ID =
  "get_credit_request_by_token_id_and_asset_id";
export const ADD_ASSETS = "insert_asset";
export const ADD_TRADE_PAIR = "insert_trade_pair";
export const ADD_TOKEN_ID_FAVOURITE = "add-token-id-favourite";
export const REMOVE_TOKEN_ID_FAVOURITE = "delete-token-id-favourite";
export const GET_TOKEN_ID_FAVOURITE = "get-token-id-favourites";
export const CREDIT_REQUEST_BY_TOKENID_AND_ASSETSID =
  "get-credit-requests-by-token-and-asset-informatics";
export const SELLER_FEES_MULTIPLIER = 5;
export const BUYER_FEES_MULTIPLIER = 1;
// SOCKET CONFIGURATION
// export const BASE_SOCKET_URL = "ws://127.0.0.1:5005/ws";
export const BASE_SOCKET_URL = "wss://betaapi.cenzex.com/ws";
export const BASE_SOCKET_URL_TRADINGVIEW =
  "wss://betaapi.cenzex.com/ws_trading_view";
// export const BASE_SOCKET_URL =
// 	"ws://ec2-3-111-137-85.ap-south-1.compute.amazonaws.com:5005/ws";
export const SOCKET_SUB_TRADING = "trading_page_user";
export const SOCKET_SUB_TRADING_MARKET = "trading_page";
export const ACTION_CHANGE_SUBSCRIPTION = "change_subscription";
export const ACTION_DICONNECT_SUBSCRIPTION = "disconnect";
export const SOCKET_SUB_MY_ORDERS = "my_orders";
export const ACTION_REFRESSH_MY_ORDERS = "refresh_my_orders";
