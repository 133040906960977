import React from "react";
import Select, { MenuPlacement, ThemeConfig } from "react-select";

const CustomDropdown = ({
  options,
  value,
  onChange,
  isDisabled,
  multiple,
  menuPlacement,
  placeholder,
  customTrade,
}: {
  options: any;
  value: any;
  onChange: any;
  isDisabled: boolean;
  multiple?: boolean;
  menuPlacement?: MenuPlacement;
  placeholder?: string;
  customTrade?: boolean;
}) => {
  const darkTheme: ThemeConfig = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      neutral0: "#323d4e", // Background color
      neutral80: "#fff", // Text color
      primary25: "#555", // Option hover color
      neutral10: "#444", // Selected option color
    },
    // Add styles for the menu and options
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#323d4e",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#555" : "#333",
      color: "#fff",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      borderColor: state.isFocused ? "#888" : "black",
      "&:hover": {
        borderColor: "#888",
      },
      height: customTrade ? "auto !important" : "47px !important", // Set the height to 47px
      boxShadow: state.isFocused ? "0 0 0 1px #888" : "none",
    }),
  });

  return (
    <Select
      theme={darkTheme}
      options={options}
      value={value}
      isDisabled={isDisabled || false}
      onChange={onChange}
      isMulti={multiple || false}
      menuPlacement={menuPlacement || "bottom"}
      menuPortalTarget={document.body}
      closeMenuOnSelect={customTrade ? false : true} // Added this line to keep the menu open on select
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
        control: (provided, state) => ({
          ...provided,
          borderColor: state.isFocused
            ? "#888"
            : state.isDisabled
            ? "#2a3441"
            : "#444e5d",
          "&:hover": { borderColor: "#888" },
          height: multiple ? "auto !important" : "47px !important",
          minHeight: "47px",
          boxShadow: state.isFocused ? "0 0 0 1px #888" : "none",
          backgroundColor: state.isDisabled ? "#2a3441" : "#323d4e",
        }),
        valueContainer: (provided) => ({
          ...provided,
          height: multiple ? "auto" : "47px",
          padding: "2px 8px",
          overflow: "auto",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? "#00abff" 
            : state.isFocused
              ? "#48576c"
              : "#18202f",
          color: state.isSelected ? "white" : "#fff",
          "&:hover": {
            backgroundColor: "#48576c",
          },
        }),
        multiValue: (provided) => ({
          ...provided,
          margin: "2px",
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          color: "#fff",
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          color: "#fff",
          "&:hover": {
            backgroundColor: "rgba(255,255,255,0.1)",
            color: "#fff",
          },
        }),
      }}
      placeholder={placeholder || "Select"}
    />
  );
};

export default CustomDropdown;
