import React, { useCallback, useEffect, useState } from "react";
import useBreakPoints from "../../hooks/useBreakPoints";
import { TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import {
  BASE_URL,
  GET_ALL_TRADE_PAIRS_EXPLORE,
  SEARCHTRADEPAIR,
} from "../../helpers/api_helper";
import { TradePairType } from "../../helpers/types/TraidPareType";
import axios from "axios";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
// import { useErrorHandler } from "react-error-boundary";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import FilterIcon from "../../assets/svg/Filter-Icon.svg";
import { Col, Row } from "reactstrap";
import WalletCreditIcon from "../../assets/svg/Wallet-credit-icon.svg";
import TradeDrawerPopup from "./TradeDrawerPopup";

const ExploreTradePair = () => {
  const { isMobile, isTablet } = useBreakPoints();
  const navigate = useNavigate();
  // const handleError = useErrorHandler();
  const jwt = GetCookies(CookieItems.JWT);

  const [defaulttradePairs, setDefaultTradePairs] = useState<TradePairType[]>(
    []
  );
  const [tradePairs, setTradePairs] = useState<TradePairType[]>([]);
  const [filters, setFilters] = useState<any>({
    page: 0,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inputValue, setInputValue] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [tradeDrawerOpen, setTradeDrawerOpen] = useState<{
    id: number | null;
    open: boolean;
    data: TradePairType | null;
  }>({
    id: null,
    open: false,
    data: null,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query: string) => {
      searchTradePairs(query);
    }, 350),
    [defaulttradePairs]
  );

  const searchTradePairs = async (query: string) => {
    setLoading(true);
    try {
      const requestBody: any = { full_name: query };

      const response = await axios.post(
        `${BASE_URL}${SEARCHTRADEPAIR}`,
        requestBody
      );
      console.log("search response data", response.data);

      let arr: any[] = [];
      console.log("defaulttradePairs", defaulttradePairs);
      // eslint-disable-next-line array-callback-return
      response.data?.map((er: any) => {
        let check = defaulttradePairs.find(
          (tradePair) => tradePair.id === er.id
        );
        console.log("check", check);
        if (check) {
          arr.push(check);
        }
      });
      console.log("arr", arr);
      setTradePairs(arr);
      // setTradePairs(mapResponseToOptions(response.data));
    } catch (error: any) {
      if (error.response.status === 401) {
        toast.error("Session expired, please login again", {
          toastId: "sessionExp",
        });
        localStorage.clear();
        navigate("/logout");
      } else {
        console.error("Failed to search trade pairs:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (inputValue.trim()) {
      debouncedSearch(inputValue.trim());
    } else {
      setTradePairs(defaulttradePairs || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, debouncedSearch]);

  // Function to find a trade pair by id
  // function findTradePairById(id: number): TradePairType | undefined {
  //   return tradePairs.find((tradePair: TradePairType) => tradePair.id === id);
  // }

  useEffect(() => {
    axios
      .post(
        BASE_URL + GET_ALL_TRADE_PAIRS_EXPLORE,
        {},
        {
          headers: {
            Authorization: jwt,
          },
        }
      )
      .then((response: any) => {
        setTradePairs(response.data);
        setDefaultTradePairs(response.data);
      })
      .catch((error: any) => {
        if (error.response.status === 401) {
          toast.error("Session expired, please login again", {
            toastId: "sessionExp",
          });
          localStorage.clear();
          navigate("/logout");
        } else {
          console.error("Error while getting all trade pairs", error);
          toast.error("Error while getting all trade pair");
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawer = (newOpen: boolean) => () => {
    setDrawerOpen(newOpen);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setFilters({ ...filters, page: newPage });
  };

  return (
    <div className="mt-3 p-4 pt-0">
      <div className=" d-flex justify-content-between align-items-center w-100">
        <span
          style={{
            textTransform: "capitalize",
            fontSize: isMobile ? 20 : 32,
            fontWeight: 700,
            lineHeight: "43.65px",
            letterSpacing: "-0.11px",
          }}
        >
          Explore Our Offerings{" "}
        </span>

        <div
          style={{ marginTop: "20px", paddingRight: 30 }}
          className="d-flex justify-content-end align-items-center "
        >
          {!isMobile && (
            <div>
              <TablePagination
                component="div"
                count={Number(tradePairs.length)} // Total number of rows
                page={filters.page}
                onPageChange={handleChangePage}
                rowsPerPage={10}
                rowsPerPageOptions={[]} // Hide the rows per page options
                sx={{
                  color: "white",
                  "& .css-pdct74-MuiTablePagination-selectLabel ": {
                    color: "white",
                    marginBottom: 0,
                  },
                  "& .css-levciy-MuiTablePagination-displayedRows ": {
                    color: "white",
                    marginBottom: 0,
                  },
                  "& .Mui-disabled": {
                    color: "grey",
                    opacity: 0.5,
                  },
                  "& .MuiTablePagination-actions": {
                    "& .MuiIconButton-root.Mui-disabled": {
                      color: "grey",
                    },
                  },
                }}
              />
            </div>
          )}
          <div
            style={{
              borderLeft: isMobile ? "" : "1px solid white",
              paddingLeft: 20,
              marginLeft: 10,
            }}
            className="d-flex justify-content-center align-items-center gap-3"
          >
            <img
              onClick={toggleDrawer(true)}
              style={{ cursor: "pointer" }}
              src={FilterIcon}
              alt="Filter Icon"
            />
          </div>
        </div>
      </div>

      <Row className={`${isTablet ? "mt-3" : ""}`}>
        {isTablet ? (
          !tradeDrawerOpen?.open && (
            <Col md={tradeDrawerOpen.open ? 6 : 12} style={{ padding: 10 }}>
              <Row>
                {tradePairs.map((trade: any, index: any) => (
                  <Col
                    key={index}
                    md={tradeDrawerOpen.open ? 12 : 6}
                    style={{ padding: 8 }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#273142",

                        borderRadius: 12,
                        height: "100%",
                        padding: "10px 15px",
                        border:
                          tradeDrawerOpen?.id === trade.id
                            ? "2px solid #0d6798"
                            : "1px solid #313d4f",
                      }}
                      onClick={() => {
                        if (trade.id === tradeDrawerOpen?.id) {
                          setTradeDrawerOpen({
                            id: null,
                            open: false,
                            data: null,
                          });
                        } else {
                          setTradeDrawerOpen({
                            id: trade.id,
                            open: true,
                            data: trade,
                          });
                        }
                      }}
                    >
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <div>
                          <p
                            className="mt-1 mb-0"
                            style={{
                              fontSize: isTablet ? 18 : 24,
                              fontWeight: 700,
                            }}
                          >
                            {trade.name}
                          </p>
                          <p style={{ fontSize: isTablet ? 10 : 12 }}>
                            {trade.full_name}
                          </p>
                        </div>

                        <img src={WalletCreditIcon} alt="Rupees Icon" />
                      </div>

                      <div
                        style={{ fontSize: 12 }}
                        className="d-flex justify-content-between align-items-center w-100"
                      >
                        <div>
                          <p>
                            LTP &nbsp; &nbsp; &#x20b9;{" "}
                            {trade.last_price ? trade.last_price : "-"}
                          </p>
                          <p>Tonnage Per Credit {trade.tonnage_per_credit}</p>
                        </div>
                        <div className="w-50">
                          <div className="d-flex gap-3">
                            <p
                              style={{ width: isTablet ? "40%" : "25%" }}
                              className="mb-0"
                            >
                              Registry
                            </p>
                            <p className="mb-0">{trade.registry}</p>
                          </div>
                          <div className="d-flex gap-3">
                            <p
                              style={{ width: isTablet ? "40%" : "25%" }}
                              className="mb-0"
                            >
                              Mint Year
                            </p>
                            <p className="mb-0">{trade.mint_year}</p>
                          </div>
                          <div className="d-flex gap-3">
                            <p
                              style={{ width: isTablet ? "40%" : "25%" }}
                              className="mb-0"
                            >
                              Total Credit
                            </p>
                            <p className="mb-0">{trade.total_credits}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          )
        ) : (
          <Col md={tradeDrawerOpen.open ? 6 : 12} style={{ padding: 10 }}>
            <Row>
              {tradePairs.map((trade: any, index: any) => (
                <Col
                  key={index}
                  md={tradeDrawerOpen.open ? 12 : 6}
                  style={{ padding: 7 }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#273142",
                      borderRadius: 12,
                      height: "100%",
                      padding: "10px 15px",
                      border:
                        tradeDrawerOpen?.id === trade.id
                          ? "2px solid #0d6798"
                          : "1px solid #313d4f",
                    }}
                    onClick={() => {
                      if (trade.id === tradeDrawerOpen?.id) {
                        setTradeDrawerOpen({
                          id: null,
                          open: false,
                          data: null,
                        });
                      } else {
                        setTradeDrawerOpen({
                          id: trade.id,
                          open: true,
                          data: trade,
                        });
                      }
                    }}
                  >
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <div>
                        <p
                          className="mt-1 mb-0"
                          style={{
                            fontSize: isTablet ? 18 : 24,
                            fontWeight: 700,
                          }}
                        >
                          {trade.name}
                        </p>
                        <p style={{ fontSize: isTablet ? 10 : 12 }}>
                          {trade.full_name}
                        </p>
                      </div>

                      {/* <img src={WalletCreditIcon} alt="Rupees Icon" /> */}
                    </div>

                    <div
                      style={{ fontSize: 12 }}
                      className="d-flex justify-content-between align-items-center w-100"
                    >
                      <div>
                        <p>
                          LTP &nbsp; &nbsp; &#x20b9;{" "}
                          {trade.last_price ? trade.last_price : "-"}
                        </p>
                        <p>Tonnage Per Credit {trade.tonnage_per_credit}</p>
                      </div>
                      <div className="w-50">
                        <div className="d-flex gap-3">
                          <p
                            style={{ width: isTablet ? "40%" : "25%" }}
                            className="mb-0"
                          >
                            Registry
                          </p>
                          <p className="mb-0">{trade.registry}</p>
                        </div>
                        <div className="d-flex gap-3">
                          <p
                            style={{ width: isTablet ? "40%" : "25%" }}
                            className="mb-0"
                          >
                            Mint Year
                          </p>
                          <p className="mb-0">{trade.mint_year}</p>
                        </div>
                        <div className="d-flex gap-3">
                          <p
                            style={{ width: isTablet ? "40%" : "25%" }}
                            className="mb-0"
                          >
                            Total Credit
                          </p>
                          <p className="mb-0">{trade.total_credits}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        )}

        {tradeDrawerOpen.open && (
          <Col md={6} style={{ padding: 7 }}>
            <TradeDrawerPopup
              tradeDrawerOpen={tradeDrawerOpen}
              setTradeDrawerOpen={setTradeDrawerOpen}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ExploreTradePair;
