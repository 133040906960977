import React, { useEffect, useState } from "react";
import {
  // Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
// import OtpInput from "react-otp-input";

// import OTPImage from "../../assets/images/otp-image.png";
import { useErrorHandler } from "react-error-boundary";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AADHAAR_VERIFICATION,
  BASE_URL,
  PAN_VERIFICATION,
  // SEND_OTP,
  // VERIFY_OTP,
} from "../../helpers/api_helper";
import axios from "axios";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import CustomButton from "../../Component/CustomButton";
import useBreakPoints from "../../hooks/useBreakPoints";

const IdentityVerificationModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [fileUpload, setFileUpload] = useState<{
    AdhaarFront: File | null;
    AdhaarBack: File | null;
    PanCardFront: File | null;
  }>({
    AdhaarFront: null,
    AdhaarBack: null,
    PanCardFront: null,
  });
  const handleError = useErrorHandler();
  const jwt = GetCookies(CookieItems.JWT);
  const { isTablet } = useBreakPoints();
  const [step, setStep] = useState<number>(1);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [verificationStatus, setVerificationStatus] = useState({
    aadhaar: false,
    pan: false,
  });

  // Add new validation state
  const [errors, setErrors] = useState<{
    AdhaarFront?: string;
    AdhaarBack?: string;
    PanCardFront?: string;
  }>({});

  const validateFile = (file: File | null, type: string): string | null => {
    if (!file) {
      return `Please upload ${type}`;
    }

    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      return `${type} must be less than 5MB`;
    }

    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!allowedTypes.includes(file.type)) {
      return `${type} must be in JPEG, JPG, or PNG format`;
    }

    return null;
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      const error = validateFile(selectedFile, key);
      if (error) {
        toast.error(error);
        return;
      }

      setFileUpload((prevState) => ({
        ...prevState,
        [key]: selectedFile,
      }));

      // Clear error for this field if it exists
      setErrors(prev => ({
        ...prev,
        [key]: undefined
      }));
    }
  };

  const validateAllFiles = (): boolean => {
    const newErrors: { [key: string]: string } = {};

    const adhaarFrontError = validateFile(fileUpload.AdhaarFront, 'Aadhaar Front');
    if (adhaarFrontError) newErrors.AdhaarFront = adhaarFrontError;

    const adhaarBackError = validateFile(fileUpload.AdhaarBack, 'Aadhaar Back');
    if (adhaarBackError) newErrors.AdhaarBack = adhaarBackError;

    const panFrontError = validateFile(fileUpload.PanCardFront, 'PAN Card');
    if (panFrontError) newErrors.PanCardFront = panFrontError;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  function handleDocumentUpload() {
    if (!validateAllFiles()) {
      toast.error("Please upload all required documents in the correct format");
      return;
    }

    setLoading(true);
    // Aadhaar Verification
    const formData = new FormData();
    if (fileUpload.AdhaarBack && fileUpload.AdhaarFront) {
      formData.append("front_image", fileUpload?.AdhaarFront);
      formData.append("back_image", fileUpload?.AdhaarBack);
    }
    axios
      .post(BASE_URL + AADHAAR_VERIFICATION, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: jwt,
        },
      })
      .then((res) => {
        handleUploadPanCardImage();
        toast.success("Adhaar Card Verified Successfully");
        setVerificationStatus((prevState) => ({
          ...prevState,
          aadhaar: true,
        }));
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response !== undefined &&
          err.response !== null &&
          err.response.data.verification_status === "failed"
        ) {
          toast.error("Adhaar Card Verification Failed, Please try Again");
        } else {
          console.error("Error while verifying Adhaar Card", err);
          handleError(new Error("Error while verifying Adhaar Card"));
        }
      });
  }

  const handleUploadPanCardImage = () => {
    // Pan Verification
    const PanformData = new FormData();
    if (fileUpload.PanCardFront) {
      PanformData.append("front_image", fileUpload?.PanCardFront);
    }

    axios
      .post(BASE_URL + PAN_VERIFICATION, PanformData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: jwt,
        },
      })
      .then((res) => {
        setLoading(false);
        toast.success("PAN Card Verified Successfully");
        setVerificationStatus((prevState) => ({
          ...prevState,
          pan: true,
        }));
        onClose();
      })
      .catch((err) => {
        setLoading(false);
        if (
          err.response !== undefined &&
          err.response !== null &&
          err.response.data.verification_status === "failed"
        ) {
          toast.error("PAN Card Verification Failed, Please try Again");
        } else {
          console.error("Error while verifying Adhaar Card", err);
          handleError(new Error("Error while verifying Adhaar Card"));
        }
      });
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [open]);

  return (
    <Modal centered size="xl" isOpen={open} toggle={onClose}>
      <ModalHeader style={{ backgroundColor: "#273142" }} toggle={onClose}>
        {step === 1 ? "Please Note" : "VERIFY YOUR IDENTITY"}
      </ModalHeader>
      <ModalBody style={{ backgroundColor: "#273142" }}>
        {step === 1 ? (
          <div>
            <p>
              To ensure compliance with Know Your Customer (KYC) and Anti-Money
              Laundering (AML) regulations, we kindly request your cooperation
              in providing essential identity documents for authentication. This
              process is crucial for maintaining the security and integrity of
              our services. Please submit clear, high-resolution photographs of
              the following:
            </p>

            <ol>
              <li style={{ fontWeight: 700 }}>
                Aadhaar Card: Both the front and back sides
              </li>
              <li style={{ fontWeight: 700 }}>PAN Card: The front side only</li>
            </ol>
            <p>
              These official government-issued documents are vital for verifying
              your identity and address. The Aadhaar card serves as proof of
              identity and residence, while the PAN card confirms your tax
              registration status. By providing these documents, you help us
              meet legal requirements and protect our platform against potential
              fraudulent activities. Rest assured that all information submitted
              will be treated with the utmost confidentiality and used solely
              for verification purposes.
            </p>
          </div>
        ) : (
          <Row className={`${isTablet ? "gap-4" : ""}`}>
            <Col lg={4} md={12} className="">
                <Label>Upload Aadhaar Front <span style={{ color: 'red' }}>*</span></Label>
              <div className="file file--upload ">
                <Input
                  type="text"
                  className="custom-upload-file"
                  style={{
                    background: "#323d4e",
                    border: "#323d4e",
                    color: "white",
                  }}
                  disabled
                  value={fileUpload.AdhaarFront?.name}
                />
                <label htmlFor="input-file">
                  <i className="fa fa-upload"></i>Upload
                </label>
                <input
                  id="input-file"
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, "AdhaarFront")
                  }
                  required
                  accept=".jpeg, .jpg, .png"
                />
              </div>
              {fileUpload.AdhaarFront && (
                <div className="d-flex justify-content-center">
                  <img
                    className="mt-4"
                    height={150}
                    width={250}
                    src={URL.createObjectURL(fileUpload.AdhaarFront)}
                    alt="Uploaded File"
                  />
                </div>
              )}
                {errors.AdhaarFront && (
                  <div className="text-danger mt-1">{errors.AdhaarFront}</div>
                )}
            </Col>
            <Col lg={4} md={12} className="">
                <Label>Upload Aadhaar Back <span style={{ color: 'red' }}>*</span></Label>
              <div className="file file--upload ">
                <Input
                  type="text"
                  style={{
                    background: "#323d4e",
                    border: "#323d4e",
                    color: "white",
                  }}
                  className="custom-upload-file"
                  disabled
                  value={fileUpload.AdhaarBack?.name}
                />
                <label htmlFor="input-file-2">
                  <i className="fa fa-upload"></i>Upload
                </label>
                <input
                  id="input-file-2"
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, "AdhaarBack")
                  }
                  required
                  accept=".jpeg, .jpg, .png"
                />
              </div>
              {fileUpload.AdhaarBack && (
                <div className="d-flex justify-content-center">
                  <img
                    className="mt-4"
                    height={150}
                    width={250}
                    src={URL.createObjectURL(fileUpload.AdhaarBack)}
                    alt="Uploaded File"
                  />
                </div>
              )}
                {errors.AdhaarBack && (
                  <div className="text-danger mt-1">{errors.AdhaarBack}</div>
                )}
            </Col>
            <Col lg={4} md={12} className="">
                <Label>Upload PAN Card <span style={{ color: 'red' }}>*</span></Label>
              <div className="file file--upload ">
                <Input
                  type="text"
                  className="custom-upload-file"
                  disabled
                  style={{
                    background: "#323d4e",
                    border: "#323d4e",
                    color: "white",
                  }}
                  value={fileUpload.PanCardFront?.name}
                />
                <label htmlFor="input-file-3">
                  <i className="fa fa-upload"></i>Upload
                </label>
                <input
                  id="input-file-3"
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleInputChange(e, "PanCardFront")
                  }
                  required
                  accept=".jpeg, .jpg, .png"
                />
              </div>
              {fileUpload.PanCardFront && (
                <div className="d-flex justify-content-center">
                  <img
                    className="mt-4"
                    height={150}
                    width={250}
                    src={URL.createObjectURL(fileUpload.PanCardFront)}
                    alt="Uploaded File"
                  />
                </div>
              )}
                {errors.PanCardFront && (
                  <div className="text-danger mt-1">{errors.PanCardFront}</div>
                )}
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter className="pt-4" style={{ backgroundColor: "#273142" }}>
        {step === 1 && (
          <CustomButton
            placeholder="Next"
            color="#00abff"
            type="button"
            onClick={() => {
              setStep(2);
            }}
          />
        )}
        {step === 2 && (
          <>
            <CustomButton
              placeholder="Previous"
              color="#323d4e"
              type="button"
              disabled={loading}
              onClick={() => {
                setStep(1);
              }}
              // loading={loading}
            />
            <CustomButton
              placeholder={loading ? "Processing..." : "Submit"}
              color="#00abff"
              type="button"
              disabled={loading}
              onClick={() => {
                handleDocumentUpload();
              }}
              loading={loading}
            />
          </>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default IdentityVerificationModal;
