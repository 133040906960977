import React, { useEffect, useState, useCallback } from "react";
import useBreakPoints from "../../hooks/useBreakPoints";
import { Col, InputGroup, InputGroupText, Label, Row } from "reactstrap";
import CustomInputBox from "../../Component/CustomInputBox";
import { useLocation, useNavigate } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  EditorState,
  ContentState,
  convertToRaw,
  // convertFromRaw,
} from "draft-js";
import { useErrorHandler } from "react-error-boundary";
import axios from "axios";
import debounce from 'lodash/debounce';
import {
  BASE_URL,
  TOKENIZE_CREDITS,
  UPDATE_CREDIT_REQUEST,
} from "../../helpers/api_helper";
import { CookieItems, GetCookies } from "../../helpers/cookies_helper";
import { toast } from "react-toastify";
import CustomPhoneInput from "../../Component/CustomPhoneInput";
import CustomButton from "../../Component/CustomButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";

interface ValidationErrors {
  creditsNo: string;
  registryProjectURL: string;
  projectID: string;
  programmeName: string;
  project_name: string;
  projectState: string;
  projectCountry: string;
  projectType: string;
  methodologyID: string;
  mintDate: string;
  registryAccountID: string;
  registryName: string;
  mintDurationYears: string;
  mintDurationMonths: string;
  mintDurationDays: string;
  projectProponentName: string;
  projectProponentAddress: string;
  projectProponentPhone: string;
  additionalAccreditations: string;
}

const MAX_ADDITIONAL_ACCREDITATIONS_CHARS = 499;

const TokenizeCredit = () => {
  const { isMobile, isTablet, isMediumMobile } = useBreakPoints();
  const navigate = useNavigate();
  const location = useLocation();
  const jwt = GetCookies(CookieItems.JWT);

  const [tokenizeInfo, setTokenizeInfo] = useState({
    registryAccountID: "",
    projectID: "",
    registryName: "",
    registryProjectURL: "",
    creditsNo: "",
    programmeName: "",
    projectCountry: "",
    projectState: "",
    projectType: "",
    project_name: "",
    methodologyID: "",
    alreadyRegistered: false,
    mintDate: "",
    oneTimeClaim: true,
    mintDurationYears: "",
    mintDurationMonths: "",
    mintDurationDays: "",
    tokenID: "",
    additionalAccreditations: EditorState.createEmpty(),
  });

  const [extras, setExtras] = useState({
    status: false,
    projectProponentName: "",
    projectProponentAddress: "",
    projectProponentPhone: "",
    projectProponentCode: "",
  });

  const [errors, setErrors] = useState<ValidationErrors>({
    creditsNo: "",
    registryProjectURL: "",
    projectID: "",
    programmeName: "",
    project_name: "",
    projectState: "",
    projectCountry: "",
    projectType: "",
    methodologyID: "",
    mintDate: "",
    registryAccountID: "",
    registryName: "",
    mintDurationYears: "",
    mintDurationMonths: "",
    mintDurationDays: "",
    projectProponentName: "",
    projectProponentAddress: "",
    projectProponentPhone: "",
    additionalAccreditations: "",
  });

  const validateField = useCallback((field: string, value: string): string => {
    switch (field) {
      case 'creditsNo':
        if (!value) return 'Number of credits is required';
        if (!Number.isInteger(Number(value)) || Number(value) <= 0) {
          return 'Please enter a whole number greater than 0';
        }
        return '';

      case 'registryProjectURL':
        if (!value) return 'Registry URL is required';

        if (!value.match(/^https?:\/\/[^/]/)) {
          return 'URL must start with http:// or https:// (exactly two slashes)';
        }

        try {
          const url = new URL(value);
          if (!url.hostname.includes('.')) {
            return 'Please enter a valid URL with a proper domain';
          }
          if (!url.hostname.match(/\.[a-z]{2,}$/i)) {
            return 'Please enter a URL with a valid domain extension';
          }
          if (value.match(/^https?:\/\/.*\/\//)) {
            return 'URL cannot contain consecutive slashes after protocol';
          }
          return '';
        } catch {
          return 'Please enter a valid URL';
        }

      case 'projectID':
        if (!value) return 'Project ID is required';
        return '';

      case 'programmeName':
        if (!value) return 'Credit Program Name is required';
        return '';

      case 'project_name':
        if (!value) return 'Project Name is required';
        return '';

      case 'projectState':
        if (!value) return 'Project State is required';
        if (!/^[A-Za-z\s]+$/.test(value)) return 'Only letters and spaces are allowed';
        return '';

      case 'projectCountry':
        if (!value) return 'Project Country is required';
        if (!/^[A-Za-z\s]+$/.test(value)) return 'Only letters and spaces are allowed';
        return '';

      case 'projectType':
        if (!value) return 'Project Type is required';
        return '';

      case 'methodologyID':
        if (!value) return 'Methodology ID is required';
        return '';

      case 'mintDate':
        if (!value) return 'Mint Year is required';
        const year = Number(value);
        if (isNaN(year) || year < 1950 || year > new Date().getFullYear()) {
          return 'Please enter a valid year between 1950 and current year';
        }
        return '';

      case 'registryAccountID':
        if (tokenizeInfo.alreadyRegistered && !value) return 'Registry Account ID is required';
        return '';

      case 'registryName':
        if (tokenizeInfo.alreadyRegistered && !value) return 'Registry Name is required';
        return '';

      case 'mintDurationYears':
        if (!tokenizeInfo.oneTimeClaim && !value) return 'Mint Duration Years is required';
        if (value && (!/^\d+$/.test(value) || Number(value) < 0)) return 'Please enter a valid number';
        return '';

      case 'mintDurationMonths':
        if (!tokenizeInfo.oneTimeClaim && value && (!/^\d+$/.test(value) || Number(value) < 0 || Number(value) > 12)) {
          return 'Please enter a valid number between 0 and 12';
        }
        return '';

      case 'mintDurationDays':
        if (!tokenizeInfo.oneTimeClaim && value && (!/^\d+$/.test(value) || Number(value) < 0 || Number(value) > 30)) {
          return 'Please enter a valid number between 0 and 30';
        }
        return '';

      case 'projectProponentName':
        if (!extras.status && !value) return 'Project Proponent Name is required';
        return '';

      case 'projectProponentAddress':
        if (!extras.status && !value) return 'Project Proponent Address is required';
        return '';

      case 'projectProponentPhone':
        if (!extras.status && !value) return 'Project Proponent Phone Number is required';
        const phoneDigits = value.replace(/\D/g, '');
        if (!extras.status && phoneDigits && phoneDigits.length !== 10) {
          return 'Phone number must be exactly 10 digits';
        }
        return '';

      default:
        return '';
    }
  }, [tokenizeInfo.oneTimeClaim, extras.status]);

  const debouncedValidate = useCallback(
    debounce((field: string, value: string) => {
      setErrors(prev => ({
        ...prev,
        [field]: validateField(field, value)
      }));
    }, 500),
    [validateField]
  );

  const handleInputChange = (field: string, value: string) => {
    if (field === 'creditsNo') {
      const wholeNumber = value.replace(/[^\d]/g, '');
      setTokenizeInfo(prev => ({
        ...prev,
        [field]: wholeNumber
      }));
      setErrors(prev => ({
        ...prev,
        [field]: validateField(field, wholeNumber)
      }));
    } else if (field === 'status' || field === 'alreadyRegistered' || field === 'oneTimeClaim') {
      if (field === 'status') {
        setExtras(prev => ({
          ...prev,
          status: value === 'true'
        }));
      } else {
        setTokenizeInfo(prev => ({
          ...prev,
          [field]: value === 'true'
        }));
      }
    } else if (field.startsWith('projectProponent')) {
      if (field === 'projectProponentPhone') {
        const digitsOnly = value.replace(/\D/g, '');
        if (digitsOnly.length <= 12) {
          setExtras(prev => ({
            ...prev,
            [field]: digitsOnly
          }));
        }
      } else {
        setExtras(prev => ({
          ...prev,
          [field]: value
        }));
      }

      if (field === 'projectProponentPhone' || field === 'projectProponentCode') {
        setTimeout(() => {
          setErrors(prev => ({
            ...prev,
            projectProponentPhone: validateField('projectProponentPhone', value)
          }));
        }, 0);
      }
    } else if (field === 'mintDurationYears' || field === 'mintDurationMonths' || field === 'mintDurationDays') {
      const digitsOnly = value.replace(/\D/g, '');
      setTokenizeInfo(prev => ({
        ...prev,
        [field]: digitsOnly
      }));
      setErrors(prev => ({
        ...prev,
        [field]: validateField(field, digitsOnly)
      }));
    } else if (field === 'projectState' || field === 'projectCountry') {
      const lettersOnly = value.replace(/[^A-Za-z\s]/g, '');
      setTokenizeInfo(prev => ({
        ...prev,
        [field]: lettersOnly
      }));
      setErrors(prev => ({
        ...prev,
        [field]: validateField(field, lettersOnly)
      }));
    } else {
      setTokenizeInfo(prev => ({
        ...prev,
        [field]: value
      }));
      setErrors(prev => ({
        ...prev,
        [field]: validateField(field, value)
      }));
    }

    debouncedValidate(field, value);
  };

  const getCharCount = () => {
    const contentState = tokenizeInfo.additionalAccreditations.getCurrentContent();
    const plainText = contentState.getPlainText();
    return plainText.length;
  };

  const [loading, setLoading] = useState(false);

  const handleError = useErrorHandler();

  const validateInputs = (): { isValid: boolean; message: string } => {
    const requiredFields = [
      { value: tokenizeInfo.creditsNo, name: "Number of Credits" },
      { value: tokenizeInfo.registryProjectURL, name: "Registry URL" },
      { value: tokenizeInfo.projectID, name: "Project ID" },
      { value: tokenizeInfo.programmeName, name: "Credit Program Name" },
      { value: tokenizeInfo.project_name, name: "Project Name" },
      { value: tokenizeInfo.projectState, name: "Project State" },
      { value: tokenizeInfo.projectCountry, name: "Project Country" },
      { value: tokenizeInfo.projectType, name: "Project Type" },
      { value: tokenizeInfo.methodologyID, name: "Methodology ID" },
      { value: tokenizeInfo.mintDate, name: "Mint Year" }
    ];

    if (tokenizeInfo.alreadyRegistered) {
      requiredFields.push(
        { value: tokenizeInfo.registryAccountID, name: "Registry Account ID" },
        { value: tokenizeInfo.registryName, name: "Registry Name" }
      );
    }

    if (!extras.status) {
      requiredFields.push(
        { value: extras.projectProponentName, name: "Project Proponent Name" },
        { value: extras.projectProponentAddress, name: "Project Proponent Address" },
        { value: extras.projectProponentPhone, name: "Project Proponent Contact Number" }
      );
    }

    if (!tokenizeInfo.oneTimeClaim) {
      requiredFields.push(
        { value: tokenizeInfo.mintDurationYears, name: "Mint Duration Years" },
        { value: tokenizeInfo.mintDurationMonths, name: "Mint Duration Months" },
        { value: tokenizeInfo.mintDurationDays, name: "Mint Duration Days" }
      );
    }

    const missingField = requiredFields.find(field => !field.value);
    if (missingField) {
      return {
        isValid: false,
        message: `Please fill in the required field: ${missingField.name}`
      };
    }

    return { isValid: true, message: "" };
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>): void => {
    e?.preventDefault();

    const validation = validateInputs();
    if (!validation.isValid) {
      toast.error(validation.message);
      return;
    }

    setLoading(true);
    if (location?.state?.edit === undefined) {
      const contentState =
        tokenizeInfo.additionalAccreditations.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const plainText = rawContentState.blocks
        .map((block) => block.text)
        .join("\n");
      axios
        .post(
          BASE_URL + TOKENIZE_CREDITS,
          {
            if_registry_account: tokenizeInfo.alreadyRegistered,
            registry_name: tokenizeInfo.alreadyRegistered
              ? tokenizeInfo.registryName
              : "",
            registry_account_id: tokenizeInfo.alreadyRegistered
              ? tokenizeInfo.registryAccountID
              : "",
            project_name: tokenizeInfo.project_name,
            project_id: tokenizeInfo.projectID,
            project_type: tokenizeInfo.projectType,
            project_country: tokenizeInfo.projectCountry,
            project_state: tokenizeInfo.projectState,
            registry_project_url: tokenizeInfo.registryProjectURL,
            num_of_credits: tokenizeInfo.creditsNo,
            token_id: tokenizeInfo.tokenID,
            methodology_id: tokenizeInfo.methodologyID,
            credit_programme: tokenizeInfo.programmeName,
            additional_accreditations: plainText,
            mint_year: tokenizeInfo.mintDate,
            if_one_time_claim: tokenizeInfo.oneTimeClaim,
            mint_duration: !tokenizeInfo.oneTimeClaim
              ? tokenizeInfo.mintDurationYears +
                "Y;" +
                tokenizeInfo.mintDurationMonths +
                "M;" +
                tokenizeInfo.mintDurationDays +
                "D;"
              : "",
            applicant_is_not_pp: !extras.status,
            pp_name: !extras.status ? extras.projectProponentName : "",
            pp_address: !extras.status ? extras.projectProponentAddress : "",
            pp_contact_details: !extras.status
              ? extras.projectProponentCode.toString() +
                "-" +
                extras.projectProponentPhone.toString()
              : "",
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((res) => {
          toast.success("Credit Tokenize Request Placed Successfully");
          setLoading(false);
          navigate("/myrequests");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            handleError(new Error("Tokenization Failed"));
          }
          setLoading(false);
        });
    } else {
      const contentState =
        tokenizeInfo.additionalAccreditations.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const plainText = rawContentState.blocks
        .map((block) => block.text)
        .join("\n");
      axios
        .post(
          BASE_URL + UPDATE_CREDIT_REQUEST,
          {
            request_id: location?.state?.edit.id,
            if_registry_account: tokenizeInfo.alreadyRegistered.toString(),
            registry_name: tokenizeInfo.alreadyRegistered
              ? tokenizeInfo.registryName
              : "",
            registry_account_id: tokenizeInfo.alreadyRegistered
              ? tokenizeInfo.registryAccountID
              : "",
            project_id: tokenizeInfo.projectID,
            project_name: tokenizeInfo.project_name,
            project_type: tokenizeInfo.projectType,
            project_country: tokenizeInfo.projectCountry,
            project_state: tokenizeInfo.projectState,
            registry_project_url: tokenizeInfo.registryProjectURL,
            num_of_credits: Number(tokenizeInfo.creditsNo),
            token_id: tokenizeInfo.tokenID,
            methodology_id: tokenizeInfo.methodologyID,
            credit_programme: tokenizeInfo.programmeName,
            additional_accreditations: plainText,
            mint_year: tokenizeInfo.mintDate,
            if_one_time_claim: tokenizeInfo.oneTimeClaim.toString(),
            mint_duration: !tokenizeInfo.oneTimeClaim
              ? tokenizeInfo.mintDurationYears +
                "Y;" +
                tokenizeInfo.mintDurationMonths +
                "M;" +
                tokenizeInfo.mintDurationDays +
                "D;"
              : "",
            applicant_is_not_pp: (!extras.status).toString(),
            pp_name: !extras.status ? extras.projectProponentName : "",
            pp_address: !extras.status ? extras.projectProponentAddress : "",
            pp_contact_details: !extras.status
              ? extras.projectProponentCode.toString() +
                "-" +
                extras.projectProponentPhone.toString()
              : "",
          },
          {
            headers: {
              Authorization: jwt,
            },
          }
        )
        .then((res) => {
          toast.success("Credit Tokenize Request Updated");
          setLoading(false);
          navigate("/myrequests");
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 401) {
            toast.error("Session expired, please login again", {
              toastId: "sessionExp",
            });
            localStorage.clear();
            navigate("/logout");
          } else {
            toast.error("Error while updating tokenize request");
          }
          setLoading(false);
        });
    }
  };

  const handleEditorChange = (editorState: EditorState) => {
    const contentState = editorState.getCurrentContent();
    const plainText = contentState.getPlainText();

    setErrors(prev => ({
      ...prev,
      additionalAccreditations: plainText.length > MAX_ADDITIONAL_ACCREDITATIONS_CHARS 
        ? `Character limit exceeded. Maximum ${MAX_ADDITIONAL_ACCREDITATIONS_CHARS} characters allowed.`
        : ''
    }));

    if (plainText.length <= MAX_ADDITIONAL_ACCREDITATIONS_CHARS ||
      plainText.length < getCharCount()) {
      setTokenizeInfo(prev => ({
        ...prev,
        additionalAccreditations: editorState
      }));
    }
  };

  const ErrorMessage = ({ error }: { error: string }) => (
    error ? (
      <div style={{ color: '#ff4444', fontSize: '12px', marginTop: '4px' }}>
        {error}
      </div>
    ) : null
  );

  useEffect(() => {
    if (location?.state?.edit) {
      setTokenizeInfo({
        ...tokenizeInfo,
        registryAccountID: location?.state?.edit.registry_account_id,
        projectID: location?.state?.edit.project_id,
        registryName: location?.state?.edit.registry_name,
        registryProjectURL: location?.state?.edit.registry_project_url,
        creditsNo: location?.state?.edit.num_of_credits.toString(),
        programmeName: location?.state?.edit.credit_programme,
        projectCountry: location?.state?.edit.project_country,
        projectState: location?.state?.edit.project_state,
        projectType: location?.state?.edit.project_type,
        project_name:
          location?.state?.edit.project_name ||
          location?.state?.edit.registry_project_name,
        methodologyID: location?.state?.edit.methodology_id,
        alreadyRegistered: location?.state?.edit.if_registry_account,
        mintDate: location?.state?.edit.mint_year,
        oneTimeClaim: location?.state?.edit.if_one_time_claim,
        mintDurationYears:
          location?.state?.edit?.mint_duration &&
          location?.state?.edit?.mint_duration?.length > 0
            ? location?.state?.edit?.mint_duration?.split(";")[0]?.slice(0, -1)
            : "",
        mintDurationMonths:
          location?.state?.edit?.mint_duration &&
          location?.state?.edit?.mint_duration?.length > 0
            ? location?.state?.edit?.mint_duration
                ?.split(";")?.[1]
                ?.slice(0, -1)
            : "",
        mintDurationDays:
          location?.state?.edit?.mint_duration &&
          location?.state?.edit?.mint_duration?.length > 0
            ? location?.state?.edit?.mint_duration
                ?.split(";")?.[2]
                ?.slice(0, -1)
            : "",
        tokenID: location?.state?.edit.token_id,
        additionalAccreditations: EditorState.createWithContent(
          ContentState.createFromText(
            location?.state?.edit.additional_accreditations
          )
        ),
      });
      setExtras({
        ...extras,
        status: !location?.state?.edit.applicant_is_not_pp,
        projectProponentName: location?.state?.edit.pp_name,
        projectProponentAddress: location?.state?.edit.pp_address,
        projectProponentPhone:
          location?.state?.edit.pp_contact_details.split("-")[1],
        projectProponentCode:
          location?.state?.edit.pp_contact_details.split("-")[0]
      });
    }
  }, []);

  const isFormValid = (): boolean => {
    const hasErrors = Object.values(errors).some(error => error !== '');

    if (hasErrors) return false;

    const accreditationsLength = getCharCount();
    const isAccreditationsValid = accreditationsLength <= MAX_ADDITIONAL_ACCREDITATIONS_CHARS;
    return isAccreditationsValid;
  };

  const handlePhoneChange = (value: string): void => {
    const digitsOnly = value.replace(/\D/g, '').replace(/^91/, '');
    if (digitsOnly.length <= 10) {
      setExtras(prev => ({
        ...prev,
        projectProponentPhone: digitsOnly
      }));
    }
  };

  return (
    <div className="mt-3 p-4 pt-0">
      <div className=" d-flex justify-content-between align-items-center w-100">
        <span style={{ fontSize: isMobile ? 20 : 32, fontWeight: 700 }}>
          Tokenize Your Credits{" "}
        </span>
        <CustomButton
          placeholder="back"
          style={{ width: isMobile ? "100%" : "auto" }}
          color="#323d4e"
          type="button"
          divclassName={` ${isMobile ? "w-100" : ""}`}
          onClick={() => {
            if (location?.state?.edit) {
              navigate("/myrequests");
            } else {
              navigate("/wallet");
            }
          }}
        />
      </div>

      <Row
        style={{
          backgroundColor: "#273142",
          border: "1px solid #313d4f",
          borderRadius: "10px",
          margin: "10px 5px",
          padding: "10px 20px",
        }}
      >
        <Col className="" style={{ marginTop: 12, paddingRight: 0 }} md={12}>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{}}
          >
            <span style={{ fontSize: 20, fontWeight: 600 }}>
              General Information
            </span>
          </div>
          <Row className={`mt-4 ${isMobile ? "gap-4" : ""}`}>
            <Col lg={4} md={6}>
              <Label>
                Number of Credits{" "} <span style={{ color: 'red' }}>*</span>
                <Tooltip
                  title="Number of credits to be tokenized"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.creditsNo}
                onchange={(e: any) =>
                  handleInputChange('creditsNo', e.target.value)
                }
                placeholder="Enter Number of Credits"
                className="form-control"
                type="number"
              />
              <ErrorMessage error={errors.creditsNo} />
            </Col>
            <Col lg={4} md={6}>
              <Label>
                Registry URL{" "} <span style={{ color: 'red' }}>*</span>
                <Tooltip
                  title="Please enter the Link of the Project on the registry"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.registryProjectURL}
                onchange={(e: any) =>
                  handleInputChange('registryProjectURL', e.target.value)
                }
                placeholder="Enter Registry URL"
                className="form-control"
                type="url"
              />
              <ErrorMessage error={errors.registryProjectURL} />
            </Col>
            <Col
              lg={4}
              md={6}
              className={`${isTablet ? (isMobile ? "" : "mt-3") : ""}`}
            >
              <Label>
                Project ID{" "} <span style={{ color: 'red' }}>*</span>
                <Tooltip
                  title="Please enter the Project ID as provided by your registry"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.projectID}
                onchange={(e: any) =>
                  handleInputChange('projectID', e.target.value)
                }
                placeholder="Enter Project ID"
                className="form-control"
                type="text"
              />
              <ErrorMessage error={errors.projectID} />
            </Col>
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Credit Program Name{" "} <span style={{ color: 'red' }}>*</span>
                <Tooltip
                  title="Please enter the credit program name as provided by your registry, eg - VCS, CCBS, etc"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.programmeName}
                onchange={(e: any) =>
                  handleInputChange('programmeName', e.target.value)
                }
                placeholder="Enter Credit Program Name"
                className="form-control"
                type="text"
              />
              <ErrorMessage error={errors.programmeName} />
            </Col>
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Project Name{" "} <span style={{ color: 'red' }}>*</span>
                <Tooltip
                  title="Please enter the Project Name as you provided to your registry"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.project_name}
                onchange={(e: any) =>
                  handleInputChange('project_name', e.target.value)
                }
                placeholder="Enter Project Name"
                className="form-control"
                type="text"
              />
              <ErrorMessage error={errors.project_name} />
            </Col>
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Project State{" "} <span style={{ color: 'red' }}>*</span>
                <Tooltip
                  title="Please enter the Project state as provided by your registry"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>{" "}
              </Label>
              <CustomInputBox
                value={tokenizeInfo.projectState}
                onchange={(e: any) =>
                  handleInputChange('projectState', e.target.value)
                }
                placeholder="Enter Project State"
                className="form-control"
                type="text"
              />
              <ErrorMessage error={errors.projectState} />
            </Col>{" "}
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Project Country{" "} <span style={{ color: 'red' }}>*</span>
                <Tooltip
                  title="Please enter the Project country as provided by your registry"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.projectCountry}
                onchange={(e: any) =>
                  handleInputChange('projectCountry', e.target.value)
                }
                placeholder="Enter Project Country"
                className="form-control"
                type="text"
              />
              <ErrorMessage error={errors.projectCountry} />
            </Col>
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Project Type{" "} <span style={{ color: 'red' }}>*</span>
                <Tooltip
                  title="eg. carbon Capture, GHG mitigation, forestry, Credits to reduce, etc"
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.projectType}
                onchange={(e: any) =>
                  handleInputChange('projectType', e.target.value)
                }
                placeholder="Enter Project Type"
                className="form-control"
                type="text"
              />
              <ErrorMessage error={errors.projectType} />
            </Col>
            <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
              <Label>
                Methodology ID{" "} <span style={{ color: 'red' }}>*</span>
                <Tooltip
                  title="The Methodology ID can be found on the project's page on the registry."
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <CustomInputBox
                value={tokenizeInfo.methodologyID}
                onchange={(e: any) =>
                  handleInputChange('methodologyID', e.target.value)
                }
                placeholder="Enter Methodology ID"
                className="form-control"
                type="text"
              />
              <ErrorMessage error={errors.methodologyID} />
            </Col>
            <Col md={12}>
              <div
                className={`d-flex justify-content-start   ${
                  isMediumMobile
                    ? "flex-column align-items-start mt-4  gap-2"
                    : "align-items-center mt-5  gap-4"
                } `}
              >
                <p
                  className={`mb-0`}
                  style={{
                    width: isMobile
                      ? isMediumMobile
                        ? "100%"
                        : "60%"
                      : "auto",
                  }}
                >
                  Do you have a Registry Account ?{" "}
                </p>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      handleInputChange('alreadyRegistered', 'true');
                    }}
                    style={{
                      border: tokenizeInfo.alreadyRegistered
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: tokenizeInfo.alreadyRegistered
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleInputChange('alreadyRegistered', 'false');
                    }}
                    style={{
                      border: !tokenizeInfo.alreadyRegistered
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: !tokenizeInfo.alreadyRegistered
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    NO
                  </button>
                </div>
              </div>
            </Col>
            {tokenizeInfo.alreadyRegistered && (
              <>
                <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>
                    Registry Account ID{" "} <span style={{ color: 'red' }}>*</span>
                    <Tooltip
                      title="Please enter the Registry Account ID as provided by your registry"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={tokenizeInfo.registryAccountID}
                    onchange={(e: any) =>
                      handleInputChange('registryAccountID', e.target.value)
                    }
                    placeholder="Enter Registry Account ID"
                    className="form-control"
                    type="text"
                  />
                  <ErrorMessage error={errors.registryAccountID} />
                </Col>
                <Col lg={4} md={6} className={`${isMobile ? "" : "mt-3"}`}>
                  <Label>
                    Registry Name{" "} <span style={{ color: 'red' }}>*</span>
                    <Tooltip
                      title="Please enter the Registry Name as provided by your registry"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={tokenizeInfo.registryName}
                    onchange={(e: any) =>
                      handleInputChange('registryName', e.target.value)
                    }
                    placeholder="Enter Registry Name"
                    className="form-control"
                    type="text"
                  />
                  <ErrorMessage error={errors.registryName} />
                </Col>
              </>
            )}
          </Row>
        </Col>

        <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{}}
          >
            <span style={{ fontSize: 20, fontWeight: 600 }}>
              Mint Information
            </span>
          </div>

          <Row className={`mt-4 ${isMobile ? "gap-4" : ""}`}>
            <Col lg={4} md={6}>
              <Label>
                Mint Year{" "} <span style={{ color: 'red' }}>*</span>
                <Tooltip
                  title="Specify the year your credits of the project were minted. Credits are minted annually."
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: "#475469",
                      },
                    },
                  }}
                >
                  <InfoOutlinedIcon
                    style={{
                      cursor: "pointer",
                      fontSize: 16,
                      marginLeft: 5,
                      marginBottom: 3,
                    }}
                  />
                </Tooltip>
              </Label>
              <InputGroup>
                <CustomInputBox
                  value={tokenizeInfo.mintDate}
                  onchange={(e: any) =>
                    handleInputChange('mintDate', e.target.value)
                  }
                  placeholder="Enter Mint Year"
                  className="form-control"
                  type="number"
                  maxLength={4}
                />
                <InputGroupText
                  style={{
                    backgroundColor: "#323d4e",
                    color: "white",
                    border: "1px solid #444e5d",
                  }}
                >
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.742676 17C0.742676 18.7 2.04268 20 3.74268 20H17.7427C19.4427 20 20.7427 18.7 20.7427 17V9H0.742676V17ZM17.7427 2H15.7427V1C15.7427 0.4 15.3427 0 14.7427 0C14.1427 0 13.7427 0.4 13.7427 1V2H7.74268V1C7.74268 0.4 7.34268 0 6.74268 0C6.14268 0 5.74268 0.4 5.74268 1V2H3.74268C2.04268 2 0.742676 3.3 0.742676 5V7H20.7427V5C20.7427 3.3 19.4427 2 17.7427 2Z"
                      fill="#B6B6B6"
                    />
                  </svg>
                </InputGroupText>
              </InputGroup>
              <ErrorMessage error={errors.mintDate} />
            </Col>
            <Col md={8}>
              <div
                className={`d-flex justify-content-start ${
                  isMediumMobile
                    ? "flex-column align-items-start gap-2 "
                    : "align-items-center gap-4 "
                } `}
                style={{ marginTop: "32px" }}
              >
                <p
                  className={`mb-0 d-flex align-items-center`}
                  style={{
                    width: isMobile
                      ? isMediumMobile
                        ? "100%"
                        : "60%"
                      : "auto",
                  }}
                >
                  Is the credit one time claimable?{" "}
                  <Tooltip
                    title="Select 'Yes' if you'll receive credits annually from this project."
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#475469",
                        },
                      },
                    }}
                  >
                    <InfoOutlinedIcon
                      style={{
                        cursor: "pointer",
                        fontSize: 16,
                        marginLeft: 5,
                        marginBottom: 0,
                      }}
                    />
                  </Tooltip>
                </p>
                <div className="d-flex align-items-center">
                  <button
                    type="button"
                    onClick={() => {
                      handleInputChange('oneTimeClaim', 'true');
                    }}
                    style={{
                      border: tokenizeInfo.oneTimeClaim
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: tokenizeInfo.oneTimeClaim
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "5px 0px 0px 5px",
                      height: "38px",
                    }}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleInputChange('oneTimeClaim', 'false');
                    }}
                    style={{
                      border: !tokenizeInfo.oneTimeClaim
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: !tokenizeInfo.oneTimeClaim
                        ? "#00abff"
                        : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "0px 5px 5px 0px",
                      height: "38px",
                    }}
                  >
                    NO
                  </button>
                </div>
              </div>
            </Col>

            {!tokenizeInfo.oneTimeClaim && (
              <>
                <Col md={4} className="mt-3">
                  {" "}
                  <Label>
                    Mint Duration{" "} <span style={{ color: 'red' }}>*</span>
                    <Tooltip
                      title="Please enter the Mint Duration in years, for example, i am issued credits every 1 year 2 months and 4 days"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={tokenizeInfo.mintDurationYears}
                    onchange={(e: any) =>
                      handleInputChange('mintDurationYears', e.target.value)
                    }
                    placeholder="Mint Duration in year"
                    className="form-control"
                    type="text"
                    maxLength={4}
                  />
                  <ErrorMessage error={errors.mintDurationYears} />
                </Col>
                <Col md={4} className="mt-3">
                  {" "}
                  <Label>
                    Mint Months{" "}
                    <Tooltip
                      title="Please enter the Mint Duration in months, for example, i am issued credits every 1 year 2 months and 4 days"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={tokenizeInfo.mintDurationMonths}
                    onchange={(e: any) =>
                      handleInputChange('mintDurationMonths', e.target.value)
                    }
                    placeholder="Mint Duration in months"
                    className="form-control"
                    type="text"
                  />
                  <ErrorMessage error={errors.mintDurationMonths} />
                </Col>{" "}
                <Col md={4} className="mt-3">
                  {" "}
                  <Label>
                    Mint Days{" "}
                    <Tooltip
                      title="Please enter the Mint Duration in days, for example, i am issued credits every 1 year 2 months and 4 days"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>{" "}
                  </Label>
                  <CustomInputBox
                    value={tokenizeInfo.mintDurationDays}
                    onchange={(e: any) =>
                      handleInputChange('mintDurationDays', e.target.value)
                    }
                    placeholder="Mint Duration in days"
                    className="form-control"
                    type="text"
                  />
                  <ErrorMessage error={errors.mintDurationDays} />
                </Col>
              </>
            )}
          </Row>
        </Col>

        <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{}}
          >
            <span style={{ fontSize: 20, fontWeight: 600 }}>
              Project Proponent Information
            </span>
          </div>

          <Row className={`mt-4 ${isMobile ? "gap-4" : ""}`}>
            <Col lg={12} md={12}>
              <div
                className={`d-flex justify-content-start ${
                  isMediumMobile
                    ? "flex-column align-items-start gap-2 "
                    : "align-items-center gap-4 "
                } `}
              >
                <p
                  className={`mb-0`}
                  style={{
                    width: isMobile
                      ? isMediumMobile
                        ? "100%"
                        : "60%"
                      : "auto",
                  }}
                >
                  Were the Credits claimed by you on your project?{" "}
                  <Tooltip
                    title="If you own the project you're claiming for, select 'Yes'. Otherwise, select 'No' and provide the project owner's details."
                    placement="right"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "#475469",
                        },
                      },
                    }}
                  >
                    <InfoOutlinedIcon
                      style={{
                        cursor: "pointer",
                        fontSize: 16,
                        marginLeft: 5,
                        marginBottom: 3,
                      }}
                    />
                  </Tooltip>
                </p>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      handleInputChange('status', 'true');
                    }}
                    style={{
                      border: extras.status
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: extras.status ? "#00abff" : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "5px 0px 0px 5px",
                    }}
                  >
                    YES
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleInputChange('status', 'false');
                    }}
                    style={{
                      border: !extras.status
                        ? "1px solid #00abff"
                        : "1px solid #444e5d",
                      background: !extras.status ? "#00abff" : "#323d4e",
                      fontSize: 12,
                      padding: "7px 13px",
                      color: "white",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                  >
                    NO
                  </button>
                </div>
              </div>
            </Col>

            {!extras.status && (
              <>
                <Col md={4} className={` ${isMobile ? "" : "mt-3"}`}>
                  {" "}
                  <Label>
                    Project Proponent Name{" "} <span style={{ color: 'red' }}>*</span>
                    <Tooltip
                      title="Please enter the Project Proponent Name"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={extras.projectProponentName}
                    onchange={(e: any) =>
                      handleInputChange('projectProponentName', e.target.value)
                    }
                    placeholder="Enter Project Proponent Name"
                    className="form-control"
                    type="text"
                  />
                  <ErrorMessage error={errors.projectProponentName} />
                </Col>
                <Col md={4} className={` ${isMobile ? "" : "mt-3"}`}>
                  {" "}
                  <Label>
                    Project Proponent Address{" "} <span style={{ color: 'red' }}>*</span>
                    <Tooltip
                      title="Please enter the Project Proponent Address"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomInputBox
                    value={extras.projectProponentAddress}
                    onchange={(e: any) =>
                      handleInputChange('projectProponentAddress', e.target.value)
                    }
                    placeholder="Enter Project Proponent Address"
                    className="form-control"
                    type="text"
                  />
                  <ErrorMessage error={errors.projectProponentAddress} />
                </Col>
                <Col md={4} className={` ${isMobile ? "" : "mt-3"}`}>
                  {" "}
                  <Label>
                    Project Proponent Contact Number{" "} <span style={{ color: 'red' }}>*</span>
                    <Tooltip
                      title="Please enter the Project Proponent Phone Number"
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: "#475469",
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon
                        style={{
                          cursor: "pointer",
                          fontSize: 16,
                          marginLeft: 5,
                          marginBottom: 3,
                        }}
                      />
                    </Tooltip>
                  </Label>
                  <CustomPhoneInput
                    label=""
                    value={extras.projectProponentPhone}
                    onChange={handlePhoneChange}
                    error=""
                    defaultCountry="in"
                  />
                </Col>
              </>
            )}
          </Row>
        </Col>

        <Col className="" style={{ marginTop: 40, paddingRight: 0 }} md={12}>
          <div
            className="d-flex justify-content-between align-items-center w-100"
            style={{}}
          >
            <span style={{ fontSize: 20, fontWeight: 600 }}>
              Additional Accreditations{" "}
              <Tooltip
                title="Please enter any additional accreditations, audits, certifications, etc."
                placement="right"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#475469",
                    },
                  },
                }}
              >
                <InfoOutlinedIcon
                  style={{
                    cursor: "pointer",
                    fontSize: 16,
                    marginLeft: 5,
                    marginBottom: 3,
                  }}
                />
              </Tooltip>
            </span>
          </div>

          <Row className={`mt-4 ${isMobile ? "gap-4" : ""}`}>
            <Col lg={12} md={12}>
              <div
                style={{
                  backgroundColor: "#323d4e",
                  border: "1px solid #444e5d",
                  borderRadius: 12,
                  padding: 10,
                }}
              >
                <Editor
                  wrapperClassName="dark-theme"
                  editorClassName="dark-theme"
                  toolbarClassName="toolbar"
                  placeholder="Additional Accreditations"
                  editorState={tokenizeInfo.additionalAccreditations}
                  onEditorStateChange={handleEditorChange}
                />
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '8px',
                  color: getCharCount() > MAX_ADDITIONAL_ACCREDITATIONS_CHARS ? '#ff4444' : '#B6B6B6',
                  fontSize: '12px'
                }}>
                  <span>
                    {getCharCount()}/{MAX_ADDITIONAL_ACCREDITATIONS_CHARS} characters
                  </span>
                  {getCharCount() > MAX_ADDITIONAL_ACCREDITATIONS_CHARS && (
                    <span style={{ color: '#ff4444' }}>
                      Character limit exceeded
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <div
            className={`d-flex mt-3 mb-3 ${
              isMobile ? "justify-content-center" : "justify-content-end"
            } align-items-center gap-3`}
          >
            <CustomButton
              placeholder="Cancel"
              style={{ width: isMobile ? "100%" : "auto" }}
              color="#323d4e"
              type="button"
              divclassName={` ${isMobile ? "w-100" : ""}`}
              onClick={() => {
                if (location?.state?.edit) {
                  navigate("/myrequests");
                } else {
                  navigate("/wallet");
                }
              }}
            />
            <CustomButton
              placeholder="Submit"
              style={{ width: isMobile ? "100%" : "auto" }}
              color="#00abff"
              type="submit"
              divclassName={` ${isMobile ? "w-100" : ""}`}
              onClick={handleSubmit}
              disabled={loading || !isFormValid()}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TokenizeCredit;
